import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import {Col, Row, Skeleton} from "antd";
import {Formik, Form as FormikForm} from "formik";
import _ from "lodash";
import Card from "../../common/components/dataDisplay/Card";
import Select from "../../common/components/dataEntry/formik/FormikSelectField";
import DatePicker from "../../common/components/dataEntry/formik/FormikDatePickerField";
import moment from "moment";
import Button from "../../common/components/general/Button";
import FormSaveCancelButtons from "../../common/components/forms/SaveCancelFormButtons";
import * as Yup from "yup";

import {NewsAuditForSave} from "core/application/newsAudits/getNewsAuditForSave";
import getNewsAuditForSave from "core/application/newsAudits/getNewsAuditForSave";
import {commandRequest, queryRequest} from "../../common/RequestUtils";
import NewsAuditTableInputs from "./tables/NewsAuditTableInputs";
import {NewsAuditItems, NewsAuditSubjects, saveNewsAudit} from "core/application/newsAudits/getNewsAuditForSave";
import notification from "../../common/components/feedback/Notification";
import TextArea from "../../common/components/dataEntry/formik/FormikTextAreaField";
import SaveAuditFormButtons from "../../common/components/forms/SaveAuditFormButtons";
import {AuditStatus} from "core/domain/common/auditStatus";
import store from "core/application/commons/localStorage";

export const MANAGE_NEWS_AUDIT = 'manage-news-audit'

const formValidator = (translate: any) =>
    Yup.object({
        date: Yup.date()
            .required(translate("general.required"))
            .typeError(translate("general.required")),
        newsEditionId: Yup.string()
            .trim()
            .required(translate("general.required"))
            .typeError(translate("general.required")),
        auditItems : Yup.array()
            .of(
                Yup.object().shape({
                    newsTopicId: Yup.string()
                        .required(translate("general.required"))
                        .typeError(translate("general.required"))
                        .nullable(),
                    duration: Yup.number()
                        .nullable()
                        .required(translate("general.required"))
                        .moreThan(0, "Më e madhe se 0"),
                })
            )
    });

const getAutoSaveCacheKey = (id: any) => `news_audit_${id}`;
const ManageNewsAudit: React.FC = () => {
    const {t: translate} = useTranslation();
    const history = useHistory();
    const {id} = useParams();
    const [item, setItem] = useState<NewsAuditForSave | null>(null)
    const [loading, setLoading] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    useEffect(() => {
        (async () => {
            await updateForm();
        })()
    }, [])

    const updateForm = async () => {
        setLoading(true);
        const result = (await queryRequest(() => getNewsAuditForSave(id)));

        const cached = store.getObject(getAutoSaveCacheKey(result.id))
        if(cached && result.status === AuditStatus.Draft) {
            cached.availableAuditableSubjects = result.availableAuditableSubjects;
            cached.availableAuditees = result.availableAuditees;
            cached.availableNewsEditions = result.availableNewsEditions;
            cached.availableNewsTopics = result.availableNewsTopics;
            setItem(cached);
        }
        else {
            setItem(result);
        }
        setLoading(false);
    }

    const onSubmit = async (data: any, status: number) => {
        if (!formValidator(translate).isValidSync({...data})) {
            return
        }
        let auditItems = data.auditItems.map((c: any, key: number) => {
            c.index = key + 1
            c.auditSubjects = c.auditSubjects.map((r: any, rkey: number) => {
                r.index = rkey + 1
                return r
            })
            return c
        })
        const request = {
            id: data.id,
            date: data.date,
            newsEditionId: data.newsEditionId,
            notes: data.notes,
            auditItems: auditItems,
            status
        }

        setIsFormSubmitted(true);
        const result = await commandRequest(() => saveNewsAudit(request))
        setIsFormSubmitted(false);

        if (!result.errors) {
            notification.open({
                message: translate("general.saved_plural").replace(
                    "[]",
                    translate("dashboard.news.the_news")
                ),
                type: "success"
            });
            store.remove(getAutoSaveCacheKey(data.id));
            history.goBack();
        } else {
            notification.open({message: result.errors[0], type: "error"});
        }
    }

    const lockAudit = async (values: any) => {
        const shouldLock = !values.id && values.newsEditionId && values.date;
        if (!shouldLock) return;

        const request = {
            id: values.id,
            date: values.date,
            newsEditionId: values.newsEditionId,
            auditItems: [],
            status: AuditStatus.Draft
        }

        setIsFormSubmitted(true)
        const result = await commandRequest(() => saveNewsAudit(request))
        setIsFormSubmitted(false)

        if (result.errors) {
            notification.open({message: result.errors[0], type: "error"});
            return;
        }

        if (!item) return;

        setItem({
            ...item,
            newsEditionId: values.newsEditionId,
            date: values.date,
            notes: values.notes,
            auditItems: [
                newAuditItem(values.auditItems.length + 1)
            ],
            id: result,
        })
    }

    const newAuditItem = (index: number): NewsAuditItems => {
        return {
            id: null,
            newsTopicId: "",
            duration: null,
            auditSubjects: [{
                id: null,
                auditableSubjectId: null,
                auditeeId: null,
                duration: 0,
                syncTime: 0,
                journalistTime: 0,
                totalTime: 0,
                auditeeSyncTime: 0,
                notes: "",
                // index: 1
            }],
            index: index
        }
    }

    const onAddAuditItemsRow = (values: any) => {
        if (values) {
            setItem({...values, auditItems: [...values.auditItems, newAuditItem(values.auditItems.length + 1)]})
        }
    }

    const onAddAuditSubjectRow = (values: any, index: number) => {
        if (values) {
            //Create new auditItem
            const newAuditSubjectItem: NewsAuditSubjects = {
                id: null,
                auditableSubjectId: null,
                auditeeId: null,
                duration: 0,
                syncTime: 0,
                journalistTime: 0,
                totalTime: 0,
                auditeeSyncTime: 0,
                notes: "",
                // index: values.auditItems[index].auditSubjects.length + 1
            }
            //Push that item in the items object
            const clone = {...values};
            clone.auditItems[index].auditSubjects = [...values.auditItems[index].auditSubjects, newAuditSubjectItem]
            setItem({
                ...clone
            })
        }
    }

    const onRemoveAuditItemRow = (index: number, values: NewsAuditForSave) => {
        if (values) {
            values.auditItems.splice(index, 1);
            setItem({
                ...values,
                auditItems: [...values.auditItems]
            })
        }
    };

    const onRemoveAuditSubjectRow = (auditItemIndex: number, index: number, values: NewsAuditForSave) => {
        if (values) {
            values.auditItems[auditItemIndex].auditSubjects.splice(index, 1);
            const clone = _.cloneDeep(values);
            setItem(clone);
        }
    }

    return (
        <Skeleton
            active
            loading={loading}
            paragraph={{rows: 6, className: "p-64 color-gray-5"}}
            title={false}
        >
            {" "}
            {item && (
                <Formik
                    innerRef={instance => {
                        if(instance && instance.values && item.id && item.status === AuditStatus.Draft) {
                            store.saveObject(getAutoSaveCacheKey(item.id), instance.values);
                        }
                    }}
                    enableReinitialize={true}
                    initialValues={item}
                    validationSchema={formValidator(translate)}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={() => {
                    }}
                >
                    {formik => (
                        <div>
                            <Card
                                className={"container"}
                                bordered={true}
                                title={id ? translate("dashboard.news.editNewsAudit") : translate("dashboard.news.addNewsAudit")}
                            >
                                <FormikForm>
                                    <Row gutter={10}>
                                        <Col xs={24} lg={12}>
                                            <Select
                                                name="newsEditionId"
                                                showSearch={true}
                                                label={`${translate("dashboard.news.newsEdition")}`}
                                                placeholder={translate("dashboard.news.newsEdition")}
                                                onBlur={async () => {
                                                    await lockAudit(formik.values);
                                                }}>
                                                {item.availableNewsEditions.map(index => (
                                                    <option key={index.id} value={index.id}>
                                                        {index.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Col>

                                        <Col xs={24} lg={12}>
                                            <DatePicker
                                                label={translate("general.date")}
                                                name="date"
                                                allowClear={false}
                                                placeholder={translate("general.date")}
                                                value={
                                                    formik.values.date
                                                        ? moment(formik.values.date)
                                                        : null
                                                }
                                                disabledDate={d =>
                                                    !d || d.isAfter(moment().subtract(0, "day"))
                                                }
                                                className="full_width"
                                                onBlur={async () => {
                                                    await lockAudit(formik.values);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <TextArea
                                                name="notes"
                                                label={`${translate("dashboard.news.audit.notes")}`}
                                                placeholder={translate("dashboard.news.audit.notes")}
                                            >
                                            </TextArea>
                                        </Col>
                                    </Row>
                                </FormikForm>
                            </Card>

                            {item.id &&
                            <Card>
                                <NewsAuditTableInputs
                                    auditItems={item?.auditItems}
                                    datasource={item}
                                    name="auditItems"
                                    onRemoveAuditItemRow={onRemoveAuditItemRow}
                                    translate={translate}
                                    onAddAuditSubjectRow={onAddAuditSubjectRow}
                                    onRemoveAuditSubjectRow={onRemoveAuditSubjectRow}
                                    onAddAuditItemsRow={onAddAuditItemsRow}
                                    formik={formik}
                                />
                                <Button className={"mt-8"} type="primary"
                                        onClick={() => onAddAuditItemsRow(formik.values)}>
                                    {translate("general.add")}
                                </Button>
                            </Card>
                            }

                            <div className={"text-center mb-16"}>
                                <SaveAuditFormButtons
                                    //disableSaveAsDraft={!formValidator(translate).isValidSync(formik.values)}
                                    onSaveAsDraftClick={async () => {
                                        await formik.submitForm();
                                        await onSubmit(formik.values, AuditStatus.Draft)
                                    }}
                                    //disablePublish={!formValidator(translate).isValidSync(formik.values)}
                                    onPublishClick={async () => {
                                        await formik.submitForm();
                                        await onSubmit(formik.values, AuditStatus.Published)
                                    }}
                                    loading={isFormSubmitted}
                                />
                            </div>
                        </div>
                    )}
                </Formik>
            )}
        </Skeleton>
    )
}
export default ManageNewsAudit;
