import React from "react";
import DatePicker, { DatePickerProps } from "../components/DatePicker";
import Error from "./FormItemError";
import { useField, useFormikContext } from "formik";
import Label from "../components/Label";
import moment from "moment";
import FieldProps from "./FieldProps";
import classnames from "classnames";

export type FormikDatePickerFieldProps = {
  name: string;
  label?: string;
  id?: string;
  placeholder?: string;
  className?: string;
  onBlur?: React.FormEventHandler<HTMLInputElement>;
}

type FormikDatePickerProps = DatePickerProps & FormikDatePickerFieldProps;

const FormikDatePickerField: React.FC<FormikDatePickerProps> = props => {
  const [field, meta] = useField(props);
  const context = useFormikContext();
  const showError = meta.touched && meta.error;
  const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;

  const onDateChange = (date: any, dateString: string) => {
    context.setFieldValue(
      props.name,
      moment.utc(dateString).format("YYYY-MM-DD")
    );
  };

  const onBlur = (e: any): void => {
    context.setFieldTouched(props.name);
    if(props.onBlur) {
      props.onBlur(e);
    }
  };

  return (
    <>
      <div className={"form-group"}>
        <div>
          {props.label && (
            <Label htmlFor={props.id || props.name}
              className=" formik-field__input-label no-select hand-on-hover">
              {props.label}
            </Label>
          )}
        </div>
        <div>
          <DatePicker{...props} onChange={onDateChange} className={` ${props.className}`} onBlur={onBlur}/>
        </div>
        {showError && (<div className="mt-4">{error}&nbsp;</div>)}
      </div>
    </>
  );
};

export default FormikDatePickerField;
