import {formatDataAsDashed} from "../../../common/dateFormatters";

export const getParentColumns = () => {
    return [
        { title: 'Edicioni i Lajmeve', dataIndex: 'newsEditionName', key: 'newsEditionName' },
        { title: 'Data', dataIndex: 'date', key: 'date', render:(text: string, record : any) => (record !== undefined && formatDataAsDashed(record.date))},
        { title: 'Shënime', dataIndex: 'notes', key: 'notes' },
    ];
}

export const getChildrenColumns = () => {
    return [
        { title: 'Nr.', dataIndex: 'index', key: 'index', render:(text: string, record : any, i: number) => record.index  },
        { title: 'Tema e lajmit', dataIndex: 'newsTopicName', key: 'newsTopicName' },
        { title: 'Kohëzgjatja', dataIndex: 'duration', key: 'duration' },
    ];
}

export const getNephewColumns = () => {
    return [
        { title: 'Subjekti', dataIndex: 'auditableSubjectName', key: 'auditableSubjectName' },
        { title: 'Koha gazetarit', dataIndex: 'journalistTime', key: 'journalistTime' },
        { title: 'Koha sinkron', dataIndex: 'syncTime', key: 'syncTime' },
        { title: 'Koha plotë', dataIndex: 'totalTime', key: 'totalTime' },
        { title: 'Aktori', dataIndex: 'auditeeName', key: 'auditeeName' },
        { title: 'Koha sinkron', dataIndex: 'auditeeSyncTime', key: 'auditeeSyncTime' },
        { title: 'Shënime', dataIndex: 'notes', key: 'notes' },
    ];
}