import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import getLoginUser, { User } from "../loginUser";

export const UPDATE_LOGIN_USER = "UPDATE_LOGIN_USER";

export interface UpdateLoginUserAction {
  type: typeof UPDATE_LOGIN_USER;
  user: User | null;
}

export const SET_FETCHING = "requests/SET_FETCHING";
export type SET_FETCHING = typeof SET_FETCHING;

export const SET_USER = "requests/SET_USER";
export type SET_USER = typeof SET_USER;

export const SET_ERROR = "requests/SET_ERROR";
export type SET_ERROR = typeof SET_ERROR;

export interface SetFetching {
  type: SET_FETCHING;
  isFetching: boolean;
}

export interface SetUser {
  type: SET_USER;
  response: User;
}

export interface SetError {
  type: SET_ERROR;
  error: string | null;
  hasError: boolean;
}

export type UserAction = SetFetching | SetUser | SetError;

// Action Creators

export function setFetching(isFetching: boolean): SetFetching {
  return {
    type: SET_FETCHING,
    isFetching
  };
}

export function setUser(response: User): SetUser {
  return {
    type: SET_USER,
    response
  };
}

export function setError(hasError: boolean, error: string | null): SetError {
  return {
    type: SET_ERROR,
    hasError,
    error
  };
}

export function fetchUser(): ThunkAction<Promise<void>, {}, {}, UserAction> {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(setFetching(true));
    dispatch(setError(false, null));
    try {
      const response = await getLoginUser();
      dispatch(setUser(response));
    } catch (e) {
      if(e !== undefined && e.data){
        dispatch(setError(true, e.data[0].message));
      }
      dispatch(setError(true, "Something went wrong. Sorry for the inconvience."))
    }
    dispatch(setFetching(false));
  };
}
