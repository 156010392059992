import React from "react";
import "./DataEntryStyles.less";
import MaskedInput from "antd-mask-input";
import { InputProps } from "./Input";
import { MaskedInputProps } from "antd-mask-input/build/main/lib/MaskedInput";
import Label from "./Label";

interface InputMaskProps extends MaskedInputProps {
  mask: string;
  label? : string;
}

const InputMask: React.FC<InputMaskProps> = props => {
  return (
      <>
        <MaskedInput {...props} id={props.id || props.name} />
      </>
      );
};

export default InputMask;
