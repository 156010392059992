import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import ProgramAudits from "./ProgramAudits";
import ManageProgramsAudit from "./ManageProgramsAudit";
import {MANAGE_PROGRAMS_PATH} from "./ManageProgramsAudit"
import NotFound from "../../errorPages/NotFound";

const Routes: React.FC = () => {
    let match = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${match.path}/`} component={ProgramAudits} />
            <Route
                path={`${match.path}/${MANAGE_PROGRAMS_PATH}/:id?`}
                component={ManageProgramsAudit}
            />
            <Route component={NotFound} />
        </Switch>
    );
};

export default Routes;
