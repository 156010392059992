import React from "react";

import {NewsTickerAuditForSave, AuditSubject} from "core/application/newsTickerAudit/saveNewsTicker";
import {useFormikContext} from "formik";
import Select from "../../../common/components/dataEntry/formik/FormikSelectField";
import InputNumber from "../../../common/components/dataEntry/formik/FormikInputNumberField";
import Button from "../../../common/components/general/Button";
import {Modal} from "antd";
import Table from "common/components/dataDisplay/Table";
import TextArea from "../../../common/components/dataEntry/formik/FormikTextAreaField";
import Input from "../../../common/components/dataEntry/formik/FormikInputField";

interface Props {
    auditSubject : any[];
    datasource : NewsTickerAuditForSave;
    name: string;
    translate : (key: string) => string;
    onRemoveAuditSubjectRow : any,
    onAddAuditSubjectRow : any,
    formik : any,
}

const {confirm} = Modal;
const AuditSubjectTableInput : React.FC<Props> =  ({
                                                auditSubject,
                                                datasource,
                                                name,
                                                translate,
                                                onRemoveAuditSubjectRow,
                                                onAddAuditSubjectRow,
                                                formik
                                               }) => {

    const columns = [
        {
            title: "",
            dataIndex: 'index',
            key: `index`,
            width:"5%",
            render:(text: string, record: any, i: number) => (
                <span><b>{++i}</b></span>
            )
        },
        {
            title: translate("dashboard.programs.manage.auditableSubjectId"),
            dataIndex: 'auditableSubjectId',
            key: "auditableSubjectId",
            sorter: false,
            width:"30%",
            editable: true,
            render:(text: string, record: any, i: number) => (
                <Select
                    name={`${name}[${i}].auditableSubjectId`}
                    showSearch={true}
                    placeholder={translate("dashboard.programs.manage.auditableSubjectId")}
                    onBlur={() => onAddAuditSubjectRow(formik.values)}
                    className={"mb-0"}
                >
                    {datasource.availableAuditableSubjects.map((item : any) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </Select>
            )
        },
        {
            title: `${translate("dashboard.liveBroadcast.auditSubjects.duration")} (minuta)`,
            dataIndex: 'duration',
            key: "duration",
            width:"10%",
            render:(text: string, record: any, i: number) =>(
                <InputNumber
                    name={`${name}[${i}].duration`}
                    placeholder={translate("dashboard.programs.manage.duration")}
                />
            )
        },
        {
            title: translate("dashboard.programs.manage.notes"),
            dataIndex: "notes",
            key: "notes",
            width: "50%",
            render: (text: string, record: any, i: number) => (
                <TextArea
                    name={`${name}[${i}].notes`}
                    placeholder={translate("dashboard.programs.manage.notes")}
                />
            )
        },
        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            render: (text: string, record: any, i : number) => (
                <div>
                    <Button
                        className={"error-button mb-0"}
                        icon="far fa-trash-alt"
                        onClick={(e) => {

                            confirm({
                                title: translate("general.delete_alert_title"),
                                content: translate("general.delete_alert_content"),
                                okText: translate("general.ok_text"),
                                onOk : (()=> onRemoveAuditSubjectRow( i ,onRemoveAuditSubjectRow( i ,formik.values ) ))
                            })
                        }}
                    />
                </div>
            )
        }

    ];
    return (
        <>
            <Table
                rowClassName={() => 'editable-row'}
                dataSource={auditSubject}
                columns={columns}
                pagination={false}
            />
        </>
    );

}

export default AuditSubjectTableInput;

