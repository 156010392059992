import React, { useState, useEffect } from "react";
import Card from "common/components/dataDisplay/Card";
import {Col, Row, Tabs, notification} from "antd";
import Button from "common/components/general/Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MANAGE_BANNED_ACTIVITY } from "./ManageBannedActivity";
import PagedList from "core/application/commons/pagedList";
import { queryRequest } from "common/RequestUtils";
import {
  formatDataAsDashed,
  formatDateTimeReadable
} from "common/dateFormatters";
import TableEditButton from "common/components/tables/TableEditButton";
import { OTHER_AUDITS_PATH } from "../OtherAuditsWrapper";
import TableDeleteButton from "common/components/tables/TableDeleteButton";
import {
  getBannedActivities,
  BannedActivityDto
} from "core/application/bannedActivities/getBannedActivities";
import deleteBannedActivity from "core/application/bannedActivities/deleteBannedActivity";
import {v4 as uuidv4} from "uuid";
import AuditStatusTag from "../../../common/components/dataDisplay/AuditStatusTag";
import InputSearch, {SearchValues} from "../../common/InputSearch";
import {SearchFilter} from "core/domain/common/searchFilter";
import {Permission} from "core/domain/users/permission";
import {useSelector} from "react-redux";
import PermissionButton from "../../../common/components/general/PermissionButton";
import {AuditStatus} from "core/domain/common/auditStatus";
import Table from "common/components/dataDisplay/Table";

export const BANNED_ACTIVITIES_PATH = "banned-activities";

const BannedActivities: React.FC = () => {
  let history = useHistory();
  const { t: translate } = useTranslation();
  const userLoginInfo = useSelector((s: any) => s.userReducer);
  const [loading, setLoading] = useState<boolean>(false);
  const [item, setItems] = useState<PagedList<BannedActivityDto>>(
    {} as PagedList<BannedActivityDto>
  );
  const [searchFilter, setSearchFilter] = useState<SearchFilter>({
    pageNumber: 1,
    pageSize: 20,
    orderColumn: "createdAt",
    orderDescending: true
  });
  const searchInitialValues = {inputSearch: searchFilter.searchValue} as SearchValues;

  useEffect(() => {
    (async () => {
      await updateTableDate();
    })();
  }, [searchFilter]);

  const updateTableDate = async () => {
    setLoading(true);
    setItems(await queryRequest(() => getBannedActivities(searchFilter)));
    setLoading(false);
  };
  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate("general.name"),
        dataIndex: "name",
        key: "name",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => (
          <div>
            <span>{record.name}</span>
          </div>
        )
      },
      {
        title: translate("dashboard.banned_activities.operator"),
        dataIndex: "operatorName",
        key: "operatorName",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => (
          <div>
            <span>{record.operatorName}</span>
          </div>
        )
      },
      {
        title: translate("dashboard.banned_activities.subject"),
        dataIndex: "subjectName",
        key: "subjectName",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => (
          <div>
            <span>{record.subjectName}</span>
          </div>
        )
      },
      {
        title: translate("dashboard.banned_activities.date"),
        dataIndex: "date",
        key: "date",
        responsive: ["md"],
        sorter: true,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => formatDataAsDashed(record.date)
      },
      {
        title: translate("dashboard.status"),
        dataIndex: "status",
        key: `status_${uuidv4()}`,
        responsive: ["md"],
        sorter: true,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => (<AuditStatusTag status={record.status}/>)
      },
      {
        title: translate("dashboard.created_at"),
        dataIndex: "createdAt",
        key: "createdAt",
        responsive: ["md"],
        sorter: true,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) =>
          formatDateTimeReadable(record.createdAt)
      },
      {
        title: translate("dashboard.created_by"),
        dataIndex: "createdBy",
        key: "createdBy",
        responsive: ["md"],
        sorter: false,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => <span>{record.createdBy}</span>
      },
      {
        title: translate("dashboard.action"),
        width: "10%",
        dataIndex: "",
        key: "actions",
        align: "center",
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="actions flex flex__center">
              <TableEditButton
                permissions={record.status === AuditStatus.Published ?  [Permission.EditAfterPublished, Permission.All] : [Permission.BannedActivities]}
                userPermissions={userLoginInfo.user.permissions}
                className="mr-16"
                editUrl={`/${OTHER_AUDITS_PATH}/${BANNED_ACTIVITIES_PATH}/${MANAGE_BANNED_ACTIVITY}/${record.id}`}
              />
              <TableDeleteButton
                disableDeleted={record.children?.length > 0}
                onDelete={() => deleteBannedActivity(record.id)}
                onDeleted={() => updateTableDate()}
              />
            </div>
          </span>
        )
      }
    ];
    const columnToSort: any = columns.find(c => c.dataIndex === searchFilter.orderColumn);
    columnToSort.sortOrder = searchFilter.orderDescending ? "descend" : "ascend";
    return columns;
  };
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setSearchFilter({
      ...searchFilter,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || "createdAt",
      orderDescending: sorter.order !== "ascend"
    });
  };
  const onSearch =  (search : any) => {
    if (search.inputSearch && search.inputSearch?.length < 2) {
      notification.open({type: "error", message: translate("general.search_length")});
    } else {
      setSearchFilter({
        ...searchFilter,
        searchValue: search.inputSearch,
      });
    }
  }
  return (
    <Card
      title={
        <Row>
          <Col xs={12} md={10}>
            <h4>{translate("dashboard.banned_activities.title")}</h4>
          </Col>
          <Col xs={12} md={11}>
            <InputSearch
                placeholder={"Operatori, Data(dd/mm/yyyy)"}
                initialValues={searchInitialValues}
                onSearch={onSearch}
                translate={translate}
                loading={loading}
            />
          </Col>
          <Col xs={12} md={3}>
            <div className={"text-right"}>
              <Button
                icon="far fa-plus"
                type="primary"
                onClick={() => {
                  history.push(
                    `${history.location.pathname}/${MANAGE_BANNED_ACTIVITY}`
                  );
                }}
              >
                {translate("dashboard.add")}
              </Button>
            </div>
          </Col>
        </Row>
      }
    >
      <Table
        dataSource={item.items || []}
        onChange={onTableChange}
        columns={getColumns()}
        loading={loading}
        pagination={{
          total: item.totalItemCount,
          pageSize: item.pageSize,
          current: item.pageNumber,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "40"]
        }}
      />
    </Card>
  );
};

export default BannedActivities;
