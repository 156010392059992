import React from "react";
import {AuditItem, Sections} from "core/application/advertisementAudits/saveAdvertisementAudit";
import Select from "../../../common/components/dataEntry/formik/FormikSelectField";
import InputNumber from "../../../common/components/dataEntry/formik/FormikInputNumberField";
import Input from "../../../common/components/dataEntry/formik/FormikInputField";
import Button from "../../../common/components/general/Button";
import {useFormikContext} from "formik";
import {v4 as uuidv4} from "uuid";
import {NamedItem} from "core/application/commons/namedItem";
import Table from "common/components/dataDisplay/Table";

interface Props{
    auditItems: AuditItem[];
    translate: (key: string) => string;
    name: string;
    availableSubjects : any[],
    onDeleteAuditItems : any,
    onAddAuditItems : any,
    sectionIndex : number,
    formik : any,
    advertisementTypes: NamedItem[],
}
const AdvertisementAuditSectionsTableInput : React.FC<Props> = ({
    auditItems,
    translate,
    name,
    availableSubjects,
    onDeleteAuditItems,
    onAddAuditItems,
    sectionIndex,
    formik,
    advertisementTypes
}) => {

    const addRow = (values : any, index : number) => {
        if (values.sections[sectionIndex].items.length - 1 === index) {
            onAddAuditItems(values, sectionIndex)
        }
    }

    const resoleIndexRow = (rowIndex : number) => {
        let row = rowIndex;
        return `${++row}`
    }

    const getColumns = () =>{
        const columns = [
            {
                title: "",
                dataIndex: 'index',
                key: `index`,
                width:"5%",
                render:(text: string, record: any, i: number) => (
                    <span><b>{++i}</b></span>
                )
            },
            {
                title: 'Reklama',
                dataIndex: 'auditableSubjectId',
                key: "auditableSubjectId",
                width: "35%",
                sorter: false,
                editable: true,
                render:(text: string, record: any, i: number) => (
                    <Select
                        name={`${name}[${i}].auditableSubjectId`}
                        showSearch={true}
                        placeholder={'Reklama'}
                        onBlur={()=>addRow(formik.values,i)}
                    >
                        {availableSubjects.map((item : any) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Select>
                )
            },
            {
                title: `${translate("dashboard.advertisements.duration")}(sekonda)`,
                dataIndex: 'duration',
                key: "duration",
                width: "5%",
                sorter: false,
                editable: true,
                render:(text: string, record: any, i: number) =>(
                    <InputNumber
                        name={`${name}[${i}].duration`}
                        placeholder={translate("dashboard.advertisements.duration")}
                    />
                )
            },
            {
                title: translate("general.type"),
                dataIndex: 'AdvertisementTypeId',
                key: "AdvertisementTypeId",
                width: "35%",
                sorter: false,
                editable: true,
                render:(text: string, record: any, i: number) => (
                    <Select
                        name={`${name}[${i}].advertisementTypeId`}
                        showSearch={true}
                        placeholder={translate("general.type")}
                    >
                        {advertisementTypes.map((item : any) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Select>
                )
            },
            {
                title: translate("dashboard.advertisements.notes"),
                dataIndex: "notes",
                key: "notes",
                width: "20%",
                sorter: false,
                editable: true,
                render: (text: string, record: any, i: number) => (
                    <Input
                        name={`${name}[${i}].notes`}
                        placeholder={translate("dashboard.advertisements.notes")}
                    />
                )
            },
            {
                title: "",
                dataIndex: "actions",
                key: "actions",
                width: "10%",
                render: (text: string, record: any, key : number) => (
                    <div className="form-group">
                        <Button
                            className={"error-button"}
                            icon="far fa-trash-alt"
                            onClick={() => {
                                onDeleteAuditItems(sectionIndex, key, formik.values );
                            }}
                        />
                    </div>
                )
            }
        ];

        return columns;
    }

    return (
        <Table
            dataSource={auditItems}
            columns={getColumns()}
            pagination={false}
        />
    )
}

export default AdvertisementAuditSectionsTableInput;
