import {adminHttpClient} from "../commons/adminHttpClient";
import {NamedItem} from "../commons/namedItem";
import {AuditStatus} from "../../domain/common/auditStatus";

export interface NewsAuditForSave {
    id: string;
    date: Date;
    newsEditionId : string;
    notes : string;
    status : AuditStatus;
    auditItems : NewsAuditItems[];
    availableNewsEditions: any[];
    availableNewsTopics : NamedItem[];
    availableAuditableSubjects : NamedItem[];
    availableAuditees : any[];
}

export interface NewsAuditItems {
    id: string | null;
    newsTopicId : string;
    duration : number | null;
    auditSubjects : any[];
    index? : string | number;
}

export interface NewsAuditSubjects {
     id : any;
     auditableSubjectId : string | null;
     auditeeId : string | null;
     duration : number;
     syncTime : number;
     journalistTime: number;
     totalTime: number;
     auditeeSyncTime: number;
     notes : string;
     index? : string | number;
}

//TODO newsAuditItemSubjectForSaveDto
export const saveNewsAudit = (data : any) : Promise<any>  => {
    return adminHttpClient.post('/NewsAudits', data)
}

const getNewsAuditForSave = (id : string | undefined) : Promise<NewsAuditForSave> => {
    if (id){
        return adminHttpClient.get(`/newsAudits/getForSave/${id}`);
    }
    return adminHttpClient.get(`/newsAudits/getForSave`);
}


export default getNewsAuditForSave;
