import {adminHttpClient} from "../commons/adminHttpClient";
import { AuditReportDto, AuditReportFilter } from "./auditableSubjectsReport";
import { AuditGroupBy } from "../../domain/reports/auditGroupBy";

export interface ProgramAuditReportFilter extends AuditReportFilter {
    groupBy: AuditGroupBy;
}

export const generateProgramAuditsReport = (filter : ProgramAuditReportFilter) : Promise<AuditReportDto> => {
    return adminHttpClient.post(`reports/programAudits/generate`, filter);
}

export const generateProgramAuditsReportExcel = (filter : ProgramAuditReportFilter) : Promise<string> => {
    return adminHttpClient.post(`reports/programAudits/generateExcel`, filter);
}