class Keys {
  static TOKEN: string = "token";
  constructor() {}
}

export const store = {
  set: (key: string, value: string) => {
    localStorage.setItem(key, value);
  },

  get: (key: string): string | null => {
    return localStorage.getItem(key);
  }
};

export default {
  setToken: (token: string) => {
    store.set(Keys.TOKEN, token);
  },
  getToken: (): string | null => store.get(Keys.TOKEN),
  saveObject: (key: string, object: any) => {
    store.set(key, JSON.stringify(object));
  },
  getObject: (key: string) => {
    const value = store.get(key);
    return value ? JSON.parse(value) : null;
  },
  remove: (key: string) => {
    localStorage.removeItem(key);
  },
};
