import React from "react";
import {useField, useFormikContext} from "formik";
import Error from "./FormItemError";
import InputMask from "../components/InputMask";
import Label from "../components/Label";
import FieldProps from "./FieldProps";

import "./FormikStyles.less";
import classnames from "classnames";

interface InputMaskProps extends FieldProps {
    mask: string;
    hint?: string;
    disabled?: boolean;
}

const FormikInputMaskDefaultField: React.FC<InputMaskProps> = props => {
    const [field, meta] = useField(props);
    const showError = meta.touched && meta.error;
    const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;
    const {setFieldValue} = useFormikContext();
    const context = useFormikContext();

    const onValueChanged = (e: any) => {
        setFieldValue(props.name, e.target.value);
    }

    const onBlur = (e: any): void => {
        context.setFieldTouched(props.name, true, true);

        if(props.onBlur) {
            props.onBlur(e);
        }
    };

    const inputErrorClassname = classnames({ border_error: showError });

    return (
        <>
            <Label
                htmlFor={props.id || props.name}
                className=" no-select hand-on-hover">
                {props.label}
                {props.hint ? (
                    <span className="formik-field__span"> {props.hint}</span>
                ) : null}
            </Label>
            <InputMask
                className={`${inputErrorClassname} input__no-autofill-color ${props.className}`}
                {...field}
                {...props}
                onChange={onValueChanged}
                onBlur={onBlur}
            />

            {showError && (<div className="mt-4">{error}&nbsp;</div>)}
        </>
    );
};

export default FormikInputMaskDefaultField;
