import React, {useEffect, useState} from "react";
import {Col, Row, Skeleton} from "antd";
import {Form as FormikForm, Formik} from "formik";
import Card from "../../common/components/dataDisplay/Card";
import Select from "../../common/components/dataEntry/formik/FormikSelectField";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import * as Yup from "yup";
import {commandRequest, queryRequest} from "../../common/RequestUtils";
import DatePicker from "../../common/components/dataEntry/formik/FormikDatePickerField";
import moment from "moment";
import TextArea from "common/components/dataEntry/formik/FormikTextAreaField";
import {saveTickerNewsAudit} from "core/application/newsTickerAudit/saveNewsTicker";
import notification from "../../common/components/feedback/Notification";
import AuditSubjectTableInput from "./tables/AuditSubjecTableInput";
import Button from "../../common/components/general/Button";
import getNewsTickerForSave, {
    AuditSubject,
    NewsTickerAuditForSave, SaveTicker
} from "core/application/newsTickerAudit/saveNewsTicker";
import InputMask from "../../common/components/dataEntry/formik/FormikInputMaskField";
import SaveAuditFormButtons from "../../common/components/forms/SaveAuditFormButtons";
import {AuditStatus} from "core/domain/common/auditStatus";
import store from "core/application/commons/localStorage";
import _ from "lodash";

export const MANAGE_TICKER_PATH= "manage-ticker";

const formValidator = (translate: any) =>
    Yup.object({
        operatorId: Yup.string()
            .required(translate("general.required"))
            .nullable()
            .typeError(translate("general.required")),
        date: Yup.date()
            .required(translate("general.required"))
            .typeError(translate("general.required"))
            .nullable(),
        startTime: Yup.string()
            .required(translate("general.required"))
            .nullable()
            .typeError(translate("general.required")),
        endTime: Yup.string()
            .required(translate("general.required"))
            .nullable()
            .typeError(translate("general.required")),
        auditSubjects: Yup.array()
            .of(
                Yup.object().shape({
                    auditableSubjectId: Yup.string()
                        .trim().required(translate("general.required"))
                        .typeError(translate("general.required"))
                        .nullable(),
                    duration: Yup.number()
                        .nullable()
                        .required(translate("general.required"))
                        .moreThan(0, "Më e madhe se 0"),
                })
            )
    });
const getAutoSaveCacheKey = (id: any) => `ticker_audit_${id}`;

const ManageTicker : React.FC = () => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const [item, setItem] = useState<NewsTickerAuditForSave | null>(null)
    const [loading, setLoading] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    useEffect( () =>{
        (async () => {
            await updateForm();
        })()
    },[])

    const updateForm = async() =>{
        setLoading(true);
        const result = (await queryRequest(() => getNewsTickerForSave(id)));
        const cached = store.getObject(getAutoSaveCacheKey(result.id));
        if(cached && result.status === AuditStatus.Draft) {
            cached.availableOperators = result.availableOperators;
            cached.availableAuditableSubjects = result.availableAuditableSubjects;
            setItem(cached);
        }
        else {
            setItem(result);
        }
        setLoading(false);
    }

    const onRemoveAuditSubjectRow = (index: number ,values : any) => {
        if (values) {
            values.auditSubjects.splice(index, 1);
            setItem({...values,  auditSubjects : [...values.auditSubjects] })
        }
    }

    const onAddAuditSubjectRow = (values : any) => {
        if (values) {
            setItem({
                ...values,
                auditSubjects: [...values.auditSubjects, newAuditSubject(values.auditSubjects.length + 1)]
            })
        }
    }

    const newAuditSubject = (index: number): AuditSubject => {
        return {
            id: null,
            notes: "",
            duration: 0,
            auditableSubjectName: "",
            auditableSubjectId: null,
        }
    }

    const onSubmit = async (values : any, status: number) => {
        if (!formValidator(translate).isValidSync({...values})) {
            return
        }
        let auditSubjects = values.auditSubjects.map((c: any, key : number) => {
            c.index = key + 1
            return c
        })
        let startTime = moment.parseZone(values.startTime, "HH:mm").format("HH:mm")
        let endTime = moment.parseZone(values.endTime, "HH:mm").format("HH:mm")

        const request : SaveTicker = {
            id : values.id,
            auditSubjects: auditSubjects,
            date : values.date,
            endTime: endTime,
            startTime : startTime,
            notes : values.notes,
            operatorId : values.operatorId,
            status
        }

        setIsFormSubmitted(true);
        const result = await commandRequest(()=>saveTickerNewsAudit(request))
        setIsFormSubmitted(false);

        if (!result.errors) {
            notification.open({
                message: translate("general.saved_plural").replace(
                    "[]",
                    translate("dashboard.ticker.tickers")
                ),
                type: "success"
            });
            store.remove(getAutoSaveCacheKey(values.id));

            history.goBack();
        } else {
            notification.open({message: result.errors[0], type: "error"});
        }
    }

    const lockAudit = async (values: any) => {
        const shouldLock = !values.id && values.operatorId && values.date && values.startTime && values.endTime;
        if (!shouldLock) return;

        let startTime = moment.parseZone(values.startTime, "HH:mm").format("HH:mm")
        let endTime = moment.parseZone(values.endTime, "HH:mm").format("HH:mm")

        const request : SaveTicker = {
            auditSubjects: [],
            date : values.date,
            endTime: endTime,
            startTime : startTime,
            notes : values.notes,
            operatorId : values.operatorId,
            status: AuditStatus.Draft
        }

        setIsFormSubmitted(true)
        const result = await commandRequest(()=> saveTickerNewsAudit(request))
        setIsFormSubmitted(false)

        if (result.errors) {
            notification.open({message: result.errors[0], type: "error"});
            return;
        }

        if (!item) return;

        setItem({
            ...item,
            operatorId: values.operatorId,
            date: values.date,
            notes: values.notes,
            startTime: startTime,
            endTime: endTime,
            auditSubjects: [
                newAuditSubject(values.auditSubjects.length + 1)
            ],
            id: result,
        })
    }

    return (
        <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 6, className: "p-64 color-gray-5" }}
            title={false}
        >
            {" "}
            {item && (
                <Formik
                    innerRef={instance => {
                        if(instance && instance.values && item.id && item.status === AuditStatus.Draft) {
                            store.saveObject(getAutoSaveCacheKey(item.id), instance.values);
                        }
                    }}
                    enableReinitialize={true}
                    initialValues={item}
                    validationSchema={formValidator(translate)}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={() => {}}
                >
                    {formik => (
                        <div className="form_center">
                            <Card
                                bordered={true}
                                title={id ? translate("dashboard.ticker.edit"): translate("dashboard.ticker.add")}
                            >
                                <FormikForm>
                                    <Row>
                                        <Col span={24}>
                                            <Select
                                                name="operatorId"
                                                showSearch={true}
                                                label={`${translate("dashboard.ticker.operatorId")}`}
                                                placeholder={translate("dashboard.ticker.operatorId")}
                                                onBlur={async () => {
                                                    await lockAudit(formik.values);
                                                }}
                                            >
                                                {item.availableOperators.map(index => (
                                                    <option key={index.id} value={index.id}>
                                                        {index.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row gutter={10}>
                                        <Col xs={24} lg={12}>
                                            <DatePicker
                                                label={translate("general.date")}
                                                name="date"
                                                allowClear={false}
                                                placeholder={translate("general.date")}
                                                value={
                                                    formik.values.date
                                                        ? moment(formik.values.date)
                                                        : null
                                                }
                                                disabledDate={d =>
                                                    !d || d.isAfter(moment().subtract(0, "day"))
                                                }
                                                className="full_width"
                                                onBlur={async () => {
                                                    await lockAudit(formik.values);
                                                }}
                                            />
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <InputMask
                                                mask="11:11"
                                                name={`startTime`}
                                                label={translate("dashboard.liveBroadcast.auditItems.startTime")}
                                                className={"w-100"}
                                                placeholder={"00:00"}
                                                onBlur={async () => {
                                                    await lockAudit(formik.values);
                                                }}
                                            />
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <InputMask
                                                mask="11:11"
                                                name={`endTime`}
                                                label={translate("dashboard.liveBroadcast.auditItems.endTime")}
                                                placeholder={"00:00"}
                                                onBlur={async () => {
                                                    await lockAudit(formik.values);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <TextArea
                                                name="notes"
                                                label={`${translate("dashboard.ticker.notes")}`}
                                                placeholder={translate("dashboard.ticker.notes")}
                                            >
                                            </TextArea>
                                        </Col>
                                    </Row>
                                </FormikForm>
                            </Card>
                            {item.id &&
                                <Card>
                                    <AuditSubjectTableInput
                                        auditSubject={item?.auditSubjects}
                                        datasource={item}
                                        name={'auditSubjects'}
                                        translate={translate}
                                        onRemoveAuditSubjectRow={onRemoveAuditSubjectRow}
                                        onAddAuditSubjectRow={onAddAuditSubjectRow}
                                        formik={formik}
                                    />
                                    <Button className={"mt-8"} type="primary" onClick={() => onAddAuditSubjectRow(formik.values)}>Add</Button>
                                </Card>
                            }
                            <div className={"text-center mb-16"}>
                                <SaveAuditFormButtons
                                    onSaveAsDraftClick={async () => {
                                        await formik.submitForm();
                                        await onSubmit(formik.values, AuditStatus.Draft)
                                    }}
                                    onPublishClick={async () => {
                                        await formik.submitForm();
                                        await onSubmit(formik.values, AuditStatus.Published)
                                    }}
                                    loading={isFormSubmitted}
                                />
                            </div>
                        </div>
                    )}
                </Formik>
            )}
        </Skeleton>
    )
}

export default ManageTicker;
