import React from "react";
import { useField, useFormikContext } from "formik";
import classnames from "classnames";

import Error from "./FormItemError";
import Input, { InputProps } from "../components/Input";
import FieldProps from "./FieldProps";
import Label from "../components/Label";

import "./FormikStyles.less";

export type FormikInputProps = InputProps & FieldProps & { hint?: string };

const FormikDefaultInputField: React.FC<FormikInputProps> = props => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const showError = meta.touched && meta.error;
  const error = showError ? <Error>{meta.error}</Error> : <Error> </Error>;
  const id = props.id || props.name;

  const onChange = (e: any) => {
    setFieldValue(props.name, e.target.value);

    if (props.onChange){
      props.onChange(e)
    }
  };

  const hint = props.hint ? (
    <span className="formik-field__span"> {props.hint}</span>
  ) : null;

  const inputErrorClassname = classnames({ border_error: showError });

  return (
    <>
      <div className={"form-group"}>
        <div>
          <Label
            htmlFor={id}
            className="formik-field__input-label no-select hand-on-hover"
          >
            {props.label}
            {hint}
          </Label>
          <Input
            {...field}
            {...props}
            className={`${inputErrorClassname} ${props.className}`}
            id={id}
            onChange={onChange}
          />
        </div>
        {showError && (<div className="mt-4">{error}&nbsp;</div>)}
      </div>
    </>
  );
};

export default FormikDefaultInputField;
