import { NamedItemInt } from "application/commons/namedItem";

export enum AuditGroupBy {
    AuditableSubject = 0,
    Auditee = 1
}

export const getAuditGroupBy = (translate: (key: string) => string): NamedItemInt[] => {
    var types: NamedItemInt[] = [];
    types.push({
      	id: AuditGroupBy.AuditableSubject,
      	name: translate("dashboard.reports.auditable_subject")
    });
    types.push({
      	id: AuditGroupBy.Auditee,
      	name: translate("dashboard.reports.auditee")
    });
	return types;
};