import React, { ReactNode, CSSProperties } from "react";
import { Avatar } from "antd";
import Icon from "../general/Icon";

interface AvatarProps {
  size?: "large" | "default" | "small" | number;
  shape?: "square" | "circle";
  icon?: string;
  text?: string;
  src?: string;
  style?: CSSProperties;
  className?: string;
}

const Display = (props: AvatarProps) => {
  return (
    <Avatar {...props} icon={props.icon && (<Icon type={props.icon} />)}>
      {props.text}
    </Avatar>
  );
};

export default Display;
