import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PagedList from "core/application/commons/pagedList";
import getUsers , {UserDto} from "core/application/users/getUsers";
import {queryRequest} from "../../common/RequestUtils";
import {formatDateTimeReadable} from "../../common/dateFormatters";
import TableEditButton from "../../common/components/tables/TableEditButton";
import TableDeleteButton from "../../common/components/tables/TableDeleteButton";
import Card from "../../common/components/dataDisplay/Card";
import {Col, Row} from "antd";
import Table from "common/components/dataDisplay/Table";
import Button from "../../common/components/general/Button";
import {CREATE_USER_PATH} from "./CreateUser";
import {UPDATE_USER_PATH} from "./UpdateUser";
import deleteUser from "core/application/users/deleteUser";
import {CHANGE_PASSWORD_FOR_USER_PATH} from "./ChangePasswordForUser";
import {v4 as uuidv4} from "uuid";

export const USERS_PATH = "users";

const Users : React.FC = () => {

    let history = useHistory();
    const {t: translate} = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [item, setItems] = useState<PagedList<UserDto>>({} as PagedList<UserDto>);
    const [searchFilter, setSearchFilter] = useState({
        pageNumber: 1,
        pageSize: 20,
        orderColumn: "createdAt",
        orderDescending: true
    });
    useEffect(() => {
        (async () => {
            await updateTableDate();
        })();
    }, [searchFilter])

    const updateTableDate = async () => {
        setLoading(true);
        setItems(await queryRequest(() => getUsers(searchFilter)))
        setLoading(false);
    }
    const getColumns = (): any[] => {
        const columns = [
            {
                title: translate("general.name"),
                dataIndex: "name",
                key: "name",
                width: "30%",
                render: (text: string, record: any) => (
                    <div>
                        <span>{record.name}</span>
                    </div>
                )
            },
            {
                title: translate("users.username"),
                dataIndex: "username",
                key: "username",
                width: "30%",
                sorter: true,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => (
                    <div>
                        <span>{record.username}</span>
                    </div>
                )
            },
            {
                title: translate("dashboard.last_login"),
                dataIndex: "lastLogin",
                key: "lastLogin",
                responsive: ["md"],
                sorter: false,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => (
                    <span>{record.lastLogin ? formatDateTimeReadable(record.lastLogin) : "-"}</span>
                )
            },
            {
                title: translate("dashboard.created_at"),
                dataIndex: "createdAt",
                key: "createdAt",
                responsive: ["md"],
                sorter: true,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => formatDateTimeReadable(record.createdAt)
            },
            {
                title: translate("dashboard.action"),
                width: "10%",
                dataIndex: "",
                key: "actions",
                align: "center",
                sorter: false,
                render: (text: string, record: any) => (
                    <span>
                        <div className="actions flex flex__center">
                         <Button
                             className="mr-16"
                             iconPadding={false}
                             icon={"far fa-lock"}
                             onClick={() => {
                                 history.push(`/${USERS_PATH}/${CHANGE_PASSWORD_FOR_USER_PATH}/${record.id}`);
                             }}
                         />

                           <TableEditButton
                               className="mr-16"
                               editUrl={`/${USERS_PATH}/${UPDATE_USER_PATH}/${record.id}`}
                           />
                          <TableDeleteButton
                              disableDeleted={record.children?.length > 0}
                              onDelete={() => deleteUser(record.id)}
                              onDeleted={() => updateTableDate()}
                          />
                        </div>
                    </span>
                )
            }
        ];
        const columnToSort: any = columns.find(c => c.dataIndex === searchFilter.orderColumn);
        columnToSort.sortOrder = searchFilter.orderDescending ? "descend" : "ascend";
        return columns;
    };
    const onTableChange = (pagination: any, filters: any, sorter: any) => {
        setSearchFilter({
            ...searchFilter,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            orderColumn: sorter.field || "createdAt",
            orderDescending: sorter.order !== "ascend"
        });
    };
    return (
        <Card title={
            <Row>
                <Col xs={12} md={14} span={16}>
                    <h4>{translate("users.name")}</h4>
                </Col>
                <Col xs={12} md={10}>
                    <div className={"text-right"}>
                        <Button
                            icon="far fa-plus"
                            type="primary"
                            onClick={() => {
                                history.push(
                                    `${history.location.pathname}/${CREATE_USER_PATH}`
                                );
                            }}>
                            {translate("dashboard.add")}
                        </Button>
                    </div>
                </Col>
            </Row>
        }>
            <Row>
                <Col span={24}>
                    <Table
                        dataSource={item.items || []}
                        onChange={onTableChange}
                        columns={getColumns()}
                        loading={loading}
                        rowKey={() => uuidv4()}
                        pagination={{
                            total: item.totalItemCount,
                            pageSize: item.pageSize,
                            current: item.pageNumber,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "20", "30", "40"]
                        }}
                    />
                </Col>
            </Row>
        </Card>
    )
}

export default Users;