import React, {useEffect, useState} from "react";
import {Formik, Form as FormikForm} from "formik";

import * as Yup from "yup";

import {Row, Col, Skeleton} from "antd";

import Card from "../../common/components/dataDisplay/Card";
import Button from "common/components/general/Button";
import Select from "common/components/dataEntry/formik/FormikSelectField";
import DatePicker from "common/components/dataEntry/formik/FormikDatePickerField";
import AdvertisementAuditTableInputs from "./tables/AdvertisementAuditTableInputs"
import notification from "../../common/components/feedback/Notification";

import moment from "moment";

import {useTranslation} from "react-i18next";
import {useParams, useHistory} from "react-router";
import {
    AdvertisementAudit,
    SaveAdvertisementAudit,
    Sections
} from "core/application/advertisementAudits/saveAdvertisementAudit";
import {AuditItem} from "core/application/advertisementAudits/saveAdvertisementAudit";
import {queryRequest, commandRequest} from "../../common/RequestUtils";
import manageAdvertisementAudit from "core/application/advertisementAudits/saveAdvertisementAudit";
import {saveAdvertisementAudit} from "core/application/advertisementAudits/saveAdvertisementAudit";
import TextArea from "../../common/components/dataEntry/formik/FormikTextAreaField";
import _ from "lodash";
import SaveAuditFormButtons from "../../common/components/forms/SaveAuditFormButtons";
import {AuditStatus} from "core/domain/common/auditStatus";
import store from "core/application/commons/localStorage";
import useUrlQueryParam from "common/hooks/useUrlQueryParam";

export const MANAGE_ADVERTISEMENT_AUDIT_PATH = 'manage-advertisement-audit';


const formValidator = (translate: any) =>
    Yup.object({
        operatorId: Yup.string()
            .required(translate("general.required"))
            .nullable(),
        date: Yup.date()
            .required(translate("general.required"))
            .typeError(translate("general.required")),
        sections: Yup.array()
            .of(
                Yup.object().shape({
                    time: Yup.string()
                        .nullable()
                        .required(translate("general.required")),
                    items: Yup.array()
                        .of(
                            Yup.object().shape({
                                auditableSubjectId: Yup.string()
                                    .required(translate("general.required"))
                                    .nullable(),
                                advertisementTypeId: Yup.string()
                                    .required(translate("general.required"))
                                    .nullable(),
                                duration: Yup.number()
                                    .nullable()
                                    .required(translate("general.required"))
                                    .moreThan(0, "Më e madhe se 0")
                            })
                        )
                })
            )
    });

const getAutoSaveCacheKey = (id: any) => `advertisement_audit_${id}`;

const ManageAdvertisementAudit: React.FC = () => {
    const {t: translate} = useTranslation();
    const history = useHistory();
    const {id} = useParams();
    const duplicateFromId = useUrlQueryParam("duplicateFromId");
    const [item, setItem] = useState<AdvertisementAudit | null>(null);
    const [loading, setLoading] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const onSubmit = async (data: any, status: number) => {
        if(!formValidator(translate).isValidSync({...data})) {
            return
        }

        let sections = data.sections.map((c: any, key: number) => {
            c.index = key + 1
            c.items = c.items.map((r: any, rkey: number) => {
                r.index = rkey + 1
                return r
            })
            return c
        })

        const itemToSave: SaveAdvertisementAudit = {
            id: data.id,
            date: data.date,
            operatorId: data.operatorId,
            notes: data.notes,
            sections: sections,
            status,
        }

        setIsFormSubmitted(true);
        const result = await commandRequest(() => saveAdvertisementAudit(itemToSave));
        setIsFormSubmitted(false);

        if (!result.errors) {
            notification.open({
                message: translate("general.saved_plural").replace(
                    "[]",
                    translate("dashboard.advertisements.advertisementAudits")
                ),
                type: "success"
            });

            store.remove(getAutoSaveCacheKey(data.id))

            history.goBack();
        } else {
            notification.open({message: result.errors[0], type: "error"});
        }
    }

    useEffect(() => {
        (async () => {
            await updateForm();
        })()
    }, []);

    const updateForm = async () => {
        setLoading(true);
        if(duplicateFromId) {
            const result =(await queryRequest(() => manageAdvertisementAudit(duplicateFromId)));
            result.id = null;
            result.date = null;
            setItem(result);
        } else {
            const result =(await queryRequest(() => manageAdvertisementAudit(id)));
            const cached = store.getObject(getAutoSaveCacheKey(result.id))
            if(cached && result.status === AuditStatus.Draft) {
                cached.availableAdvertisementTypes = result.availableAdvertisementTypes;
                cached.availableOperators = result.availableOperators;
                cached.availableSubjects = result.availableSubjects;
                setItem(cached);
            }
            else {
                setItem(result);
            }
        }
        setLoading(false);
    }

   const newSection = (): Sections => {
      return {
          id: null,
          time: null,
          items: [{
              id: null,
              auditableSubjectId: null,
              advertisementTypeId: null,
              duration: null,
              notes: "",
          }],
      }
   }

    const addSections = (values: any) => {
        if (values) {
            let clone = {...values, sections: [...values.sections, newSection()]}
            setItem(clone)
        }
    }

    const addAuditItems = (values: any, index: number) => {
        if (values) {
            //Create new auditItem
            const auditItems: AuditItem = {
                id: null,
                auditableSubjectId: null,
                advertisementTypeId: null,
                duration: null,
                notes: "",
            }
            //Push that item in the items object
            const clone = _.cloneDeep(values);
            clone.sections[index].items.push(auditItems)
            setItem({...clone})
        }
    }

    const onDeleteSections = (i: number, values: AdvertisementAudit) => {
        if (values) {
            values.sections.splice(i, 1);
            setItem({...values, sections: [...values.sections]})
        }
    };

    const onDeleteAuditItem = (sectionIndex: number, key: number, values: AdvertisementAudit) => {
        if (values) {
            const clone = _.cloneDeep(values);
            clone.sections[sectionIndex].items.splice(key, 1);
            setItem({...clone});
        }
    }

    const lockAudit = async (values: any) =>  {
        const shouldLock = !values.id && !duplicateFromId && values.operatorId && values.date;
        if(!shouldLock) return;

        const request = {
            id: values.id,
            date: values.date,
            operatorId: values.operatorId,
            notes: values.notes,
            sections: [],
            status: AuditStatus.Draft
        } as SaveAdvertisementAudit;

        setIsFormSubmitted(true)
        const result = await commandRequest(() => saveAdvertisementAudit(request));
        setIsFormSubmitted(false)

        if(result.errors) {
            notification.open({message: result.errors[0], type: "error"});
            return;
        }

        if(!item) return;

        setItem({
            ...item,
            date: values.date,
            operatorId: values.operatorId,
            notes: values.notes,
            sections: [newSection()],
            id: result,
        })
    }

    return (
        <Skeleton
            active
            loading={loading}
            paragraph={{rows: 6, className: "color-gray-5"}}
            title={false}>
            {" "}
            {item && (
                <Formik
                    innerRef={instance => {
                        if(instance && instance.values && item.id && item.status === AuditStatus.Draft) {
                            store.saveObject(getAutoSaveCacheKey(item.id), instance.values);
                        }
                    }}
                    enableReinitialize={true}
                    initialValues={item}
                    validationSchema={formValidator(translate)}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={() => {}}>
                    {formik => (
                        <div>
                            <Card
                                className={'container'}
                                bordered={true}
                                title={duplicateFromId ? translate("dashboard.advertisements.duplicateAdvertisementAudit") : (id ? translate("dashboard.advertisements.editAdvertisementAudit") : translate("dashboard.advertisements.addAdvertisementAudit"))}
                            >
                                <FormikForm>
                                    <Row gutter={10}>
                                        <Col sm={24} lg={12}>
                                            <Select
                                                name="operatorId"
                                                showSearch={true}
                                                label={`${translate("dashboard.advertisements.operator")}`}
                                                placeholder={translate("dashboard.advertisements.operator")}
                                                onBlur={async () => {
                                                   await lockAudit(formik.values);
                                                }}
                                            >
                                                {item.availableOperators.map(index => (
                                                    <option key={index.id} value={index.id}>
                                                        {index.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col xs={24} lg={12}>
                                            <DatePicker
                                                onBlur={async () => {
                                                   await lockAudit(formik.values);
                                                }}
                                                label={translate("dashboard.advertisements.date")}
                                                name="date"
                                                allowClear={false}
                                                placeholder={translate("dashboard.advertisements.date")}
                                                value={
                                                    formik.values.date
                                                        ? moment(formik.values.date)
                                                        : null
                                                }
                                                disabledDate={d =>
                                                    !d || d.isAfter(moment().subtract(0, "day"))
                                                }
                                                className="full_width"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <TextArea
                                                name="notes"
                                                label={`${translate("dashboard.news.audit.notes")}`}
                                                placeholder={translate("dashboard.news.audit.notes")}
                                            >
                                            </TextArea>
                                        </Col>
                                    </Row>
                                </FormikForm>
                            </Card>

                            {(item.id || duplicateFromId) && (
                                <Card>
                                    <AdvertisementAuditTableInputs
                                        sections={item?.sections}
                                        name="sections"
                                        translate={translate}
                                        availableSubjects={item?.availableSubjects}
                                        advertisementTypes={item?.availableAdvertisementTypes}
                                        onDeleteSections={onDeleteSections}
                                        onDeleteAuditItem={onDeleteAuditItem}
                                        onAddSection={addSections}
                                        onAddAuditItems={addAuditItems}
                                        formik={formik}
                                    />
                                    <Button className={"mt-8"} type="primary"
                                            onClick={() => addSections(formik.values)}>
                                        {translate("general.add")}
                                    </Button>
                                </Card>
                            )}

                            <div className={"text-center mb-16"}>
                                <SaveAuditFormButtons
                                    onSaveAsDraftClick={async () => {
                                        //Hack to trigger validation
                                        await formik.submitForm()
                                        await onSubmit(formik.values, AuditStatus.Draft)
                                    }}
                                    onPublishClick={async () => {
                                        //Hack to trigger validation
                                        await formik.submitForm()
                                        await onSubmit(formik.values, AuditStatus.Published)
                                    }}
                                    loading={isFormSubmitted}
                                />
                            </div>
                        </div>
                    )}
                </Formik>
            )}
        </Skeleton>
    );
}

export default ManageAdvertisementAudit;

