import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { commandRequest, queryRequest } from "../../../common/RequestUtils";
import notification from "../../../common/components/feedback/Notification";
import { Col, Row, Skeleton } from "antd";
import { Form as FormikForm, Formik } from "formik";
import Card from "../../../common/components/dataDisplay/Card";
import Input from "../../../common/components/dataEntry/formik/FormikInputField";
import FormSaveCancelButtons from "../../../common/components/forms/SaveCancelFormButtons";
import * as Yup from "yup";
import {
  getNotesForSave,
  NotesForSaveDto,
  NotesType,
  saveProhibitedActivityNotes,
  saveViolationNotes
} from "core/application/notes/saveNotes";
import TextArea from "../../../common/components/dataEntry/formik/FormikTextAreaField";
import SaveAuditFormButtons from "../../../common/components/forms/SaveAuditFormButtons";
import {AuditStatus} from "core/domain/common/auditStatus";

export const MANAGE_NOTES = "manage-notes";
const formValidator = (translate: any) =>
  Yup.object({
    title: Yup.string()
      .required(translate("general.required"))
      .nullable()
      .typeError(translate("general.required"))
  });

const ManageNotes: React.FC = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { type, id } = useParams();
  const [item, setItem] = useState<NotesForSaveDto | null>(null);
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    (async () => {
      await updateForm();
    })();
  }, []);

  const updateForm = async () => {
    setLoading(true);
    setItem(await queryRequest(() => getNotesForSave(id)));
    setLoading(false);
  };

  const lockAudit = async (values: any) => {
    const shouldLock = !values.id && values.title && values.description;
    if (!shouldLock) return;

    const request = {
      title : values.title,
      description : values.description
    }

    setIsFormSubmitted(true)
    const result = await commandRequest(() => saveViolationNotes(request))
    setIsFormSubmitted(false)

    if (result.errors) {
      notification.open({message: result.errors[0], type: "error"});
      return;
    }

    if (!item) return;

    setItem({
      ...item,
      id: result,
      title : values.title,
      description : values.description
    })
  }

  const onSubmit = async (values: any, status : any) => {
    let result =  await commandRequest(() => saveViolationNotes({...values, status : status}));;
    // switch (type) {
    //   case NotesType.Violation:
    //     result = await commandRequest(() => saveViolationNotes(values));
    //     break;
    //   case NotesType.ProhibitedActivity:
    //     result = await commandRequest(() =>
    //       saveProhibitedActivityNotes(values)
    //     );
    //     break;
    //}
    if (result && !result.errors) {
      notification.open({
        message: translate("general.saved").replace(
          "[]",
          translate("dashboard.notes.the_note")
        ),
        type: "success"
      });
      history.goBack();
    } else {
      notification.open({ message: result.errors[0], type: "error" });
    }
  };
  return (
    <Skeleton
      active
      loading={loading}
      paragraph={{ rows: 6, className: "p-64 color-gray-5" }}
      title={false}
    >
      {" "}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {formik => (
            <div>
              <Card
                bordered={true}
                title={
                  id
                    ? translate("dashboard.notes.edit")
                    : translate("dashboard.notes.add")
                }
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        name="title"
                        label={`${translate("dashboard.notes.title")}`}
                        placeholder={`${translate("dashboard.notes.title")}`}
                        onBlur={async () => {
                          await lockAudit(formik.values);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <TextArea
                        name="description"
                        label={`${translate("dashboard.notes.description")}`}
                        placeholder={`${translate(
                          "dashboard.notes.description"
                        )}`}
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>
              <div className={"text-center mb-16"}>
                <SaveAuditFormButtons
                    onSaveAsDraftClick={async () => {
                      await formik.submitForm();
                      await onSubmit(formik.values, AuditStatus.Draft)
                    }}
                    onPublishClick={async () => {
                      await formik.submitForm();
                      await onSubmit(formik.values, AuditStatus.Published)
                    }}
                    loading={isFormSubmitted}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  );
};

export default ManageNotes;
