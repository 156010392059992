import {adminHttpClient} from "../commons/adminHttpClient";
import OrderedPageFilter from  "../commons/orderedPageFilter";
import PagedList from "../commons/pagedList";

export interface NotesDto{
    id: string | null;
    title: string;
    createdBy : string;
    createdAt : string;
}

const getViolationNotes = (filter : OrderedPageFilter) : Promise<PagedList<NotesDto>>  => adminHttpClient.get(`/Notes/Violations/Get`, filter)
const getProhibitedActivitiesNotes = (filter : OrderedPageFilter) : Promise<PagedList<NotesDto>>  => adminHttpClient.get(`/Notes/ProhibitedActivities/Get`, filter)

export {getViolationNotes, getProhibitedActivitiesNotes}