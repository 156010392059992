import { NamedItemInt } from "application/commons/namedItem";

export enum NewsAuditGroupBy {
    NewsTopic = 0,
    AuditableSubject = 1,
    Auditee = 2
}

export const getNewsAuditGroupBy = (translate: (key: string) => string): NamedItemInt[] => {
    var types: NamedItemInt[] = [];
    types.push({
        id: NewsAuditGroupBy.NewsTopic,
        name: translate("dashboard.reports.news_topic")
    });
    types.push({
      	id: NewsAuditGroupBy.AuditableSubject,
      	name: translate("dashboard.reports.auditable_subject")
    });
    types.push({
      	id: NewsAuditGroupBy.Auditee,
      	name: translate("dashboard.reports.auditee")
    });
	return types;
};