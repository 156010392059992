import React, {useEffect, useState} from 'react';
import {Row, Col, notification,} from "antd";
import Card from "../../common/components/dataDisplay/Card";
import Button from "../../common/components/general/Button";

import PagedList from "core/application/commons/pagedList";
import {NewsAudit} from "core/application/newsAudits/getNewsAudits";
import {queryRequest} from "../../common/RequestUtils";
import getNewsAudit from "core/application/newsAudits/getNewsAudits";
import deleteNewsAudit from "core/application/newsAudits/deleteNewsAudit";

import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import NewsAuditsTable from "./tables/NewsAuditsTable";
import {MANAGE_NEWS_AUDIT} from "./ManageNewsAudit";
import { useSelector } from "react-redux";
import PermissionButton from "common/components/general/PermissionButton";
import { Permission } from "core/domain/users/permission";
import InputSearch, {SearchValues} from "../common/InputSearch";
import {SearchFilter} from "core/domain/common/searchFilter";

export const NEWS_AUDIT_PATH = 'news-audit'

const NewsAudits : React.FC = () => {
    let history = useHistory();
    const {t: translate} = useTranslation();
    const [loading , setLoading] = useState<boolean>(false);
    const userLoginInfo = useSelector((s: any) => s.userReducer);
    const [items , setItems] = useState<PagedList<NewsAudit>>({} as PagedList<NewsAudit>);
    const [searchFilter, setSearchFilter] = useState<SearchFilter>({
                                                        pageNumber: 1,
                                                        pageSize: 20,
                                                        orderColumn: "createdAt",
                                                        orderDescending: true
                                                    });
    const searchInitialValues = {inputSearch: searchFilter.searchValue} as SearchValues;


    useEffect(() => {
        (async() => {
            await updateTableDate();
        })();
    }, [searchFilter])

    const updateTableDate = async () =>{
        setLoading(true);
        setItems(await queryRequest(() => getNewsAudit(searchFilter)))
        setLoading(false);
    }
    const onSearch =  (search : any) => {
        if (search.inputSearch && search.inputSearch?.length < 2) {
            notification.open({type: "error", message: translate("general.search_length")});
        } else {
            setSearchFilter({
                ...searchFilter,
                searchValue: search.inputSearch,
            });
        }
    }
    return (
        <Card title={
            <Row>
                <Col xs={12} md={10}>
                    <h4>{translate("dashboard.news.newsAudit")}</h4>
                </Col>
                <Col xs={12} md={11}>
                    <InputSearch
                        placeholder={'Operatori, Data(dd/mm/yyyy)'}
                        initialValues={searchInitialValues}
                        onSearch={onSearch}
                        translate={translate}
                        loading={loading}
                    />
                </Col>
                <Col xs={12} md={3}>
                    <div className={"text-right"}>
                        <PermissionButton
                            permissions={[Permission.SaveNewsAudit]}
                            userPermissions={userLoginInfo.user.permissions}
                            icon="far fa-plus"
                            type="primary"
                            onClick={() => {
                                history.push(
                                    `${history.location.pathname}/${MANAGE_NEWS_AUDIT}`
                                );
                            }}>
                            {translate("dashboard.add")}
                        </PermissionButton>
                    </div>
                </Col>
            </Row>
        }>
            <Row>
                <Col span={24}>
                    <NewsAuditsTable
                        onDelete={deleteNewsAudit}
                        updateTable={updateTableDate}
                        user={userLoginInfo.user}
                        setSearchFilter={setSearchFilter}
                        filter={searchFilter}
                        item={items}
                        history={history}
                        translate={translate}
                        loading={loading}
                    />
                </Col>
            </Row>

        </Card>
    )
}

export default NewsAudits;