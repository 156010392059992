import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import {Formik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";

import PasswordInput from "../../common/components/dataEntry/formik/FormikPasswordField";
import {generalPasswordRegx} from "../../common/components/utils/Regx";
import FormTitle from "../../common/components/forms/FormTitle";
import Card from "../../common/components/dataDisplay/Card";
import notification from "../../common/components/feedback/Notification";
import {ADMIN_DASHBOARD_PATH} from "../../dashboard/Routes";
import {commandRequest} from "../../common/RequestUtils";

import savePassword, {
    ChangePasswordData
} from "core/application/account/profile/changePassword";

import "./ChangePassword.less";
import FormSaveCancelButtons from "../../common/components/forms/SaveCancelFormButtons";

export const CHANGE_PASSWORD_PATH = "change-password";

const formValidator = (translate: any) =>
    Yup.object({
        currentPassword: Yup.string().required(translate("general.required")),
        newPassword: Yup.string()
            .required(translate("general.required"))
            .min(8, translate("change_password.conditions_not_met"))
            .matches(
                generalPasswordRegx,
                translate("change_password.password_does_not_match")
            ),
        confirmPassword: Yup.string()
            .oneOf(
                [Yup.ref("newPassword")],
                translate("change_password.confirm_password")
            )
            .required(translate("general.required"))
    });

interface ChangePasswordForm {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const defaultValues: ChangePasswordForm = {
    confirmPassword: "",
    currentPassword: "",
    newPassword: ""
};

const ChangePassword: React.FC = () => {
    const {t: translate} = useTranslation();
    let history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = async (values: ChangePasswordForm) => {
        setLoading(true);
        let dataForSubmit: ChangePasswordData = {
            currentPassword: values.currentPassword,
            newPassword: values.confirmPassword
        };

        let result = await commandRequest(() => savePassword(dataForSubmit));

        if (!result.errors) {
            notification.open({
                message: translate("general.saved").replace(
                    "[]",
                    translate("change_password.the_new_password")
                ),
                type: "success"
            });
            history.push(ADMIN_DASHBOARD_PATH);
        } else {
            notification.open({
                message: result.errors[0],
                type: "error"
            });
        }
        setLoading(false);
    };

    return (
        <div className="form_center">
            <Formik
                enableReinitialize={true}
                validationSchema={formValidator(translate)}
                initialValues={defaultValues}
                onSubmit={onSubmit}>
                {formik => (
                    <>
                        <Card bordered={true} title={translate("change_password.title")}>
                            <form onSubmit={() => {
                            }}>
                                <Row className="m-auto">
                                    <Col xs={24} md={12} offset={6}>
                                        <Row>
                                            <Col span={24}>
                                                <PasswordInput
                                                    label={translate("change_password.current_password")}
                                                    name="currentPassword"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={24}>
                                                <PasswordInput
                                                    label={translate("change_password.new_password")}
                                                    name="newPassword"
                                                    popover={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="pb-8">
                                            <Col xs={24}>
                                                <PasswordInput
                                                    label={translate("change_password.confirm_new_password")}
                                                    name="confirmPassword"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </form>
                        </Card>
                        <div className={"text-center"}>
                            <FormSaveCancelButtons
                                disabledSave={!formValidator(translate).isValidSync(formik.values)}
                                onButtonClick={() => onSubmit(formik.values)}
                                loading={loading}/>
                        </div>
                    </>

                )}
            </Formik>
        </div>
    );
};

export default ChangePassword;
