import {
  UpdateLoginUserAction,
  UPDATE_LOGIN_USER,
  UserAction,
  SET_FETCHING,
  SET_USER,
  SET_ERROR
} from "./userAction";
import { User } from "../loginUser";

export interface UserState extends User {
  user: User | null;
  hasError: boolean;
  error: string | null;
  fetching: boolean;
}

const initialState: UserState = {
  user: null,
  hasError: false,
  error: null,
  fetching: false
};

export function userReducer(
  state = initialState,
  action: UserAction
): UserState {
  switch (action.type) {
    case SET_FETCHING:
      return {
        ...state,
        fetching: action.isFetching
      };
    case SET_USER:
      return {
        ...state,
        user: action.response
      };

    case SET_ERROR:
      return {
        ...state,
        hasError: action.hasError,
        error: action.error
      };
    default:
      return state;
  }
}
