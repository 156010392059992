export enum Permission {
    All = 0,

    //Audits
    SaveAdvertisementAudit = 1,
    DeleteAdvertisementAudit = 2,
    ViewAllAdvertisementAudits = 3,
    ViewAdvertisementAudit = 4,

    SaveLiveBroadcastAudit = 5,
    DeleteLiveBroadcastAudit = 6,
    ViewAllLiveBroadcastAudits = 7,
    ViewLiveBroadcastAudit = 8,

    SaveNewsAudit = 9,
    DeleteNewsAudit = 10,
    ViewAllNewsAudits = 11,
    ViewNewsAudit = 12,

    SaveNewsTickerAudit = 13,
    DeleteNewsTickerAudit = 14,
    ViewAllNewsTickerAudits = 15,
    ViewNewsTickerAudit = 16,

    SaveProgramAudit = 17,
    DeleteProgramAudit = 18,
    ViewAllProgramAudits = 19,
    ViewProgramAudit = 20,

    //Configurations
    Advertisements = 21,
    AdvertisementTypes = 22,
    AuditableSubjects = 23,
    Auditees = 24,
    Industries = 25,
    NewsEditions = 26,
    NewsTopics = 27,
    Notes = 28,
    Operators = 29,
    Programs = 30,

    //Users
    Users = 31,

    //Reports
    Reports = 32,

    //Special audits
    Polls = 33,
    BannedActivities = 34,

    //Editing
    EditAfterPublished = 35,
}
