import {adminHttpClient}  from "../commons/adminHttpClient";

export interface AdvertisementTypeForSave {
    id : string | null;
    name: string;
}

export const getAdvertisementTypeForSave = (id : string | undefined) : Promise<any> => {
    if (id) {
        return adminHttpClient.get(`/AdvertisementTypes/GetForSave/${id}`);
    }
    return adminHttpClient.get(`/AdvertisementTypes/GetForSave`);
}

const saveAdvertisementType = (data : any) :Promise<any> => {
    return adminHttpClient.post(`/AdvertisementTypes`, data);
}
export default saveAdvertisementType;