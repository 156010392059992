import {adminHttpClient} from "../commons/adminHttpClient";
import PagedFilter from "../commons/pagedFilter";
import PagedList from "../commons/pagedList";

export interface LiveBroadcastAuditDto {
    id : string;
    date : Date;
    operatorName : string;
    createdBy : string;
    createdAt : string;
}

const getLiveBroadcast = (filter : PagedFilter) : Promise<PagedList<LiveBroadcastAuditDto>> => {
    return adminHttpClient.get(`/LiveBroadcastAudits/Get`, filter);
}

export default getLiveBroadcast;