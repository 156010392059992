import {formatDataAsDashed} from "../../../common/dateFormatters";

export const getParentColumns = () => {
    return [
        { title: 'Operatori', dataIndex: 'operatorName', key: 'operatorName' },
        { title: 'Data', dataIndex: 'date', key: 'date', render:(text: string, record : any) => (record !== undefined && formatDataAsDashed(record.date))},
        { title: 'Ora e fillimit', dataIndex: 'startTime', key: 'startTime' },
        { title: 'Ora e mbarimit', dataIndex: 'endTime', key: 'endTime' },
        { title: 'Shënime', dataIndex: 'notes', key: 'notes' },
    ];
}

export const getChildrenColumns = () => {
    return [
        { title: 'Nr.', dataIndex: 'index', key: 'index', render:(text: string, record : any) => record.index  },
        { title: 'Subjekti', dataIndex: 'auditableSubjectName', key: 'auditableSubjectName' },
        { title: 'Kohëzgjatja', dataIndex: 'duration', key: 'duration', render:(text: string, record : any) => record.duration.toFixed(2) },
        { title: 'Shënime', dataIndex: 'notes', key: 'notes' },
    ];
}

export const getNephewColumns = () => {
    return [

    ];
}