import React from "react";
import { useField, useFormikContext } from "formik";
import classnames from "classnames";

import Error from "./FormItemError";
import InputNumber, { InputNumberProps } from "../components/InputNumber";
import Label from "../components/Label";
import FieldProps from "./FieldProps";

import "./FormikStyles.less";

type Props = InputNumberProps &
  FieldProps & {
    hint?: string;
    formatter?: (value: number | string | undefined) => string;
    parser?: (displayValue: string | undefined) => number | string;
  };

const FormikInputNumberField: React.FC<Props> = props => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const showError = meta.touched && meta.error;
  const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;
  const id = props.id || props.name;

  const onChange = (value: string | number | undefined) => {
    setFieldValue(props.name, value);
  };

  const hint = props.hint ? (
    <span className="formik-field__span"> {props.hint}</span>
  ) : null;

  const inputNumberErrorClassname = classnames({ border_error: showError });

  return (
   <div className={"form-group"}>
      <Label htmlFor={id} className="formik-field__input-label no-select hand-on-hover">
        {props.label}
        {hint}
      </Label>
      <InputNumber{...field} {...props} id={id} onChange={onChange} className={inputNumberErrorClassname} />
      {showError && (<div className="mt-4">{error}&nbsp;</div>)}
    </div>
  );
};

export default FormikInputNumberField;
