import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import NotFound from "../../errorPages/NotFound";
import Notes, { NOTES_PATH } from "./notes/Notes";
import ManageNotes, { MANAGE_NOTES } from "./notes/ManageNotes";
import Polls, { POLLS_PATH } from "./polls/Polls";
import ManagePoll, { MANAGE_POLL_PATH } from "./polls/ManagePoll";
import BannedActivities, {
  BANNED_ACTIVITIES_PATH
} from "./bannedActivities/BannedActivities";
import ManageBannedActivity, {
  MANAGE_BANNED_ACTIVITY
} from "./bannedActivities/ManageBannedActivity";

const Routes: React.FC = () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}/${NOTES_PATH}`} component={Notes} />
      <Route
        path={`${match.path}/${NOTES_PATH}/${MANAGE_NOTES}/:id?`}
        component={ManageNotes}
      />

      <Route exact path={`${match.path}/${POLLS_PATH}`} component={Polls} />
      <Route
        exact
        path={`${match.path}/${POLLS_PATH}/${MANAGE_POLL_PATH}/:id?`}
        component={ManagePoll}
      />

      <Route
        exact
        path={`${match.path}/${BANNED_ACTIVITIES_PATH}`}
        component={BannedActivities}
      />
      <Route
        path={`${match.path}/${BANNED_ACTIVITIES_PATH}/${MANAGE_BANNED_ACTIVITY}/:id?`}
        component={ManageBannedActivity}
      />

      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
