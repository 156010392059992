import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router";
import notification from "common/components/feedback/Notification";
import { queryRequest, commandRequest } from "common/RequestUtils";
import { Col, Row, Skeleton } from "antd";
import { Form as FormikForm, Formik } from "formik";
import Card from "common/components/dataDisplay/Card";
import Input from "common/components/dataEntry/formik/FormikInputField";
import InputNumber from "common/components/dataEntry/formik/FormikInputNumberField";
import FormSaveCancelButtons from "common/components/forms/SaveCancelFormButtons";
import Select from "common/components/dataEntry/formik/FormikSelectField";
import { NamedItemInt } from "core/application/commons/namedItem";
import {
  getPollForSave,
  savePoll,
  PollForSaveDto
} from "core/application/polls/savePoll";
import DatePicker from "common/components/dataEntry/formik/FormikDatePickerField";
import moment from "moment";
import TextArea from "common/components/dataEntry/formik/FormikTextAreaField";
import {AuditStatus} from "core/domain/common/auditStatus";
import savePrograms from "core/application/programAudits/saveProgramAudit";
import SaveAuditFormButtons from "../../../common/components/forms/SaveAuditFormButtons";

export const MANAGE_POLL_PATH = "manage-poll";

const formValidator = (translate: any) =>
  Yup.object({
    name: Yup.string()
      .required(translate("general.required"))
      .nullable()
      .typeError(translate("general.required")),
    startDate: Yup.date()
      .required(translate("general.required"))
      .nullable()
      .typeError(translate("general.required")),
    endDate: Yup.date()
      .required(translate("general.required"))
      .nullable()
      .typeError(translate("general.required")),
    totalParticipants: Yup.number()
      .required(translate("general.required"))
      .typeError(translate("general.required")),
    marginError: Yup.number()
      .required(translate("general.required"))
      .typeError(translate("general.required")),
    operatorId: Yup.string()
      .required(translate("general.required"))
      .nullable()
      .typeError(translate("general.required"))
  });
const ManagePoll: React.FC = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [item, setItem] = useState<PollForSaveDto | null>(null);
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    (async () => {
      await updateForm();
    })();
  }, []);

  const updateForm = async () => {
    setLoading(true);
    setItem(await queryRequest(() => getPollForSave(id)));
    setLoading(false);
  };

  const onSubmit = async (values: any, status : any) => {
    const result = await commandRequest(() => savePoll({...values, status : status}));
    if (!result.errors) {
      notification.open({
        message: translate("general.saved").replace(
          "[]",
          translate("dashboard.polls.the_poll")
        ),
        type: "success"
      });
      history.goBack();
    } else {
      notification.open({ message: result.errors[0], type: "error" });
    }
  };

  const lockAudit = async (values: any) => {
    const shouldLock = !values.id && values.name && values.operatorId && values.subject
        && values.startDate && values.endDate;
    if (!shouldLock) return;

    const request = {
      name: values.name,
      operatorId: values.operatorId,
      subject: values.subject,
      marginError: values.marginError,
      totalParticipants: values.totalParticipants,
      startDate: values.startDate,
      endDate: values.endDate,
      availableOperators: values.availableOperators,
    }

    setIsFormSubmitted(true)
    const result = await commandRequest(() => savePoll(request))
    setIsFormSubmitted(false)

    if (result.errors) {
      notification.open({message: result.errors[0], type: "error"});
      return;
    }

    if (!item) return;

    setItem({
      ...item,
      id: result,
      subject : values.subject,
      startDate : values.startDate,
      endDate : values.endDate,
      name : values.name,
      availableOperators : values.availableOperators,
      marginError : values.marginError,
      operatorId : values.operatorId,
      totalParticipants : values.totalParticipants
    })
  }

  return (
    <Skeleton
      active
      loading={loading}
      paragraph={{ rows: 6, className: "p-64 color-gray-5" }}
      title={false}
    >
      {" "}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {formik => (
            <div>
              <Card
                bordered={true}
                title={
                  id
                    ? translate("dashboard.polls.edit")
                    : translate("dashboard.polls.add")
                }
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        name="name"
                        label={`${translate("general.name")}`}
                        placeholder={translate("general.name")}
                        onBlur={async () => {
                          await lockAudit(formik.values);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col span={12}>
                      <DatePicker
                        label={translate("dashboard.polls.start_date")}
                        name="startDate"
                        allowClear={false}
                        placeholder={translate("dashboard.polls.start_date")}
                        defaultValue={
                          item.startDate ? moment(item.startDate) : undefined
                        }
                        disabledDate={d =>
                          !d || d.isAfter(moment().subtract(0, "day"))
                        }
                        className="full_width"
                        onBlur={async () => {
                          await lockAudit(formik.values);
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <DatePicker
                        label={translate("dashboard.polls.end_date")}
                        name="endDate"
                        allowClear={false}
                        placeholder={translate("dashboard.polls.end_date")}
                        defaultValue={
                          item.endDate ? moment(item.endDate) : undefined
                        }
                        disabledDate={d =>
                          !d || d.isAfter(moment().subtract(0, "day"))
                        }
                        className="full_width"
                        onBlur={async () => {
                          await lockAudit(formik.values);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Select
                        name="operatorId"
                        showSearch={true}
                        label={`${translate("dashboard.polls.operator")}`}
                        placeholder={translate("dashboard.polls.operator")}
                        defaultValue={id ? [item.operatorId] : []}
                        onBlur={async () => {
                          await lockAudit(formik.values);
                        }}
                      >
                        {item.availableOperators.map((index: NamedItemInt) => (
                          <option key={index.id} value={index.id}>
                            {index.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <TextArea
                        name="subject"
                        label={`${translate("dashboard.polls.subject")}`}
                        placeholder={translate("dashboard.polls.subject")}
                        onBlur={async () => {
                          await lockAudit(formik.values);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col span={12}>
                      <InputNumber
                        name="totalParticipants"
                        placeholder={translate(
                          "dashboard.polls.total_participants"
                        )}
                        label={translate("dashboard.polls.total_participants")}
                        onBlur={async () => {
                          await lockAudit(formik.values);
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <InputNumber
                        name="marginError"
                        placeholder={translate("dashboard.polls.margin_error")}
                        label={translate("dashboard.polls.margin_error")}
                        onBlur={async () => {
                          await lockAudit(formik.values);
                        }}
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>
              <div className={"text-center mb-16"}>
                <SaveAuditFormButtons
                    onSaveAsDraftClick={async () => {
                      await formik.submitForm();
                      await onSubmit(formik.values, AuditStatus.Draft)
                    }}
                    onPublishClick={async () => {
                      await formik.submitForm();
                      await onSubmit(formik.values, AuditStatus.Published)
                    }}
                    loading={isFormSubmitted}
                />
              </div>

            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  );
};

export default ManagePoll;
