import React, {useEffect} from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import NotFound from "../../errorPages/NotFound";
import {OPERATORS_PATH} from "../operators/Operators";
import Operators from "../operators";
import ManageOperator, {MANAGE_OPERATOR_PATH} from "../operators/ManageOperator";

import {ADVERTISEMENT_PATH} from "./advertisements/Advertisement";
import Advertisement from "./advertisements/Advertisement";

import {INDUSTRY_PATH} from "./industries/Industry";
import {MANAGE_INDUSTRY} from "./industries/ManageIndustry";
import Industry from "./industries/Industry";
import ManageIndustry from "./industries/ManageIndustry";

import {NEWS_EDITION_PATH} from "./newsEditions/NewsEdition";
import {MANAGE_NEWS_EDITION} from "./newsEditions/ManageNewsEdition";
import ManageNewsEdition from "./newsEditions/ManageNewsEdition";
import NewsEdition from "./newsEditions/NewsEdition";
import {MANAGE_ADVERTISEMENT_PATH} from "./advertisements/ManageAdvertisement";
import ManageAdvertisement from "./advertisements/ManageAdvertisement";
import {ADVERTISEMENT_TYPE_PATH} from "./advertisementTypes/AdvertisementType";
import AdvertisementType from "./advertisementTypes/AdvertisementType";
import {MANAGE_ADVERTISEMENT_TYPE} from "./advertisementTypes/ManageAdvertisementType";
import ManageAdvertisementType from "./advertisementTypes/ManageAdvertisementType";
import {NEWS_TOPICS_PATH} from "./newsTopics/NewsTopics";
import {MANAGE_NEWS_TOPICS} from "./newsTopics/ManageNewsTopics";
import NewsTopics from "./newsTopics/NewsTopics";
import ManageNewsTopics from "./newsTopics/ManageNewsTopics";
import {AVAILABLE_SUBJECT_PATH} from "./subjects/AvailableSubject";
import {MANAGE_AVAILABLE_SUBJECT} from "./subjects/ManageAvailableSubject";
import AvailableSubject from "./subjects/AvailableSubject";
import ManageAvailableSubject from "./subjects/ManageAvailableSubject";
import {AUDITEE_PATH} from "./auditees/Auditee";
import {MANAGE_AUDITEE_PATH} from "./auditees/ManageAuditee";
import Auditee from "./auditees/Auditee";
import ManageAuditee from "./auditees/ManageAuditee";
import Program, {PROGRAM_PATH} from "./programs/Program";
import ManageProgram, {MANAGE_PROGRAM_PATH} from "./programs/ManageProgram";
const Routes: React.FC = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}/${OPERATORS_PATH}`} component={Operators}/>
            <Route path={`${match.path}/${OPERATORS_PATH}/${MANAGE_OPERATOR_PATH}/:id?`} component={ManageOperator}/>

            <Route exact path={`${match.path}/${INDUSTRY_PATH}`} component={Industry}/>
            <Route path={`${match.path}/${INDUSTRY_PATH}/${MANAGE_INDUSTRY}/:id?`} component={ManageIndustry}/>

            <Route exact path={`${match.path}/${NEWS_EDITION_PATH}`} component={NewsEdition}/>
            <Route path={`${match.path}/${NEWS_EDITION_PATH}/${MANAGE_NEWS_EDITION}/:id?`} component={ManageNewsEdition}/>

            <Route exact path={`${match.path}/${ADVERTISEMENT_TYPE_PATH}`} component={AdvertisementType}/>
            <Route path={`${match.path}/${ADVERTISEMENT_TYPE_PATH}/${MANAGE_ADVERTISEMENT_TYPE}/:id?`} component={ManageAdvertisementType}/>

            <Route exact path={`${match.path}/${NEWS_TOPICS_PATH}`} component={NewsTopics}/>
            <Route path={`${match.path}/${NEWS_TOPICS_PATH}/${MANAGE_NEWS_TOPICS}/:id?`} component={ManageNewsTopics}/>

            <Route exact path={`${match.path}/${AVAILABLE_SUBJECT_PATH}`} component={AvailableSubject}/>
            <Route path={`${match.path}/${AVAILABLE_SUBJECT_PATH}/${MANAGE_AVAILABLE_SUBJECT}/:id?`} component={ManageAvailableSubject}/>

            <Route exact path={`${match.path}/${AUDITEE_PATH}`} component={Auditee}/>
            <Route path={`${match.path}/${AUDITEE_PATH}/${MANAGE_AUDITEE_PATH}/:id?`} component={ManageAuditee}/>

            <Route component={NotFound} />
        </Switch>
    );
};

export default Routes;
