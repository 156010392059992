import React, { useState } from "react";
import { Col, Row } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";

import Layout from "../common/components/layout/Layout";

import Button from "../common/components/general/Button";
import Input from "../common/components/dataEntry/formik/FormikInputField";
import PasswordInput from "../common/components/dataEntry/formik/FormikPasswordField";
import Alert from "../common/components/feedback/Alert";
import { commandRequest } from "../common/RequestUtils";

import auth from "core/application/auth/auth";
import store from "core/application/commons/localStorage";

import {
  fetchUser,
  UserAction
} from "core/application/account/updateUserInStore/userAction";

import "./AccountStyles.less";

export const LOGIN_PATH = "login";

const formValidator = (translate: any) =>
  Yup.object({
    email: Yup.string()
      .email("Emaili nuk është i saktë")
      .required("Fushë e detyrueshme"),
    password: Yup.string().required("Fushë e detyrueshme")
  });

interface FormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

const initialValues: FormValues = {
  email: "",
  password: "",
  rememberMe: false
};

interface LoginProps {
  onFetchUser: () => void;
}

const Login: React.FC<LoginProps> = ({ onFetchUser }) => {
  const [submiting, setSubmiting] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const { t: translate } = useTranslation();

  const onSubmit = async (values: FormValues) => {
    setSubmiting(true);
    const res = await commandRequest(() => auth(values.email, values.password));
    if (!res.errors) {
      store.setToken(res.token);
      setSubmiting(false);
      onFetchUser();
      window.location.href = "/";
    } else {
      setSubmiting(false);
      setErrors(res.errors);
    }
  };

  const errorDescription = (
    <>
      <span className="block">
        {translate("account.login.forgot_password")}
        <a href="#" className="info-primary-color pl-8">
          {translate("account.login.change_password")}
        </a>
      </span>
    </>
  );

  return (
    <Layout>
      <Row justify="center">
        <Col xs={24} md={24} lg={24}>
          <div className="login login_container__content_center">
            <Formik
              enableReinitialize={true}
              validationSchema={formValidator}
              initialValues={initialValues}
              onSubmit={onSubmit}>
              {formik => (
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <Row>
                        <Col>
                          <div className="p-16">
                            <img src="/assets/logos/logo.png" alt="AMA Logo" width={150}/>
                          </div>
                        </Col>
                      </Row>

                      <Row className="p-16">
                        <Col span={24}>
                          <Row className="form">
                            <Col span={24}>
                              <div>
                                <h3>{translate("account.login.login")}</h3>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={24}>
                              {errors.length > 0 && (
                                <Alert
                                  type="error"
                                  message={translate(
                                    "account.login.password_alert"
                                  )}
                                  description={errorDescription}
                                  showIcon
                                />
                              )}
                            </Col>
                          </Row>

                          <Row className="mt-8">
                            <Col xs={24} sm={24} md={24}>
                              <Input
                                label={translate("account.login.email")}
                                name="email"
                              ></Input>
                            </Col>
                          </Row>
                          <Row className="mt-8">
                            <Col xs={24} span={24}>
                              <PasswordInput
                                label={translate("account.login.password")}
                                name="password"
                                className="input-with-suffix__border-botton-only"
                              />
                            </Col>
                          </Row>

                          <Row className="mt-16">
                            <Col span={24}>
                              <Button
                                htmlType="submit"
                                type="primary"
                                className="button__large full_width"
                                loading={submiting}
                                disabled={
                                  !formValidator(translate).isValidSync(
                                    formik.values
                                  )
                                }
                              >
                                {translate("account.login.login")}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, UserAction>) {
  return {
    onFetchUser: () => dispatch(fetchUser())
  };
}

export default connect(null, mapDispatchToProps)(Login);
