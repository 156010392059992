import React from "react";
import { useField } from "formik";
import classnames from "classnames";

import Error from "./FormItemError";
import TextArea from "../components/TextArea";
import FieldProps from "./FieldProps";
import Label from "../components/Label";

interface TextAreaProps extends FieldProps {
  cols?: number;
  rows?: number;
  wrap?: string;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  hint?: string;
}

const FormikTextAreaField: React.FC<TextAreaProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const showError = meta.touched && meta.error;
  const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;
  const id = props.id || props.name;

  const hint = props.hint ? (
    <span className="formik-field__span"> {props.hint}</span>
  ) : null;

  const inputClassname = classnames({ border_error: showError });

  return (
    <>
      <Label
        htmlFor={id}
        className="formik-field__input-label no-select hand-on-hover"
      >
        {label}
        {hint}
      </Label>
      <TextArea {...field} {...props} id={id} className={inputClassname} />
      {showError && (<div className="mb-16 mt-8">{error}&nbsp;</div>)}
    </>
  );
};

export default FormikTextAreaField;
