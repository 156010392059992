import React from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound: React.FC = () => {
  let history = useHistory();
  const { t: translate } = useTranslation();

  return (
    <Result
      status="404"
      title="404"
      subTitle={translate("general.not_found")}
      extra={
        <Button type="primary" onClick={() => history.push("/admindashboard")}>
          {translate("general.back_to_home")}
        </Button>
      }
    />
  );
};

export default NotFound;
