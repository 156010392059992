import React, { useState, useEffect } from "react";
import { Row, Col, Skeleton, Card } from "antd";
import StatisticCard from "./statistics/StatisticCard";
import NewsAuditReport from "./reports/news/NewsAuditReport";
import AdvertisementAuditReport from "./reports/advertisements/AdvertisementAuditReport";
import { useTranslation } from "react-i18next";
import Select from "common/components/dataEntry/components/Select";
import { useSelector } from "react-redux";
import { Permission } from "core/domain/users/permission";
import { useHistory } from "react-router";
import { ADMIN_DASHBOARD_PATH } from "dashboard/Routes";
import { CONFIGURATION_PATH } from "../configurations/ConfigurationWrapper";

import {
  MenuItem,
  navigationMenu,
  navigationPathMapping,
  NavigationMapping,
  getConfigurationPermission,
  configurationsPathMapping,
  otherAuditsPathMapping,
  getOtherAuditsPermissions
} from "dashboard/DashboardWrapper";
import AuditableSubjectsReport from "./reports/auditableSubjects/AuditableSubjectsReport";
import AuditableSubjectsByGenderReport from "./reports/auditableSubjects/AuditableSubjectsByGenderReport";
import LiveBroadcastAuditReport from "./reports/liveBroadcasts/LiveBroadcastAuditReport";
import ProgramAuditReport from "./reports/programs/ProgramAuditReport";
import NewsTickerAuditReport from "./reports/newsTickers/NewsTickerAuditReport";
import { OTHER_AUDITS_PATH } from "dashboard/otherAudits/OtherAuditsWrapper";
import OperatorAuditReport from "./reports/operators/OperatorAuditReport";

export const HOME_PATH = "/";

interface ReportSelectOption {
  name: string;
  key: number;
  component: React.ComponentType<any>;
}

enum ReportSelectOptionMapping {
  NewsAuditReport = 1,
  AdvertisementAuditReport = 2,
  AuditableSubjectsReport = 3,
  LiveBroadcastAuditReport = 4,
  ProgramAuditReport = 5,
  NewsTickerAuditReport = 6,
  AuditsGenderReport = 7,
  OperatorAuditsReport = 8
}

const reportSelectOptions: ReportSelectOption[] = [
  {
    name: "dashboard.reports.news_audit_report_title",
    key: ReportSelectOptionMapping.NewsAuditReport,
    component: NewsAuditReport
  },
  {
    name: "dashboard.reports.advertisement_audit_report_title",
    key: ReportSelectOptionMapping.AdvertisementAuditReport,
    component: AdvertisementAuditReport
  },
  {
    name: "dashboard.reports.auditable_subjects_report",
    key: ReportSelectOptionMapping.AuditableSubjectsReport,
    component: AuditableSubjectsReport
  },
  {
    name: "dashboard.reports.live_broadcast_audit_report",
    key: ReportSelectOptionMapping.LiveBroadcastAuditReport,
    component: LiveBroadcastAuditReport
  },
  {
    name: "dashboard.reports.program_audit_report",
    key: ReportSelectOptionMapping.ProgramAuditReport,
    component: LiveBroadcastAuditReport
  },
  {
    name: "dashboard.reports.news_ticker_audit_report",
    key: ReportSelectOptionMapping.NewsTickerAuditReport,
    component: LiveBroadcastAuditReport
  },
  {
    name: "dashboard.reports.audits_gender_report",
    key: ReportSelectOptionMapping.AuditsGenderReport,
    component: AuditableSubjectsByGenderReport
  },
  {
    name: "dashboard.reports.operator_audits_report",
    key: ReportSelectOptionMapping.OperatorAuditsReport,
    component: OperatorAuditReport
  }
];

const Home: React.FC = () => {
  const { t: translate } = useTranslation();
  const [reportComponent, setReportComponent] = useState<number>();
  const userLoginInfo = useSelector((s: any) => s.userReducer);
  const history = useHistory();

  const buildReportSelectOptions = reportSelectOptions
    .sort((first, second) => first.key - second.key)
    .map((item: ReportSelectOption) => (
      <option key={item.key} value={item.key}>
        {translate(item.name)}
      </option>
    ));

  const onChangeHandler = (value: any, key: any) => {
    setReportComponent(value);
  };

  const isVisibleBasedOnPermissions = (userPermissions: Permission[]) => {
    return (
      userPermissions.includes(Permission.All) ||
      userPermissions.includes(Permission.Reports)
    );
  };

  const getFirstPermission = (
    navigationMenu: MenuItem[],
    userPermission: Permission
  ) => {
    var permissions = navigationMenu.filter((i: MenuItem) =>
      i.permissions.includes(userPermission)
    );
    if (permissions && permissions.length > 0) {
      return permissions.sort((a, b) => a.key - b.key)[0];
    }
    return null;
  };

  useEffect(() => {
    var permissions = userLoginInfo.user.permissions;
    if (!isVisibleBasedOnPermissions(permissions)) {
      if (permissions && permissions.length > 0) {
        var path = getFirstPermission(navigationMenu, permissions[0]);
        if (path) {
          if (path.key === NavigationMapping.Configuration) {
            var configurationPath = configurationsPathMapping.get(
              getConfigurationPermission(userLoginInfo.user)
            );
            history.replace(
              configurationPath
                ? `${ADMIN_DASHBOARD_PATH}${CONFIGURATION_PATH}/${configurationsPathMapping.get(
                    getConfigurationPermission(userLoginInfo.user)
                  )}`
                : "not-found"
            );
          }
          if (path.key === NavigationMapping.OtherAudits) {
            var otherAuditsPath = otherAuditsPathMapping.get(
              getOtherAuditsPermissions(userLoginInfo.user)
            );
            history.replace(
              otherAuditsPath
                ? `${ADMIN_DASHBOARD_PATH}${OTHER_AUDITS_PATH}/${otherAuditsPathMapping.get(
                    getOtherAuditsPermissions(userLoginInfo.user)
                  )}`
                : "not-found"
            );
          } else {
            history.replace(path.path);
          }
        } else {
          history.replace("not-found");
        }
      } else {
        history.replace("not-found");
      }
    }
  }, []);

  return (
    <div>
      <Card>
        <Select
          className="width_30"
          showSearch={true}
          placeholder={translate("dashboard.reports.choose_report")}
          onChange={onChangeHandler}
        >
          {buildReportSelectOptions}
        </Select>
        <div className="mt-8">
          {reportComponent === ReportSelectOptionMapping.NewsAuditReport && (
            <NewsAuditReport />
          )}
          {reportComponent ===
            ReportSelectOptionMapping.AdvertisementAuditReport && (
            <AdvertisementAuditReport />
          )}
          {reportComponent ===
            ReportSelectOptionMapping.AuditableSubjectsReport && (
            <AuditableSubjectsReport />
          )}
          {reportComponent ===
            ReportSelectOptionMapping.LiveBroadcastAuditReport && (
            <LiveBroadcastAuditReport />
          )}
          {reportComponent === ReportSelectOptionMapping.ProgramAuditReport && (
            <ProgramAuditReport />
          )}
          {reportComponent ===
            ReportSelectOptionMapping.NewsTickerAuditReport && (
            <NewsTickerAuditReport />
          )}
          {reportComponent === ReportSelectOptionMapping.AuditsGenderReport && (
            <AuditableSubjectsByGenderReport />
          )}
          {reportComponent === ReportSelectOptionMapping.OperatorAuditsReport && (
            <OperatorAuditReport />
          )}
        </div>
      </Card>
    </div>
  );
};

export default Home;
