import {adminHttpClient} from "../commons/adminHttpClient";
import {NamedItem} from "../commons/namedItem";
import {AuditStatus} from "../../domain/common/auditStatus";

export interface AdvertisementAudit {
    id: string;
    date: Date;
    notes: string;
    operatorId: string;
    operatorName: string;
    status: AuditStatus,
    auditItems: AuditItem[];
    availableOperators: any[];
    availableSubjects: any[];
    availableAdvertisementTypes: NamedItem[];
    sections: Sections[];
}
export interface SaveAdvertisementAudit {
    id: string;
    date: Date;
    operatorId : string,
    notes: string
    status: number,
    sections: any[];
}
export interface Sections {
    id: string | null;
    time : any | null;
    items : AuditItem[];
    index? : string | number;

}
export interface AuditItem{
    id : any;
    auditableSubjectId : string | null;
    advertisementTypeId : string | null;
    index? : string | number;
    duration : number | null;
    notes : string;
}
const manageAdvertisementAudit = (id: string | undefined): Promise<AdvertisementAudit> => {
    if (id) {
        return adminHttpClient.get(`/advertisementAudits/getForSave/${id}`);
    }
    return adminHttpClient.get(`/advertisementAudits/getForSave`);
};

export const saveAdvertisementAudit = (data : SaveAdvertisementAudit) : Promise<any> => {
    return adminHttpClient.post("/advertisementAudits", data);
}

export default  manageAdvertisementAudit
