import React from "react";

import { Route, Switch, useRouteMatch } from "react-router-dom";

import LiveBroadcastAudit from "./LiveBroadcastAudit";
import NotFound from "../../errorPages/NotFound";
import ManageLiveBroadcast, {MANAGE_LIVE_BROADCAST} from "./ManageLiveBroadcast";

const Routes: React.FC = () => {
    let match = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${match.path}/`} component={LiveBroadcastAudit} />
            <Route
                path={`${match.path}/${MANAGE_LIVE_BROADCAST}/:id?`}
                component={ManageLiveBroadcast}
            />
            <Route component={NotFound} />
        </Switch>
    );
};

export default Routes;