import { adminHttpClient } from "../commons/adminHttpClient";
import OrderedPageFilter from "../commons/orderedPageFilter";
import PagedList from "../commons/pagedList";

export interface PollDto {
  id: string | null;
  name: string;
  operatorName: string;
  subject: string;
  marginError: number;
  totalParticipants: number;
  startDate: string;
  endDate: string;
  createdBy: string;
  createdAt: string;
}

export const getPolls = (
  filter: OrderedPageFilter
): Promise<PagedList<PollDto>> => adminHttpClient.get(`/Polls/Get`, filter);
