import {adminHttpClient}  from "../commons/adminHttpClient";

export interface NewsTopicsForSave {
    id : string | null;
    name: string;
}

export const getNewsTopicsForSave = (id : string | undefined) : Promise<NewsTopicsForSave> => {
    if (id) {
        return adminHttpClient.get(`/NewsTopics/GetForSave/${id}`);
    }
    return adminHttpClient.get(`/NewsTopics/GetForSave`);
}

const saveNewsTopics = (data : any) :Promise<any> => {
    return adminHttpClient.post(`/NewsTopics`, data);
}
export default saveNewsTopics;