import React from "react";
import { Table as AntTable } from "antd";
import { TableProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";

const Table: React.FC<TableProps<any>> = props => {
  const { t: translate } = useTranslation();
  return (
    <AntTable {...props} locale={{ emptyText: translate("general.no_data") }} />
  );
};

export default Table;
