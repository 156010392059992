import {adminHttpClient} from "../commons/adminHttpClient";
import {NamedItem} from "../commons/namedItem";
import {AuditStatus} from "../../domain/common/auditStatus";

export interface LiveBroadcastForSave {
    id : string;
    date : Date;
    operatorId : string;
    operatorName : string;
    notes : string;
    status: AuditStatus,
    auditItems : any[];
    availableAuditItems : any[];
    availableOperators : any[];
    availableAuditableSubjects : any[];
    availableAuditees : any[];
}

export interface SaveLiveBroadcast {
    id : string;
    date: Date;
    operatorId : string;
    notes: string;
    auditItems : AuditItems[],
    status: number
}

export interface AuditItems {
    id : string | null;
    name: string ;
    index? : string | number;
    startTime : string | null;
    endTime : string | null;
    auditSubjects : AuditSubjects[];
}

export interface AuditSubjects {
    id : string | null;
    index? : string | number;
    auditableSubjectId : string | null;
    auditeeId : string | null;
    decimal? : number;
    notes : string
    duration: number
}

export const getLiveBroadcastForSave = (id : string | null) : Promise<any> => {
    if(id){
        return adminHttpClient.get(`/LiveBroadcastAudits/GetForSave/${id}`);
    }
    return adminHttpClient.get(`/LiveBroadcastAudits/GetForSave`);
}

const saveLiveBroadcast = ( data : SaveLiveBroadcast) : Promise<any> => {
    return adminHttpClient.post(`/LiveBroadcastAudits`, data);
}
export default saveLiveBroadcast;
