import React from "react";

import Button, { ButtonProps } from "./Button";
import "./ButtonStyles.less";
import { Permission } from "core/domain/users/permission";

export interface PermissionButtonProps extends ButtonProps {
  permissions: Permission[];
  userPermissions: Permission[];
}

const isVisibleBasedOnPermissions = (
  permissions: Permission[],
  userPermissions: Permission[]
) => {
  return (
    userPermissions.includes(Permission.All) ||
    userPermissions.some((p: Permission) => permissions.includes(p))
  );
};

const PermissionButton: React.FC<PermissionButtonProps> = ({
  permissions,
  userPermissions,
  ...props
}) => {
  return isVisibleBasedOnPermissions(permissions, userPermissions) ? (
    <Button {...props} />
  ) : (
    <></>
  );
};

export default PermissionButton;
