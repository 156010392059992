import React, { ReactNode } from "react";
import "./DataEntryStyles.less";
import { Input as AntInput } from "antd";
import { InputProps as AntInputProps } from "antd/lib/input";

import "./DataEntry.less";

export type InputProps = AntInputProps;

const Input: React.FC<InputProps> = props => {
  return (
    <AntInput
      {...props}
      className={`input_height round_border ${props.className}`}
    />
  );
};

export default Input;
