import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form as FormikForm, Formik, useFormik} from "formik";
import {useHistory, useParams} from "react-router";
import {commandRequest, queryRequest} from "../../common/RequestUtils";
import saveLiveBroadcast, {
    getLiveBroadcastForSave,
    LiveBroadcastForSave,
    AuditItems,
    AuditSubjects, SaveLiveBroadcast
} from "core/application/liveBroadcastAudit/saveLiveBroadcastAudit";
import notification from "../../common/components/feedback/Notification";
import {Col, Row, Skeleton} from "antd";
import Card from "../../common/components/dataDisplay/Card";
import Select from "../../common/components/dataEntry/formik/FormikSelectField";
import DatePicker from "../../common/components/dataEntry/formik/FormikDatePickerField";
import moment from "moment";
import TextArea from "../../common/components/dataEntry/formik/FormikTextAreaField";
import * as Yup from "yup";
import AuditItemsTableInput from "./tables/AuditItemsTableInput";
import _ from "lodash";
import Button from "../../common/components/general/Button";
import SaveAuditFormButtons from "../../common/components/forms/SaveAuditFormButtons";
import {AuditStatus} from "core/domain/common/auditStatus";
import store from "core/application/commons/localStorage";

export const MANAGE_LIVE_BROADCAST = "manage-live-broadcast"

const formValidator = (translate: any) =>
    Yup.object({
        operatorId: Yup.string()
            .required(translate("general.required"))
            .nullable()
            .typeError(translate("general.required")),
        date: Yup.date()
            .required(translate("general.required"))
            .nullable()
            .typeError(translate("general.required")),
        auditItems: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string()
                        .required(translate("general.required"))
                        .nullable(),
                    startTime: Yup.string()
                        .required(translate("general.required"))
                        .nullable(),
                    endTime: Yup.string()
                        .required(translate("general.required"))
                        .nullable(),
                    auditSubjects: Yup.array()
                        .of(
                            Yup.object().shape({
                                auditableSubjectId: Yup.string()
                                    .required(translate("general.required"))
                                    .nullable(),
                                auditeeId: Yup.string()
                                    .required(translate("general.required"))
                                    .nullable(),
                                duration: Yup.number()
                                    .nullable()
                                    .required(translate("general.required"))
                                    .moreThan(0, "Më e madhe se 0"),
                            })
                        )
                })
            )
    });

const getAutoSaveCacheKey = (id: any) => `live_broadcast_audit_${id}`;
const ManageLiveBroadcast = () => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const [item, setItem] = useState<LiveBroadcastForSave | null>(null)
    const [loading, setLoading] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    useEffect( () =>{
        (async () => {
            await updateForm();
        })()
    },[])

    const updateForm = async() =>{
        setLoading(true);
        const result = (await queryRequest(() => getLiveBroadcastForSave(id)));
        const cached = store.getObject(getAutoSaveCacheKey(result.id));
        if(cached && result.status === AuditStatus.Draft) {
            cached.availableAuditees = result.availableAuditees;
            cached.availableOperators = result.availableOperators;
            cached.availableAuditableSubjects = result.availableAuditableSubjects;
            setItem(cached);
        }
        else {
            setItem(result);
        }
        setLoading(false);
    }

    const onSubmit = async (values : any, status: number) => {
        if (!formValidator(translate).isValidSync({...values})) {
            return
        }

        let auditItems = values.auditItems.map((c: any, key : number) => {
            c.index = key + 1
            c.auditSubjects = c.auditSubjects.map((r: any, rkey : number) => {
                r.index = rkey + 1
                return r
            })
            return c
        })

        const inputDto : SaveLiveBroadcast = {
            auditItems : auditItems,
            date : values.date,
            id : values.id,
            notes : values.notes,
            operatorId : values.operatorId,
            status,
        }

        setIsFormSubmitted(true);
        const result = await commandRequest(() => saveLiveBroadcast(inputDto))
        setIsFormSubmitted(false);

        if (!result.errors) {
            notification.open({
                message: translate("general.saved_plural").replace(
                    "[]",
                    translate("dashboard.liveBroadcast.liveBroadcasts")
                ),
                type: "success"
            });

            store.remove(getAutoSaveCacheKey(values.id));

            history.goBack();
        } else {
            notification.open({message: result.errors[0], type: "error"});
        }
    }

    const lockAudit = async (values: any) => {
        const shouldLock = !values.id && values.operatorId && values.date;
        if (!shouldLock) return;

        const request : SaveLiveBroadcast = {
            auditItems : [],
            date : values.date,
            id : values.id,
            notes : values.notes,
            operatorId : values.operatorId,
            status: AuditStatus.Draft,
        }

        setIsFormSubmitted(true)
        const result = await commandRequest(() => saveLiveBroadcast(request))
        setIsFormSubmitted(false)

        if (result.errors) {
            notification.open({message: result.errors[0], type: "error"});
            return;
        }

        if (!item) return;

        setItem({
            ...item,
            operatorId: values.operatorId,
            date: values.date,
            notes: values.notes,
            auditItems: [
                newAuditItem()
            ],
            id: result,
        })
    }

    const newAuditItem = (): AuditItems => {
        return {
            id: null,
            auditSubjects: [{
                auditableSubjectId: null,
                auditeeId: null,
                id: null,
                notes:"",
                duration: 0
            }],
            endTime: "",
            startTime:"",
            name:"",
        }
    }

    const onAddAuditItem = (values : any) => {
        if (values) {
            setItem({...values, auditItems : [...values.auditItems, newAuditItem()]})
        }
    }

    const onAddAuditSubjectRow = (values : any, index : number) => {
        if (values) {
            const auditSubject : AuditSubjects = {
                auditableSubjectId: null,
                auditeeId: null,
                id: null,
                notes:"",
                duration: 0
            }
            const clone = _.cloneDeep(values);
            clone.auditItems[index].auditSubjects.push(auditSubject)
            setItem({...clone})
        }
    }

    const onRemoveAuditItemRow = (values: any, index : number) => {
        if (values) {
            values.auditItems.splice(index, 1);
            setItem({...values, auditItems : [...values.auditItems]})
        }
    };

    const onRemoveAuditSubjectRow = (auditItemIndex : number , values: any, index : number) => {
        if (values){
            values.auditItems[auditItemIndex].auditSubjects.splice(index, 1);
            const clone = _.cloneDeep(values);
            setItem(clone);
        }
    }

    const onChangeSubject = (record : any, index: number, values:any) => {
        let clone = _.cloneDeep(values);
        clone.auditItems[index].auditSubjects.map((c : any) => {
            if (c.auditableSubjectId === record.auditableSubjectId){
                c.auditeeId = null;
            }
            return c
        })
        setItem({...clone})
    }
    return (
        <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 6, className: "p-64 color-gray-5" }}
            title={false}
        >
            {" "}
            {item && (
                <Formik
                    innerRef={instance => {
                        if(instance && instance.values && item.id && item.status === AuditStatus.Draft) {
                            store.saveObject(getAutoSaveCacheKey(item.id), instance.values);
                        }
                    }}
                    enableReinitialize={true}
                    initialValues={item}
                    validationSchema={formValidator(translate)}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={() => {}}
                >
                    {formik => (
                        <div>
                            <Card
                                className={"container"}
                                bordered={true}
                                title={id ? translate("dashboard.liveBroadcast.edit"): translate("dashboard.liveBroadcast.add")}
                            >
                                <FormikForm>
                                    <Row gutter={10}>
                                        <Col xs={24} lg={12}>
                                            <Select
                                                name="operatorId"
                                                showSearch={true}
                                                label={`${translate("dashboard.ticker.operatorId")}`}
                                                placeholder={translate("dashboard.ticker.operatorId")}
                                                onBlur={async () => {
                                                    await lockAudit(formik.values);
                                                }}
                                            >
                                                {item.availableOperators.map(index => (
                                                    <option key={index.id} value={index.id}>
                                                        {index.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col xs={24} lg={12}>
                                            <DatePicker
                                                label={translate("general.date")}
                                                name="date"
                                                allowClear={false}
                                                placeholder={translate("general.date")}
                                                value={
                                                    formik.values.date
                                                        ? moment(formik.values.date)
                                                        : null
                                                }
                                                disabledDate={d =>
                                                    !d || d.isAfter(moment().subtract(0, "day"))
                                                }
                                                className="full_width"
                                                onBlur={async () => {
                                                    await lockAudit(formik.values);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <TextArea
                                                name="notes"
                                                label={`${translate("dashboard.ticker.notes")}`}
                                                placeholder={translate("dashboard.ticker.notes")}
                                            >
                                            </TextArea>
                                        </Col>
                                    </Row>
                                </FormikForm>
                            </Card>
                            {item.id && (
                                <Card>
                                    <AuditItemsTableInput
                                        auditItems={item?.auditItems}
                                        datasource={item}
                                        name={'auditItems'}
                                        translate={translate}
                                        onRemoveAuditItemRow={onRemoveAuditItemRow}
                                        onAddAuditSubjectRow={onAddAuditSubjectRow}
                                        onAddAuditItem={onAddAuditItem}
                                        onRemoveAuditSubjectRow={onRemoveAuditSubjectRow}
                                        onChangeSubject={onChangeSubject}
                                        formik={formik}
                                    />
                                    <Button className={"mt-8"} type="primary" onClick={() => onAddAuditItem(formik.values)}>
                                        {translate("general.add")}
                                    </Button>
                                </Card>
                            )}
                            <div className={"text-center mb-16"}>
                                <SaveAuditFormButtons
                                    onSaveAsDraftClick={async  () => {
                                        await formik.submitForm();
                                        await onSubmit(formik.values, AuditStatus.Draft)
                                    }}
                                    onPublishClick={async () => {
                                        await formik.submitForm();
                                        await onSubmit(formik.values, AuditStatus.Published)
                                    }}
                                    loading={isFormSubmitted}
                                />
                            </div>
                        </div>
                    )}
                </Formik>
            )}
        </Skeleton>
    )
}

export default ManageLiveBroadcast;
