import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import NewsAudits from "./NewsAudits";
import ManageNewsAudit , {MANAGE_NEWS_AUDIT} from "./ManageNewsAudit";
import NotFound from "../../errorPages/NotFound";

const Routes: React.FC = () => {
    let match = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${match.path}/`} component={NewsAudits} />
            <Route
                path={`${match.path}/${MANAGE_NEWS_AUDIT}/:id?`}
                component={ManageNewsAudit}
            />
            <Route component={NotFound} />
        </Switch>
    );
};

export default Routes;
