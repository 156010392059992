import { combineReducers, applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { userReducer } from "core/application/account/updateUserInStore/userReducer";
import { UserAction } from "core/application/account/updateUserInStore/userAction";

export const rootReducer = combineReducers({
  userReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore<AppState, UserAction, any, any>(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
