import React, {useEffect, useState} from "react";
import {Col, Row, Skeleton} from "antd";
import {Form as FormikForm, Formik} from "formik";
import Card from "../../../common/components/dataDisplay/Card";
import Input from "../../../common/components/dataEntry/formik/FormikInputField";
import FormSaveCancelButtons from "../../../common/components/forms/SaveCancelFormButtons";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import saveNewsEdition, {getNewsEditionForSave, NewsEditionForSave} from "core/application/newsEditions/saveNewsEdition";
import {commandRequest, queryRequest} from "../../../common/RequestUtils";
import notification from "../../../common/components/feedback/Notification";
import Select from "../../../common/components/dataEntry/formik/FormikSelectField";
import TimePicker from "common/components/dataEntry/formik/FormikTimePickerField"
import moment from "moment";
import InputMask from "common/components/dataEntry/formik/FormikInputMaskField";

export const MANAGE_NEWS_EDITION = "manage-news-edition"
const formValidator = (translate: any) =>
    Yup.object({
        operatorId: Yup.string()
            .required(translate("general.required"))
            .nullable()
            .typeError(translate("general.required")),
        time: Yup.string()
            .required(translate("general.required"))
            .nullable()
            .typeError(translate("general.required")),
    });
const ManageNewsEdition : React.FC = () => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const [item, setItem] = useState<NewsEditionForSave | null>(null)
    const [loading, setLoading] = useState(false);

    useEffect( () =>{
        (async () => {
            await updateForm();
        })()
    },[])


    const updateForm = async() =>{
        setLoading(true);
        setItem(await queryRequest(() => getNewsEditionForSave(id)));
        setLoading(false);
    }

    const onSubmit =async (values : any) => {
        values.time = moment.parseZone(values.time, "HH:mm").format("HH:mm")
        const result = await commandRequest(() => saveNewsEdition(values))
        if (!result.errors) {
            notification.open({
                message: translate("general.saved").replace(
                    "[]",
                    translate("dashboard.newsEdition.the_edition")
                ),
                type: "success"
            });
            history.goBack();
        } else {
            notification.open({message: result.errors[0], type: "error"});
        }
    }

    return (
        <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 6, className: "p-64 color-gray-5" }}
            title={false}
        >
            {" "}
            {item && (
                <Formik
                    enableReinitialize={true}
                    initialValues={item}
                    validationSchema={formValidator(translate)}
                    onSubmit={() => {}}
                >
                    {formik => (
                        <div>
                            <Card
                                bordered={true}
                                title={id ? translate("dashboard.newsEdition.edit"): translate("dashboard.newsEdition.add")}
                            >
                                <FormikForm>
                                    <Row gutter={10}>
                                        <Col xs={24} lg={12}>
                                            <Select
                                                name="operatorId"
                                                showSearch={true}
                                                label={`${translate("dashboard.newsEdition.operator")}`}
                                                placeholder={translate("dashboard.newsEdition.operator")}
                                                defaultValue={id ? [item.operatorId] : []}
                                            >
                                                {item?.availableOperators.map((index) => (
                                                    <option key={index.id} value={index.id}>
                                                        {index.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col xs={24} lg={12}>
                                        <InputMask
                                            disabled={item.hasExistingAudits}
                                            mask="11:11"
                                            name="time"
                                            label={translate("dashboard.newsEdition.time")}
                                            placeholder={translate("dashboard.newsEdition.time")}
                                        />
                                        </Col>
                                    </Row>
                                </FormikForm>
                            </Card>
                            <div className={"text-center mb-16"}>
                                <FormSaveCancelButtons
                                    disabledSave={
                                        !formValidator(translate).isValidSync(formik.values)
                                    }
                                    onButtonClick={() => onSubmit(formik.values)}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    )}
                </Formik>
            )}
        </Skeleton>
    )
}

export default ManageNewsEdition;