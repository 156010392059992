import { adminHttpClient } from "../commons/adminHttpClient";

const getAdvertisementAuditReportExcel = (id: string): Promise<any> => {
  return adminHttpClient.get(`/AdvertisementAudits/View/GenerateExcel/${id}`);
};

export const getAdvertisementAuditReportHtml = (id: string): Promise<any> => {
  return adminHttpClient.get(`/AdvertisementAudits/View/GenerateHtml/${id}`);
};

export default getAdvertisementAuditReportExcel;