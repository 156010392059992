import React from "react";

export interface IconProps {
  type: string;
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const Icon: React.FC<IconProps> = props => (
  <i
    className={`${props.type} ${props.className}`}
    onClick={props.onClick}
    style={props.style}
  ></i>
);

export default Icon;
