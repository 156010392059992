import {adminHttpClient}  from "../commons/adminHttpClient";
import {NamedItem, NamedItemInt} from "../commons/namedItem";

export interface AuditeeForSave {
    id : string | null;
    name: string;
    gender: any;
    auditableSubjectName : string;
}
export const AuditeeGenderMap : NamedItemInt[] = [
    {id: 0,  name: "Mashkull"},
    {id: 1,  name: "Femer"},
]

export const getAuditeesForSave = (id : string | undefined) : Promise<AuditeeForSave> => {
    if (id) {
        return adminHttpClient.get(`/Auditees/GetForSave/${id}`);
    }
    return adminHttpClient.get(`/Auditees/GetForSave`);
}

const saveAuditees = (data : any) :Promise<any> => {
    return adminHttpClient.post(`/Auditees`, data);
}
export default saveAuditees;