
import { NamedItemInt } from "application/commons/namedItem";

export enum AdvertisementAuditGroupBy {
    AuditableSubject = 0,
    Industry = 1
}

export const getAdvertisementAuditGroupBy = (translate: (key: string) => string): NamedItemInt[] => {
    var types: NamedItemInt[] = [];
    types.push({
        id: AdvertisementAuditGroupBy.AuditableSubject,
        name: translate("dashboard.reports.auditable_subject")
    });
    types.push({
      	id: AdvertisementAuditGroupBy.Industry,
      	name: translate("dashboard.reports.industry")
    });
	return types;
};