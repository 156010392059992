import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PagedList from "core/application/commons/pagedList";
import {queryRequest} from "../../common/RequestUtils";
import getLiveBroadcast, {LiveBroadcastAuditDto} from "core/application/liveBroadcastAudit/getLiveBroadcastAudit";
import {formatDataAsDashed, formatDateTimeReadable} from "../../common/dateFormatters";
import TableEditButton from "../../common/components/tables/TableEditButton";
import TableDeleteButton from "../../common/components/tables/TableDeleteButton";
import Card from "../../common/components/dataDisplay/Card";
import {Col, notification, Row} from "antd";
import Button from "../../common/components/general/Button";
import {MANAGE_LIVE_BROADCAST} from "./ManageLiveBroadcast";
import AuditTableView from "../common/AuditTableView";
import getLiveBroadcastView from "core/application/liveBroadcastAudit/getLiveBroadcastView";
import deleteLiveBroadcastAudit from "core/application/liveBroadcastAudit/deleteLiveBroadcastAudit";
import {getParentColumns, getNephewColumns, getChildrenColumns} from "./tables/AuditColumns";
import { useSelector } from "react-redux";
import PermissionButton from "common/components/general/PermissionButton";
import { Permission } from "core/domain/users/permission";
import {v4 as uuidv4} from "uuid";
import AuditStatusTag from "../../common/components/dataDisplay/AuditStatusTag";
import {AuditStatus} from "core/domain/common/auditStatus";
import getLiveBroadcastAuditReportExcel from "core/application/liveBroadcastAudit/liveBroadcastsAuditsReports";
import InputSearch, {SearchValues} from "../common/InputSearch";
import {SearchFilter} from "core/domain/common/searchFilter";
import Table from "common/components/dataDisplay/Table";
import { ReportTimeUnit } from "core/domain/reports/reportTimeUnit";

export const LIVE_BROADCAST_PATH = "live-broadcast";

const LiveBroadcastAudit : React.FC = () => {
    let history = useHistory();
    const {t: translate} = useTranslation();
    const [loading , setLoading] = useState<boolean>(false);
    const [isModalVisible, setModalVisible] = useState(false);
    const [auditId, setAuditId] = useState(null);
    const userLoginInfo = useSelector((s: any) => s.userReducer);
    const [items , setItems] = useState<PagedList<LiveBroadcastAuditDto>>({} as PagedList<LiveBroadcastAuditDto>);
    const [searchFilter, setSearchFilter] = useState<SearchFilter>({
        pageNumber: 1,
        pageSize: 20,
        orderColumn: "createdAt",
        orderDescending: true
    });
    const searchInitialValues = {inputSearch: searchFilter.searchValue} as SearchValues;

    useEffect(() => {
        (async() => {
            await updateTableDate();
        })();
    }, [searchFilter])

    const updateTableDate = async () =>{
        setLoading(true);
        setItems(await queryRequest(() => getLiveBroadcast(searchFilter)))
        setLoading(false);
    }
    const getColumns = (): any[] => {
        const columns = [
            {
                title: translate("dashboard.ticker.operatorName"),
                dataIndex: "operatorName",
                key: "operatorName",
                width: "30%",
                sorter: true,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => (
                    <div>
                        <span>{record.operatorName}</span>
                    </div>
                )
            },
            {
                title: translate("general.date"),
                dataIndex: "date",
                key: "date",
                width: "30%",
                sorter: true,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => (
                    <div>
                        <span>{formatDataAsDashed(record.date)}</span>
                    </div>
                )
            },
            {
                title: translate("dashboard.status"),
                dataIndex: "status",
                key: `status_${uuidv4()}`,
                responsive: ["md"],
                sorter: true,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => (<AuditStatusTag status={record.status}/>)

            },
            {
                title: translate("dashboard.created_at"),
                dataIndex: "createdAt",
                key: "createdAt",
                responsive: ["md"],
                sorter: true,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => formatDateTimeReadable(record.createdAt)
            },
            {
                title: translate("dashboard.created_by"),
                dataIndex: "createdBy",
                key: "createdBy",
                responsive: ["md"],
                sorter: false,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record :any ) =>( <span>{record.createdBy}</span>)
            },
            {
                title: translate("dashboard.action"),
                width: "10%",
                dataIndex: "",
                key: "actions",
                align: "center",
                sorter: false,
                render: (text: string, record: any) => (
                    <span>
                        <div className="actions flex flex__center">
                            <PermissionButton
                                permissions={[Permission.ViewLiveBroadcastAudit]}
                                userPermissions={userLoginInfo.user.permissions}
                                className="mr-16"
                                iconPadding={false}
                                icon={"far fa-list"}
                                onClick={() => {
                                    setAuditId(record.id);
                                    setModalVisible(true)
                                }}
                            />
                            <TableEditButton
                                permissions={[record.status === AuditStatus.Published ? Permission.EditAfterPublished : Permission.SaveLiveBroadcastAudit]}
                                userPermissions={userLoginInfo.user.permissions}
                                className="mr-16"
                                editUrl={`/${LIVE_BROADCAST_PATH}/${MANAGE_LIVE_BROADCAST}/${record.id}`}
                            />
                          <TableDeleteButton
                                permissions={[Permission.DeleteLiveBroadcastAudit]}
                                userPermissions={userLoginInfo.user.permissions}
                                onDelete={() => deleteLiveBroadcastAudit(record.id)}
                                onDeleted={() => updateTableDate()}
                          />
                        </div>
                    </span>
                )
            }
        ];

        const columnToSort: any = columns.find(c => c.dataIndex === searchFilter.orderColumn);
        columnToSort.sortOrder = searchFilter.orderDescending ? "descend" : "ascend";

        return columns;
    };
    const onTableChange = (pagination: any, filters: any, sorter: any) => {
        setSearchFilter({
            ...searchFilter,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            orderColumn: sorter.field || "createdAt",
            orderDescending: sorter.order !== "ascend"
        });
    };
    const onSearch =  (search : any) => {
        if (search.inputSearch && search.inputSearch?.length < 2) {
            notification.open({type: "error", message: translate("general.search_length")});
        } else {
            setSearchFilter({
                ...searchFilter,
                searchValue: search.inputSearch,
            });
        }
    }
    return (
        <Card title={
            <Row>
                <Col xs={12} md={10}>
                    <h4>{translate("dashboard.liveBroadcast.name")}</h4>
                </Col>
                <Col xs={12} md={11}>
                    <InputSearch
                        placeholder={'Operatori, Data(dd/mm/yyyy)'}
                        initialValues={searchInitialValues}
                        onSearch={onSearch}
                        translate={translate}
                        loading={loading}
                    />
                </Col>
                <Col xs={12} md={3}>
                    <div className={"text-right"}>
                        <PermissionButton
                            permissions={[Permission.SaveLiveBroadcastAudit]}
                            userPermissions={userLoginInfo.user.permissions}
                            icon="far fa-plus"
                            type="primary"
                            onClick={() => {
                                history.push(
                                    `${history.location.pathname}/${MANAGE_LIVE_BROADCAST}`
                                );
                            }}>
                            {translate("dashboard.add")}
                        </PermissionButton>
                    </div>
                </Col>
            </Row>
        }>
            <Row>
                <Col span={24}>
                    <Table
                        dataSource={items.items}
                        onChange={onTableChange}
                        columns={getColumns()}
                        loading={loading}
                        rowKey={record => record.id}
                        pagination={{
                            total: items.totalItemCount,
                            pageSize: items.pageSize,
                            current: items.pageNumber,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "20", "30", "40"]
                        }}
                    />
                </Col>
            </Row>
            <AuditTableView
                name={"Transmetime Live"}
                auditId={auditId}
                getAuditData={getLiveBroadcastView}
                isModalVisible={isModalVisible}
                onCloseModal={() => setModalVisible(false)}
                childrenName={'auditItems'}
                nephewName={'auditSubjects'}
                parentColumns={getParentColumns()}
                childColumns={getChildrenColumns()}
                nephewColumns={getNephewColumns()}
                getAuditReportExcel={getLiveBroadcastAuditReportExcel}
                auditTemplateName={"dashboard.liveBroadcast.name"}
                timeUnit={ReportTimeUnit.Minutes}
                entityName={"operatorName"}
                user={userLoginInfo.user}
            />
        </Card>
    )

}

export default LiveBroadcastAudit;
