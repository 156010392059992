import React, { useState, ReactNode } from "react";
import { useField, useFormikContext } from "formik";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { InputProps } from "antd/lib/input";

import Error from "./FormItemError";
import PasswordInput from "../components/PasswordInput";
import Popover from "../../dataDisplay/Popover";
import Progress from "../../feedback/Progress";
import FieldProps from "./FieldProps";
import Label from "../components/Label";
import PasswordPopoverContent, {
  ContentProps
} from "../components/PasswordPopoverContent";
import {
  numberRegx,
  lowercaseRegx,
  uppercaseRegx,
  specialCharRegx
} from "../../utils/Regx";

import "./FormikStyles.less";

type PasswordProps = InputProps &
  FieldProps & {
    popover?: boolean;
    progress?: boolean;
  };

const TOTAL_REQUIRED_STEPS = 5;
const TOTAL_PROGRESS = 100;
const NR_OF_CHARS_REQUIRED = 8;
const SINGLE_STEP_PROGRESS = TOTAL_PROGRESS / TOTAL_REQUIRED_STEPS;
const hasNumberKey = 1;
const hasUppercaseKey = 2;
const hasLowerCaseKey = 3;
const hasLength8Key = 4;
const hasSpecialCharsKey = 5;

let progressMap = new Map<number, boolean>([
  [hasNumberKey, false],
  [hasUppercaseKey, false],
  [hasLowerCaseKey, false],
  [hasLength8Key, false],
  [hasSpecialCharsKey, false]
]);

const FormikInputField: React.FC<PasswordProps> = props => {
  const [field, meta] = useField(props);
  const showError = meta.touched && meta.error;

  const [progressState, setProgress] = useState<number>(0);
  const { t: translate } = useTranslation();
  const { setFieldValue } = useFormikContext();

  let passwordValidationResult: ContentProps = {
    nrOfcharsIcon: false,
    lowercaseIcon: false,
    oneNumberIcon: false,
    specialCharIcon: false,
    uppercaseIcon: false,
    translate: null
  };

  const [content, setContent] = useState<ReactNode>(
    <PasswordPopoverContent
      {...passwordValidationResult}
      translate={translate}
    />
  );

  const calculatePasswordPopoverContent = (password: string) => {
    passwordValidationResult.oneNumberIcon = password.match(numberRegx) != null;
    passwordValidationResult.lowercaseIcon =
      password.match(lowercaseRegx) != null;
    passwordValidationResult.nrOfcharsIcon = password.length >= 8;
    passwordValidationResult.specialCharIcon =
      password.match(specialCharRegx) != null;
    passwordValidationResult.uppercaseIcon =
      password.match(uppercaseRegx) != null;

    setContent(
      <PasswordPopoverContent
        {...passwordValidationResult}
        translate={translate}
      />
    );
  };

  const calculateProgress = (password: string) => {
    progressMap.set(hasNumberKey, password.match(numberRegx) != null);
    progressMap.set(hasLowerCaseKey, password.match(lowercaseRegx) != null);
    progressMap.set(hasUppercaseKey, password.match(uppercaseRegx) != null);
    progressMap.set(
      hasSpecialCharsKey,
      password.match(specialCharRegx) != null
    );
    progressMap.set(hasLength8Key, password.length >= NR_OF_CHARS_REQUIRED);

    let progressCount = 0;
    progressMap.forEach((value, key) => {
      if (value) progressCount += SINGLE_STEP_PROGRESS;
    });

    setProgress(progressCount);
  };

  const onPasswordInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    let password = e.currentTarget.value;
    setFieldValue(props.name, password);
    calculatePasswordPopoverContent(password);
    calculateProgress(password);
  };

  const id = props.id || props.name;
  const errorClassname = classnames({ password_error: showError });

  const passWordInput = (
    <PasswordInput
      {...field}
      {...props}
      onChange={onPasswordInputChange}
      className={`${errorClassname} ${props.className}`}
      id={id}
    />
  );

  const input = props.popover ? (
    <Popover
      title={translate("change_password.requirements")}
      content={content}
      placement="bottom"
      className="password-popover"
    >
      {passWordInput}
    </Popover>
  ) : (
    passWordInput
  );

  const progressComponent = props.progress ? (
    <div className="progress-bar">
      <Progress
        percent={progressState}
        strokeColor="#11D175"
        showInfo={false}
      />
    </div>
  ) : null;

  const errorComponent = showError ? (
    <Error>{meta.error}</Error>
  ) : (
    <Error></Error>
  );

  return (
    <>
      <Label
        htmlFor={id}
        className="formik-field__input-label no-select hand-on-hover"
      >
        {props.label}
      </Label>
      {input}
      <div className="mb-16 mt-8">{errorComponent}&nbsp;</div>
      {/* {progressComponent} */}
    </>
  );
};

export default FormikInputField;
