import React from "react";
import { useTranslation } from "react-i18next";
import { message, Modal } from "antd";
import { commandRequest } from "../../RequestUtils";
import Button from "../general/Button";
import { Permission } from "core/domain/users/permission";
import PermissionButton from "../general/PermissionButton";

const { confirm } = Modal;

interface TableDeleteButtonProps {
  onDelete: () => void;
  onDeleted: () => void;
  disableDeleted?: boolean;
  deleteTitle?: string;
  title?: string;
  content?: string;
  visible?: boolean;
  permissions?: Permission[];
  userPermissions?: Permission[];
}

const handleOnClick = (
  props: TableDeleteButtonProps,
  translate: (key: string) => string
) => {
  confirm({
    title: props.title ?? translate("general.delete_alert_title"),
    content: props.content ?? translate("general.delete_alert_content"),
    okText: translate("general.ok_text"),
    cancelText: translate("general.cancel_text"),
    onOk: async () => {
      const result = await commandRequest(() => props.onDelete());
      if (!result.errors) {
        props.onDeleted();
      } else {
        message.error(result.errors[0]);
      }
    }
  });
};

const TableDeleteButton: React.FC<TableDeleteButtonProps> = props => {
  const { t: translate } = useTranslation();
  return (
    <>
      {props.permissions && props.userPermissions ? (
        <PermissionButton
          permissions={props.permissions}
          userPermissions={props.userPermissions}
          className={"error-button"}
          disabled={props.disableDeleted}
          icon="far fa-trash-alt"
          iconPadding={false}
          onClick={() => handleOnClick(props, translate)}
        >
          {props.deleteTitle}
        </PermissionButton>
      ) : (
        <Button
          className={"error-button"}
          disabled={props.disableDeleted}
          icon="far fa-trash-alt"
          iconPadding={false}
          onClick={() => handleOnClick(props, translate)}
        >
          {props.deleteTitle}
        </Button>
      )}
    </>
  );
};

export default TableDeleteButton;
