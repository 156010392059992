import React from "react";
import { useHistory } from "react-router-dom";

import Button from "../../components/general/Button";
import { Permission } from "core/domain/users/permission";
import PermissionButton from "../general/PermissionButton";

interface TableEditButtonProps {
  editUrl: string;
  className?: string;
  visible?: boolean;
  permissions?: Permission[];
  userPermissions?: Permission[];
}

const TableEditButton: React.FC<TableEditButtonProps> = ({
  editUrl,
  className,
  permissions,
  userPermissions
}) => {
  const history = useHistory();

  return (
    <>
      {permissions && userPermissions ? (
        <PermissionButton
          permissions={permissions}
          userPermissions={userPermissions}
          iconPadding={false}
          className={className}
          icon={"far fa-edit"}
          onClick={() => {
            history.push(editUrl);
          }}
        ></PermissionButton>
      ) : (
        <Button
          iconPadding={false}
          className={className}
          icon={"far fa-edit"}
          onClick={() => {
            history.push(editUrl);
          }}
        ></Button>
      )}
    </>
  );
};

export default TableEditButton;
