import React, { ReactNode, useEffect, useState } from "react";
import { Menu, Layout as AntLayout, Row, Col } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import HeaderUserSection from "../common/components/layout/HeaderUserSection";

import { User } from "core/application/account/loginUser";

import { ADMIN_DASHBOARD_PATH } from "./Routes";
import { ADVERTISEMENT_PATH } from "./advertisements/AdvertisementAudit";
import { APP_PATH } from "Routes";

import "./dashboard.less";
import { NEWS_AUDIT_PATH } from "./news/NewsAudits";
import { PROGRAM_AUDIT_PATH } from "./programs/ProgramAudits";
import { OPERATORS_PATH } from "./operators/Operators";
import { NEWS_TICKER_PATH } from "./ticker/NewsTicker";
import { CONFIGURATION_PATH } from "./configurations/ConfigurationWrapper";
import { LIVE_BROADCAST_PATH } from "./liveBroadcastAudit/LiveBroadcastAudit";
import { USERS_PATH } from "./users/Users";
import { ADVERTISEMENT_PATH as ADVERTISEMENTS_PATH } from "./configurations/advertisements/Advertisement";
import { Permission } from "core/domain/users/permission";
import { AVAILABLE_SUBJECT_PATH } from "./configurations/subjects/AvailableSubject";
import { ADVERTISEMENT_TYPE_PATH } from "./configurations/advertisementTypes/AdvertisementType";
import { AUDITEE_PATH } from "./configurations/auditees/Auditee";
import { INDUSTRY_PATH } from "./configurations/industries/Industry";
import { NEWS_EDITION_PATH } from "./configurations/newsEditions/NewsEdition";
import { NEWS_TOPICS_PATH } from "./configurations/newsTopics/NewsTopics";
import { PROGRAM_PATH as PROGRAMS_PATH } from "./configurations/programs/Program";
import { NOTES_PATH } from "./otherAudits/notes/Notes";
import { OTHER_AUDITS_PATH } from "./otherAudits/OtherAuditsWrapper";
import { POLLS_PATH } from "./otherAudits/polls/Polls";
import { BANNED_ACTIVITIES_PATH } from "./otherAudits/bannedActivities/BannedActivities";

const { Content } = AntLayout;
interface DashboardProps {
  children?: ReactNode;
  user: User;
}
export interface MenuItem {
  name: string;
  key: number;
  path: string;
  icon?: string;
  permissions: Permission[];
}

export enum NavigationMapping {
  Home = 1,
  Advertisement = 2,
  News = 3,
  LiveBroadcast = 4,
  Programs = 5,
  Ticker = 6,
  OtherAudits = 7,
  Configuration = 8,
  Users = 9
}

export const navigationPathMapping = new Map<string, number>([
  ["/", NavigationMapping.Home],
  [ADVERTISEMENT_PATH, NavigationMapping.Advertisement],
  [NEWS_AUDIT_PATH, NavigationMapping.News],
  [LIVE_BROADCAST_PATH, NavigationMapping.LiveBroadcast],
  [PROGRAM_AUDIT_PATH, NavigationMapping.Programs],
  [NEWS_TICKER_PATH, NavigationMapping.Ticker],
  [OTHER_AUDITS_PATH, NavigationMapping.OtherAudits],
  [CONFIGURATION_PATH, NavigationMapping.Configuration],
  [USERS_PATH, NavigationMapping.Users]
]);

const configurationsPermissions = [
  Permission.Advertisements,
  Permission.AdvertisementTypes,
  Permission.AuditableSubjects,
  Permission.Auditees,
  Permission.Industries,
  Permission.NewsEditions,
  Permission.NewsTopics,
  Permission.Operators,
  Permission.Programs
];

export const configurationsPathMapping = new Map<number, string>([
  [Permission.AdvertisementTypes, ADVERTISEMENT_TYPE_PATH],
  [Permission.AuditableSubjects, AVAILABLE_SUBJECT_PATH],
  [Permission.Auditees, AUDITEE_PATH],
  [Permission.Industries, INDUSTRY_PATH],
  [Permission.NewsEditions, NEWS_EDITION_PATH],
  [Permission.NewsTopics, NEWS_TOPICS_PATH],
  [Permission.Programs, PROGRAMS_PATH],
  [Permission.Operators, OPERATORS_PATH]
]);

const otherAuditsPermissions = [
  Permission.Notes,
  Permission.Polls,
  Permission.BannedActivities
];

export const otherAuditsPathMapping = new Map<number, string>([
  [Permission.Notes, NOTES_PATH],
  [Permission.Polls, POLLS_PATH],
  [Permission.BannedActivities, BANNED_ACTIVITIES_PATH]
]);

export const navigationMenu: MenuItem[] = [
  {
    name: "dashboard.home",
    key: NavigationMapping.Home,
    path: "/",
    permissions: [Permission.Reports]
  },
  {
    name: "dashboard.advertisementAudit",
    key: NavigationMapping.Advertisement,
    path: `/${ADVERTISEMENT_PATH}`,
    permissions: [
      Permission.ViewAdvertisementAudit,
      Permission.ViewAllAdvertisementAudits,
      Permission.SaveAdvertisementAudit,
      Permission.DeleteAdvertisementAudit
    ]
  },
  {
    name: "dashboard.news.newsAudit",
    key: NavigationMapping.News,
    path: `/${NEWS_AUDIT_PATH}`,
    permissions: [
      Permission.ViewNewsAudit,
      Permission.ViewAllNewsAudits,
      Permission.SaveNewsAudit,
      Permission.DeleteNewsAudit
    ]
  },
  {
    name: "Transmetime Live",
    key: NavigationMapping.LiveBroadcast,
    path: `/${LIVE_BROADCAST_PATH}`,
    permissions: [
      Permission.ViewLiveBroadcastAudit,
      Permission.ViewAllLiveBroadcastAudits,
      Permission.SaveLiveBroadcastAudit,
      Permission.DeleteLiveBroadcastAudit
    ]
  },
  {
    name: "dashboard.programs.programAudit",
    key: NavigationMapping.Programs,
    path: `/${PROGRAM_AUDIT_PATH}`,
    permissions: [
      Permission.ViewProgramAudit,
      Permission.ViewAllProgramAudits,
      Permission.SaveProgramAudit,
      Permission.DeleteProgramAudit
    ]
  },
  {
    name: "dashboard.ticker.name",
    key: NavigationMapping.Ticker,
    path: `/${NEWS_TICKER_PATH}`,
    permissions: [
      Permission.ViewNewsTickerAudit,
      Permission.ViewAllNewsTickerAudits,
      Permission.SaveNewsTickerAudit,
      Permission.DeleteNewsTickerAudit
    ]
  },
  {
    name: "dashboard.other_audits.title",
    key: NavigationMapping.OtherAudits,
    path: `/${OTHER_AUDITS_PATH}/${NOTES_PATH}`,
    permissions: otherAuditsPermissions
  },
  {
    name: "dashboard.configurations.name",
    key: NavigationMapping.Configuration,
    path: `/${CONFIGURATION_PATH}/${ADVERTISEMENTS_PATH}`,
    permissions: configurationsPermissions
  },
  {
    name: "users.name",
    key: NavigationMapping.Users,
    path: `/${USERS_PATH}`,
    permissions: [Permission.Users]
  }
];

const filterMenuItemBasedOnPermissions = (menuItem: MenuItem, user: User) => {
  return (
    user.permissions.includes(Permission.All) ||
    menuItem.permissions.length == 0 ||
    user.permissions.some((p: Permission) => menuItem.permissions.includes(p))
  );
};

export const getConfigurationPermission = (user: User) => {
  var hasConfigurationPermissions =
    user.permissions.includes(Permission.All) ||
    user.permissions.some((p: Permission) =>
      configurationsPermissions.includes(p)
    );
  if (hasConfigurationPermissions) {
    var permissions = user.permissions.filter((p: Permission) =>
      configurationsPermissions.includes(p)
    );
    if (permissions && permissions.length > 0) {
      return permissions[0];
    } else {
      return Permission.AdvertisementTypes;
    }
  }
  return null;
};

export const getOtherAuditsPermissions = (user: User) => {
  var hasOtherAuditsPermissions =
    user.permissions.includes(Permission.All) ||
    user.permissions.some((p: Permission) =>
      otherAuditsPermissions.includes(p)
    );
  if (hasOtherAuditsPermissions) {
    var permissions = user.permissions.filter((p: Permission) =>
      otherAuditsPermissions.includes(p)
    );
    if (permissions && permissions.length > 0) {
      return permissions[0];
    } else {
      return Permission.Notes;
    }
  }
  return null;
};

const DashboardWrapper: React.FC<DashboardProps> = ({ children, user }) => {
  let history = useHistory();
  let location = useLocation();
  const [activeKey, setActiveKey] = useState<string[] | undefined>();
  const { t: translate } = useTranslation();
  const userLoginInfo = useSelector((s: any) => s.userReducer);

  const goToPath = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    let pathname = location.pathname;
    const split = pathname.split("/")[1];
    const res = navigationPathMapping.get(split);
    setActiveKey(res ? [res.toString()] : [NavigationMapping.Home.toString()]);
  }, [location]);

  const dashboardMenuItems = navigationMenu
    .filter(item => filterMenuItemBasedOnPermissions(item, userLoginInfo.user))
    .sort((first, second) => first.key - second.key)
    .map((item: MenuItem) => (
      <Menu.Item
        key={item.key}
        onClick={() => {
          if (
            item.key === NavigationMapping.Configuration &&
            getConfigurationPermission(user)
          ) {
            goToPath(
              `${ADMIN_DASHBOARD_PATH}${CONFIGURATION_PATH}/${configurationsPathMapping.get(
                getConfigurationPermission(user)
              )}`
            );
          } else if (
            item.key === NavigationMapping.OtherAudits &&
            getOtherAuditsPermissions(user)
          ) {
            goToPath(
              `${ADMIN_DASHBOARD_PATH}${OTHER_AUDITS_PATH}/${otherAuditsPathMapping.get(
                getOtherAuditsPermissions(user)
              )}`
            );
          } else {
            goToPath(item.path);
          }
        }}
      >
        {translate(item.name)}
      </Menu.Item>
    ));

  return (
    <>
      <div className={"header-menu-container mb-16"}>
        <div className={"container"}>
          <Row>
            <Col md={2} xs={2} className={"pt-4 logo"}>
              <a onClick={() => goToPath(APP_PATH)}>
                <img src="/assets/logos/logo.png" alt="AMA Logo" width={100} />
              </a>
            </Col>
            <Col md={20} xs={12}>
              <Menu
                selectedKeys={activeKey}
                mode="horizontal"
                className={"text-center"}
              >
                {dashboardMenuItems}
              </Menu>
            </Col>
            <Col md={2} xs={10} className={"pt-8"}>
              <div className={"text-right"}>
                <HeaderUserSection user={user} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Content>
        <div className="container-fluid">{children}</div>
      </Content>
    </>
  );
};
export default DashboardWrapper;
