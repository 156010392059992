import { useLocation } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const useUrlQueryParam = (param: string) => {
  let query = useQuery();
  return query.get(param);
};

export default useUrlQueryParam;
