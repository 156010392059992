import React, { ReactNode } from "react";
import { Button as AntButton } from "antd";
import {
  ButtonHTMLType,
  ButtonShape,
  ButtonType
} from "antd/lib/button/button";
import classnames from "classnames";
import { ButtonSize } from "antd/lib/button";

import Icon from "./Icon";

import "./ButtonStyles.less";

type IconPosition = "left" | "right";

export interface ButtonProps {
  disabled?: boolean;
  icon?: string | undefined;
  iconPosition?: IconPosition | undefined;
  shape?: ButtonShape;
  size?: ButtonSize;
  type?: ButtonType;
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  htmlType?: ButtonHTMLType;
  loading?: boolean;
  children?: ReactNode;
  iconPadding?: boolean;
}

const getButtonContentBasedOnIconState = (
  icon: string | undefined,
  iconPosition: IconPosition | undefined,
  children: ReactNode,
  iconPadding: boolean | undefined
): ReactNode => {
  const classNames = classnames({
    "pr-8": iconPadding === undefined || iconPadding === true,
    "pl-8": iconPadding === undefined || iconPadding === true
  });

  if (icon === undefined) {
    return children;
  } else {
    if (iconPosition === undefined || iconPosition === "left") {
      return (
        <>
          <Icon type={icon} className={classNames} />
          {children}
        </>
      );
    } else {
      return (
        <>
          {children}
          <Icon type={icon} className={classNames} />
        </>
      );
    }
  }
};

class Button extends React.Component<ButtonProps> {
  static __ANT_BUTTON = true;

  render() {
    const { icon, iconPosition, children, iconPadding, ...props } = this.props;
    return (
      <AntButton {...props} className={`${props.className}`}>
        {getButtonContentBasedOnIconState(
          icon,
          iconPosition,
          children,
          iconPadding
        )}
      </AntButton>
    );
  }
}

export default Button;
