import React from "react";
import { Row, Table as AntdTable } from "antd";

import Card from "common/components/dataDisplay/Card";
import { OperatorAuditReportDto } from "core/application/reports/operatorAuditsReport";
import Table from "common/components/dataDisplay/Table";

interface OperatorAuditReportTableProps {
  auditReport: OperatorAuditReportDto;
  entityName: string;
  minutes: boolean;
  loading: boolean;
  translate: (key: string) => string;
}

const OperatorAuditReportTable: React.FC<OperatorAuditReportTableProps> = ({
  auditReport,
  entityName,
  minutes,
  loading,
  translate
}) => {
  const getColumns = (): any[] => {
    const columns = [
      {
        title: entityName,
        dataIndex: "name",
        key: "name",
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.name}</span>
            </div>
          </span>
        )
      },
      {
        title: translate("dashboard.reports.time"),
        dataIndex: "time",
        key: "time",
        sorter: (a: any, b: any) => a.time - b.time,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{minutes ? record.time.toFixed(2) : record.time}</span>
            </div>
          </span>
        )
      }
    ];

    return columns;
  };

  return (
    <Row>
      <Card bordered={false} title={auditReport.auditName}>
        <Table
          bordered={true}
          rowKey={record => record.id}
          dataSource={auditReport.auditedEntities}
          loading={loading}
          columns={getColumns()}
          pagination={false}
          summary={() => {
            return (
              <>
                <AntdTable.Summary.Row>
                  <AntdTable.Summary.Cell index={0}>
                    {translate("dashboard.reports.total")}
                  </AntdTable.Summary.Cell>
                  <AntdTable.Summary.Cell index={1}>
                    <b>
                      {minutes
                        ? auditReport.totalTime.toFixed(2)
                        : auditReport.totalTime}
                    </b>
                  </AntdTable.Summary.Cell>
                </AntdTable.Summary.Row>
                {auditReport.malePercentage != null && (
                  <AntdTable.Summary.Row>
                    <AntdTable.Summary.Cell index={0}>
                      {translate("dashboard.reports.male_percentage")}
                    </AntdTable.Summary.Cell>
                    <AntdTable.Summary.Cell index={1}>
                      <span>{auditReport.malePercentage.toFixed(2)}</span>
                    </AntdTable.Summary.Cell>
                  </AntdTable.Summary.Row>
                )}
                {auditReport.femalePercentage != null && (
                  <AntdTable.Summary.Row>
                    <AntdTable.Summary.Cell index={0}>
                      {translate("dashboard.reports.female_percentage")}
                    </AntdTable.Summary.Cell>
                    <AntdTable.Summary.Cell index={1}>
                      <span>{auditReport.femalePercentage.toFixed(2)}</span>
                    </AntdTable.Summary.Cell>
                  </AntdTable.Summary.Row>
                )}
              </>
            );
          }}
        />
      </Card>
    </Row>
  );
};

export default OperatorAuditReportTable;
