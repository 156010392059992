import { adminHttpClient } from "../commons/adminHttpClient";
import { NamedItemInt } from "application/commons/namedItem";

export interface BannedActivityForSaveDto {
  id: string | null;
  name: string;
  operatorId: string;
  subjectId: string;
  date: string;
  availableOperators: NamedItemInt[];
  availableSubjects: NamedItemInt[];
}

export const getBannedActivityForSave = (
  id: string | undefined
): Promise<any> => {
  if (id) {
    return adminHttpClient.get(`/BannedActivities/GetForSave/${id}`);
  }
  return adminHttpClient.get(`/BannedActivities/GetForSave`);
};

export const saveBannedActivity = (data: any): Promise<any> => {
  return adminHttpClient.post(`/BannedActivities`, data);
};
