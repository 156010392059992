import {adminHttpClient} from "../commons/adminHttpClient";
import {NamedItem} from "../commons/namedItem";

export interface NewsEditionForSave {
    id :string
    time :string
    operatorId: string;
    operatorName : string
    availableOperators : NamedItem[];
    hasExistingAudits: boolean;
}
export const getNewsEditionForSave = (id : string | undefined) : Promise<NewsEditionForSave> => {
    if(id){
        return adminHttpClient.get(`/NewsEditions/GetForSave/${id}`);
    }
    return adminHttpClient.get(`/NewsEditions/GetForSave`);
}

const saveNewsEdition = ( data : any ) : Promise<any> => {
    return adminHttpClient.post(`/NewsEditions`, data);
}
export default saveNewsEdition;