import {jsPDF} from "jspdf";
import autoTable from "jspdf-autotable";
export const downloadExcel = (content: any, fileName: string) => {
    const linkSource = `data:application/octet-stream;base64,${content}`;
    const downloadLink = document.createElement("a");
  
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

export const generateReportPdf = (actors : any, data : any, pageHeader : string, templateName : string, headerColumns : any, print?: boolean) => {
    const doc = new jsPDF()
    let xOffset = (doc.internal.pageSize.width / 2);
    doc.text(pageHeader,  xOffset, 10, {align:'center'});
    for (let i = 0; i <= actors.length - 1; i++){
        autoTable(doc, {
            margin: { top: 35 },
            head:[[{content: actors[i], colSpan: 3, styles: {halign: 'center', fillColor:'#eee'}}],headerColumns],
            headStyles:{fillColor:'#fff', textColor:'#000', fontStyle: 'normal'},
            body: data[i],
        });
    }

    for(let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(String(i),220-20,320-30);
    }
    //@ts-ignore
    print ? window.open(doc.output('bloburl'), '_blank') : doc.save(templateName);
}
export const resolveReportDataForPdf = (data : any, isMinutes? : boolean) => {
    let temp = [];
    for (let i = 0; i <= data.columnNames.length -1; i++){
        const inner = [];
        for (let j = 0; j <= data.entitySummary.length -1; j++){
            inner.push([
                `${j + 1}`,
                data.entitySummary[j].entityName,
                `${isMinutes ? data.entitySummary[j].times[i].toFixed(2) : data.entitySummary[j].times[i]}`
            ])
        }
        temp.push(inner);
    }
    return temp;
}