import React from "react";
import { Row } from "antd";

import Card from "common/components/dataDisplay/Card";
import { AuditReportDto } from "core/application/reports/auditableSubjectsReport";
import Table from "common/components/dataDisplay/Table";

interface LiveBroadcastAuditReportTableProps {
  auditReport: AuditReportDto;
  entityName: string;
  minutes: boolean;
  loading: boolean;
  translate: (key: string) => string;
}

const AuditReportTable: React.FC<LiveBroadcastAuditReportTableProps> = ({
  auditReport,
  entityName,
  minutes,
  loading,
  translate
}) => {
  const getColumns = (): any[] => {
    const columns = [];

    columns.push({
      title: entityName,
      dataIndex: "entityName",
      key: "entityName",
      width: 100,
      fixed: "left",
      sorter: (a: any, b: any) => a.entityName.localeCompare(b.entityName),
      sortDirections: ["descend", "ascend"],
      render: (text: string, record: any) => (
        <span>
          <div>
            <span>{record.entityName}</span>
          </div>
        </span>
      )
    });

    auditReport.columnNames.map((name: string, i: number) => {
      columns.push({
        title: name,
        dataIndex: name,
        key: name,
        width: 100,
        sorter: (a: any, b: any) => a.times[i] - b.times[i],
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{minutes ? record.times[i].toFixed(2) : record.times[i]}</span>
            </div>
          </span>
        )
      });
    });

    return columns;
  };

  return (
    <Row>
      <Card bordered={false}>
        <Table
          rowKey={record => record.entityName}
          dataSource={auditReport.entitySummary}
          loading={loading}
          columns={getColumns()}
          pagination={false}
          scroll={{ x: 1300 }}
        />
      </Card>
    </Row>
  );
};

export default AuditReportTable;
