import {adminHttpClient} from "../commons/adminHttpClient";
import PagedList from "../commons/pagedList";
import PagedFilter from "../commons/pagedFilter";

export interface NewsEditionDto {
    id :string
    time :string
    operatorName : string
    createdBy :string
    createdAt :string
}
const getNewsEdition = (filter : PagedFilter) : Promise<PagedList<NewsEditionDto>> => {
    return adminHttpClient.get(`/NewsEditions/Get`, filter);
}

export default getNewsEdition;