import React, { useState } from "react";
import {Modal,} from "antd";

import TableEditButton from "../../../common/components/tables/TableEditButton";
import TableDeleteButton from "../../../common/components/tables/TableDeleteButton";

import { PagedAdvertisementAudits } from "core/application/advertisementAudits/getAdvertisementAudits"
import { AdvertisementAuditFilter } from "core/application/advertisementAudits/getAdvertisementAudits";

import {formatDataAsDashed, formatDateTimeReadable} from "../../../common/dateFormatters";
import {ADVERTISEMENT_PATH} from "../AdvertisementAudit";
import {MANAGE_ADVERTISEMENT_AUDIT_PATH} from "../ManageAdvertisementAudit";
import {v4 as uuidv4} from "uuid";
import AuditTableView from "../../common/AuditTableView";
import {queryRequest} from "../../../common/RequestUtils";
import getAdvertisementAuditView from "core/application/advertisementAudits/getAdvertisementAuditView";
import getAdvertisementAuditReportExcel, { getAdvertisementAuditReportHtml } from "core/application/advertisementAudits/advertisementAuditsReports";
import {getChildrenColumns, getNephewColumns, getParentColumns} from "./AuditColumns";
import Button from "../../../common/components/general/Button";
import {CHANGE_PASSWORD_FOR_USER_PATH} from "../../users/ChangePasswordForUser";
import {USERS_PATH} from "../../users/Users";
import PermissionButton from "common/components/general/PermissionButton";
import { User } from "core/application/account/loginUser";
import { Permission } from "core/domain/users/permission";
import AuditStatusTag from "../../../common/components/dataDisplay/AuditStatusTag";
import {AuditStatus} from "core/domain/common/auditStatus";
import Table from "common/components/dataDisplay/Table";
import { ReportTimeUnit } from "core/domain/reports/reportTimeUnit";


interface Props {
    setSearchFilter: (value: AdvertisementAuditFilter) => void;
    filter: AdvertisementAuditFilter;
    item: PagedAdvertisementAudits;
    history: any;
    translate: (key: string) => string;
    loading : boolean;
    user: User;
    onDelete: (id: string) => void;
    updateTable: () => void;
}

const AdvertisementAuditTable: React.FC<Props> =
 ({
     setSearchFilter,
     filter,
     item,
     history,
     translate,
     loading,
     user,
     onDelete,
     updateTable
 }) => {
    const { confirm } = Modal;
    const [isModalVisible, setModalVisible] = useState(false);
    const [auditId, setAuditId] = useState(null);

    const getColumns = (): any[] => {
        const columns = [
            {
                title: translate("dashboard.advertisements.operator"),
                dataIndex: "operatorName",
                key: "operatorName",
                width: "30%",
                sorter: true,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => (
                    <div>
                        <span>{record.operatorName}</span>
                    </div>
                )
            },
            {
                title: translate("general.date"),
                dataIndex: "date",
                key: `date`,
                width: "20%",
                sorter: true,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => (
                    <div>
                        <span>{formatDataAsDashed(record.date)}</span>
                    </div>
                )
            },
            {
                title: translate("dashboard.status"),
                dataIndex: "status",
                key: `status_${uuidv4()}`,
                responsive: ["md"],
                sorter: true,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => (<AuditStatusTag status={record.status}/>)

            },
            {
                title: translate("dashboard.created_at"),
                dataIndex: "createdAt",
                key: "createdAt",
                sorter: true,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => formatDateTimeReadable(record.createdAt)
            },
            {
                title: translate("dashboard.created_by"),
                dataIndex: "createdBy",
                key: "createdBy",
                responsive: ["md"],
                sorter: false,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record :any ) =>( <span>{record.createdBy}</span>)
            },
            {
                title: translate("dashboard.action"),
                width: "10%",
                dataIndex: "",
                key: "actions",
                align: "center",
                sorter: false,
                render: (text: string, record: any) => (
                    <span>
                        <div className="actions flex flex__center">
                            <PermissionButton
                                permissions={[Permission.ViewAdvertisementAudit]}
                                userPermissions={user.permissions}
                                className="mr-16"
                                iconPadding={false}
                                icon={"far fa-list"}
                                onClick={() => {
                                    setAuditId(record.id);
                                    setModalVisible(true)
                                }}
                            />
                            <TableEditButton
                                permissions={[record.status === AuditStatus.Published ? Permission.EditAfterPublished : Permission.SaveAdvertisementAudit]}
                                userPermissions={user.permissions}
                                className="mr-16"
                                editUrl={`/${ADVERTISEMENT_PATH}/${MANAGE_ADVERTISEMENT_AUDIT_PATH}/${record.id}`}
                            />
                            <Button
                                iconPadding={false}
                                icon={"far fa-copy"}
                                className="mr-16"
                                onClick={() => {
                                    history.push(`/${ADVERTISEMENT_PATH}/${MANAGE_ADVERTISEMENT_AUDIT_PATH}?duplicateFromId=${record.id}`);
                                }}
                                ></Button>
                          <TableDeleteButton
                                permissions={[Permission.DeleteAdvertisementAudit]}
                                userPermissions={user.permissions}
                                onDelete={() => onDelete(record.id)}
                                onDeleted={() => updateTable()}
                          />
                        </div>
                    </span>
                )
            }
        ];

        const columnToSort: any = columns.find(c => c.dataIndex === filter.orderColumn);
        columnToSort.sortOrder = filter.orderDescending ? "descend" : "ascend";

        return columns;
    };
    const onTableChange = (pagination: any, filters: any, sorter: any) => {
        setSearchFilter({
            ...filter,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            orderColumn: sorter.field || "createdAt",
            orderDescending: sorter.order !== "ascend"
        } as AdvertisementAuditFilter);
    };

    return (
        <>
            <Table
                rowKey={record => record.id}
                dataSource={item.items}
                onChange={onTableChange}
                columns={getColumns()}
                loading={loading}
                pagination={{
                    total: item.totalItemCount,
                    pageSize: item.pageSize,
                    current: item.pageNumber,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30", "40"]
                }}
            />
            <AuditTableView
                name={"Reklama"}
                auditId={auditId}
                getAuditData={getAdvertisementAuditView}
                isModalVisible={isModalVisible}
                onCloseModal={() => setModalVisible(false)}
                childrenName={'sections'}
                nephewName={'items'}
                parentColumns={getParentColumns()}
                childColumns={getChildrenColumns()}
                nephewColumns={getNephewColumns()}
                getAuditReportExcel={getAdvertisementAuditReportExcel}
                auditTemplateName={"dashboard.advertisements.advertisementAuditTitle"}
                getAuditReportHtml={getAdvertisementAuditReportHtml}
                timeUnit={ReportTimeUnit.Seconds}
                entityName={"operatorName"}
                user={user}
            />
        </>
    );
};

export default AdvertisementAuditTable;
