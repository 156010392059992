import React from "react";
import classnames from "classnames";

import { useField, useFormikContext } from "formik";
import Select, { SelectProps } from "../components/Select";
import Error from "./FormItemError";
import FieldProps from "./FieldProps";
import Label from "../components/Label";

type FormiKSelectFieldProps = SelectProps & FieldProps;

const FormikSelectField: React.FC<FormiKSelectFieldProps> = ({
  onChange,
  ...props
}) => {
  const [field, meta] = useField(props.name);
  const context = useFormikContext();
  const showError = meta.touched && meta.error;
  const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;
  const id = props.id || props.name;

  const inputKeyDown = (e: any) => {
    const val = e.target.value;
    if (e.key === "Tab" && val && props.children && !Array.isArray(props.defaultValue)) {
      //@ts-ignore
      var value = props.children.filter((child: any) => {
        return (
          child != null &&
          child.props != null &&
          child.props.children != null &&
          child.props.children.toLowerCase().indexOf(val.toLowerCase()) >= 0
        );
      });
      if (value && value.length > 0)
        onChangeHandler(value[0].key, value[0].props);
    }
  };

  const onChangeHandler = (value: any, key: any): void => {
    context.setFieldValue(props.name, value);

    if (onChange) {
      onChange(value, key);
    }
  };

  const onBlurHandler = (e : any): void => {
    context.setFieldTouched(props.name);

    if(props.onBlur){
      props.onBlur(e);
    }
  };

  const inputClassname = classnames({ select_border_error: showError });

  return (
    <>
      <div className={"form-group"}>
        {props.label && (
          <Label htmlFor={props.id || props.name} className="formik-field__input-label no-select"> {props.label}</Label>
        )}
        <Select
          {...field}
          {...props}
          className={`${inputClassname} input__no-autofill-color ${props.className}`}
          id={id}
          filterOption={(input, option) =>
              option != null && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={onChangeHandler}
          onKeyDown={inputKeyDown}
          onBlur={onBlurHandler}>
          {props.children}
        </Select>
        {showError && (<div className="mt-4">{error}&nbsp;</div>)}
        {/*{showError && (<Tooltip title={error} defaultVisible={true}  placement="left" />)}*/}
      </div>
    </>
  );
};

export default FormikSelectField;
