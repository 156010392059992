import { adminHttpClient } from "../commons/adminHttpClient";
import { NamedItemInt } from "../commons/namedItem";

export interface PollForSaveDto {
  id: string | null;
  name: string;
  operatorId: string;
  subject: string;
  marginError: number;
  totalParticipants: number;
  startDate: string;
  endDate: string;
  availableOperators: NamedItemInt[];
}

export const getPollForSave = (id: string | undefined): Promise<any> => {
  if (id) {
    return adminHttpClient.get(`/Polls/GetForSave/${id}`);
  }
  return adminHttpClient.get(`/Polls/GetForSave`);
};

export const savePoll = (data: any): Promise<any> => {
  return adminHttpClient.post(`/Polls`, data);
};
