import React from "react";
import OrderedPageFilter from "core/application/commons/orderedPageFilter";
import PagedList from "core/application/commons/pagedList";
import {OperatorDto} from "core/application/operators/getOperators";
import {Modal} from "antd";
import {formatDateTimeReadable} from "../../../common/dateFormatters";
import TableEditButton from "../../../common/components/tables/TableEditButton";
import TableDeleteButton from "../../../common/components/tables/TableDeleteButton";
import Table from "common/components/dataDisplay/Table";
import {OPERATORS_PATH} from "../Operators";
import {MANAGE_OPERATOR_PATH} from "../ManageOperator";
import deleteOperator from "core/application/operators/deleteOperator";
import {CONFIGURATION_PATH} from "../../configurations/ConfigurationWrapper";
interface Props {
    setSearchFilter: (value: any) => void;
    filter: OrderedPageFilter;
    item: PagedList<OperatorDto>;
    history: any;
    translate: (key: string) => string;
    loading : boolean,
    updateTableDate : () => void
}

const OperatorsTable : React.FC<Props> = ({
                                              setSearchFilter,
                                              filter,
                                              item,
                                              history,
                                              translate,
                                              loading,
                                              updateTableDate,
}) =>{

    const { confirm } = Modal;

    const getColumns = (): any[] => {
        const columns = [
            {
                title: translate("general.name"),
                dataIndex: "name",
                key: "name",
                width: "30%",
                sorter: true,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => (
                    <div>
                        <span>{record.name}</span>
                    </div>
                )
            },
            {
                title: translate("dashboard.created_at"),
                dataIndex: "createdAt",
                key: "createdAt",
                responsive: ["md"],
                sorter: true,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => formatDateTimeReadable(record.createdAt)
            },
            {
                title: translate("dashboard.created_by"),
                dataIndex: "createdBy",
                key: "createdBy",
                responsive: ["md"],
                sorter: false,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record :any ) =>( <span>{record.createdBy}</span>)
            },
            {
                title: translate("dashboard.action"),
                width: "10%",
                dataIndex: "",
                key: "actions",
                align: "center",
                sorter: false,
                render: (text: string, record: any) => (
                    <span>
                        <div className="actions flex flex__center">
                            <TableEditButton
                                className="mr-16"
                                editUrl={`/${CONFIGURATION_PATH}/${OPERATORS_PATH}/${MANAGE_OPERATOR_PATH}/${record.id}`}
                            />
                          <TableDeleteButton
                              disableDeleted={record.children?.length > 0}
                              onDelete={() => deleteOperator(record.id)}
                              onDeleted={() => updateTableDate()}
                          />
                        </div>
                    </span>
                )
            }
        ];
        const columnToSort: any = columns.find(c => c.dataIndex === filter.orderColumn);
        columnToSort.sortOrder = filter.orderDescending ? "descend" : "ascend";
        return columns;
    };
    const onTableChange = (pagination: any, filters: any, sorter: any) => {
        setSearchFilter({
            ...filter,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            orderColumn: sorter.field || "createdAt",
            orderDescending: sorter.order !== "ascend"
        });
    };
    return (
        <>
            <Table
                dataSource={item.items}
                onChange={onTableChange}
                columns={getColumns()}
                loading={loading}
                pagination={{
                    total: item.totalItemCount,
                    pageSize: item.pageSize,
                    current: item.pageNumber,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30", "40"]
                }}
            />
        </>
    )
}

export default OperatorsTable;