import { adminHttpClient } from "../commons/adminHttpClient";

import { UserRoles as Role } from "./userRoles";
import { Permission } from "domain/users/permission";

export interface User {
  permissions: Permission[];
  roles: Role[];
  storeId: string;
  storeName: string;
  firstName: string;
  lastName: string;
  userId: string;
  currency: string;
  weightUnit: string;
  language: string;
  storeSubDomain: string;
  customDomain: string;
  isSetupCompleted: boolean
}

const getLoginUser = (): Promise<User> => {
  return adminHttpClient.get("/logininfo");
};

export default getLoginUser;
