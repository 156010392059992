import React, { useEffect, useState } from "react";
import Card from "../../common/components/dataDisplay/Card";
import { Col, notification, Row, Tabs } from "antd";
import Table from "common/components/dataDisplay/Table";
import Button from "../../common/components/general/Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ProgramType } from "core/application/programAudits/saveProgramAudit";
import { MANAGE_PROGRAMS_PATH } from "./ManageProgramsAudit";
import { useSelector } from "react-redux";
import PermissionButton from "common/components/general/PermissionButton";
import { Permission } from "core/domain/users/permission";
import InputSearch, { SearchValues } from "../common/InputSearch";
import PagedList from "core/application/commons/pagedList";
import {
  getProgramAudit,
  ProgramAudit
} from "core/application/programAudits/getProgramAudits";
import { queryRequest } from "../../common/RequestUtils";
import { SearchFilter } from "core/domain/common/searchFilter";
import { v4 as uuidv4 } from "uuid";
import {
  formatDataAsDashed,
  formatDateTimeReadable
} from "../../common/dateFormatters";
import AuditStatusTag from "../../common/components/dataDisplay/AuditStatusTag";
import TableEditButton from "../../common/components/tables/TableEditButton";
import { AuditStatus } from "core/domain/common/auditStatus";
import TableDeleteButton from "../../common/components/tables/TableDeleteButton";
import deleteProgramAudit from "core/application/programAudits/deleteProgramAudit";
import AuditTableView from "../common/AuditTableView";
import getProgramAuditView from "core/application/programAudits/getProgramAuditView";
import {
  getChildrenColumns,
  getNephewColumns,
  getParentColumns
} from "./tables/AuditColumns";
import getProgramAuditReportExcel from "core/application/programAudits/programAuditsReports";
import { ReportTimeUnit } from "core/domain/reports/reportTimeUnit";

export const PROGRAM_AUDIT_PATH = "program-audit";

const { TabPane } = Tabs;
const ProgramAudits: React.FC = () => {
  const [tabKey, setTabKey] = useState<string>(ProgramType.Live);
  let history = useHistory();
  const { t: translate } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [auditId, setAuditId] = useState(null);
  const userLoginInfo = useSelector((s: any) => s.userReducer);
  const [items, setItems] = useState<PagedList<ProgramAudit>>(
    {} as PagedList<ProgramAudit>
  );
  const [searchFilter, setSearchFilter] = useState<SearchFilter>({
    pageNumber: 1,
    pageSize: 20,
    orderColumn: "createdAt",
    orderDescending: true
  });
  const searchInitialValues = {
    inputSearch: searchFilter.searchValue
  } as SearchValues;

  useEffect(() => {
    (async () => {
      await updateTableDate();
    })();
  }, [searchFilter]);

  const updateTableDate = async () => {
    setLoading(true);
    setItems(await queryRequest(() => getProgramAudit(searchFilter)));
    setLoading(false);
  };

  const onSearch = (search: any) => {
    if (search.inputSearch && search.inputSearch?.length < 2) {
      notification.open({
        type: "error",
        message: translate("general.search_length")
      });
    } else {
      setSearchFilter({
        ...searchFilter,
        searchValue: search.inputSearch
      });
    }
  };
  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate("dashboard.programs.operatorName"),
        dataIndex: "operatorName",
        key: `operatorName_${uuidv4()}`,
        width: "20%",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => (
          <div>
            <span>{record.operatorName}</span>
          </div>
        )
      },
      {
        title: translate("general.date"),
        dataIndex: "date",
        key: `date_${uuidv4()}`,
        width: "20%",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => (
          <div>
            <span>{formatDataAsDashed(record.date)}</span>
          </div>
        )
      },
      {
        title: translate("dashboard.status"),
        dataIndex: "status",
        key: `status_${uuidv4()}`,
        responsive: ["md"],
        sorter: true,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => (
          <AuditStatusTag status={record.status} />
        )
      },
      {
        title: translate("dashboard.created_at"),
        dataIndex: "createdAt",
        key: `createdAt_${uuidv4()}`,
        responsive: ["md"],
        sorter: true,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) =>
          formatDateTimeReadable(record.createdAt)
      },
      {
        title: translate("dashboard.created_by"),
        dataIndex: "createdBy",
        key: `createdBy_${uuidv4()}`,
        responsive: ["md"],
        sorter: false,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => <span>{record.createdBy}</span>
      },
      {
        title: translate("dashboard.action"),
        width: "10%",
        dataIndex: "",
        key: `actions_${uuidv4()}`,
        align: "center",
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="actions flex flex__center">
              <PermissionButton
                permissions={[Permission.ViewProgramAudit]}
                userPermissions={userLoginInfo.user.permissions}
                className="mr-16"
                iconPadding={false}
                icon={"far fa-list"}
                onClick={() => {
                  setAuditId(record.id);
                  setModalVisible(true);
                }}
              />
              <TableEditButton
                permissions={[
                  record.status === AuditStatus.Published
                    ? Permission.EditAfterPublished
                    : Permission.SaveProgramAudit
                ]}
                userPermissions={userLoginInfo.user.permissions}
                className="mr-16"
                editUrl={`/${PROGRAM_AUDIT_PATH}/${MANAGE_PROGRAMS_PATH}/${record.id}`}
              />
              <TableDeleteButton
                permissions={[Permission.DeleteProgramAudit]}
                userPermissions={userLoginInfo.user.permissions}
                onDelete={() => deleteProgramAudit(record.id)}
                onDeleted={() => updateTableDate()}
              />
            </div>
          </span>
        )
      }
    ];

    const columnToSort: any = columns.find(
      c => c.dataIndex === searchFilter.orderColumn
    );
    columnToSort.sortOrder = searchFilter.orderDescending
      ? "descend"
      : "ascend";

    return columns;
  };
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setSearchFilter({
      ...searchFilter,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || "createdAt",
      orderDescending: sorter.order !== "ascend"
    });
  };
  return (
    <Card
      title={
        <Row>
          <Col xs={12} md={10}>
            <h4>{translate("dashboard.programs.programAudit")}</h4>
          </Col>
          <Col xs={12} md={11}>
            <InputSearch
                placeholder={'Operatori, Data(dd/mm/yyyy)'}
              initialValues={searchInitialValues}
              onSearch={onSearch}
              translate={translate}
              loading={loading}
            />
          </Col>
          <Col xs={12} md={3}>
            <div className={"text-right"}>
              <PermissionButton
                permissions={[Permission.SaveProgramAudit]}
                userPermissions={userLoginInfo.user.permissions}
                icon="far fa-plus"
                type="primary"
                onClick={() => {
                  history.push(
                    `${history.location.pathname}/${MANAGE_PROGRAMS_PATH}`
                  );
                }}
              >
                {translate("dashboard.add")}
              </PermissionButton>
            </div>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          <Table
            dataSource={items.items}
            onChange={onTableChange}
            columns={getColumns()}
            loading={loading}
            pagination={{
              total: items.totalItemCount,
              pageSize: items.pageSize,
              current: items.pageNumber,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "40"]
            }}
          />
          <AuditTableView
            name={"Emisione"}
            auditId={auditId}
            getAuditData={getProgramAuditView}
            isModalVisible={isModalVisible}
            onCloseModal={() => setModalVisible(false)}
            childrenName={"auditItems"}
            nephewName={"auditSubjects"}
            parentColumns={getParentColumns()}
            childColumns={getChildrenColumns()}
            nephewColumns={getNephewColumns()}
            getAuditReportExcel={getProgramAuditReportExcel}
            auditTemplateName={"dashboard.programs.programAudit"}
            timeUnit={ReportTimeUnit.Minutes}
            entityName={"operatorName"}
            user={userLoginInfo.user}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ProgramAudits;
