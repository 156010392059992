import { adminHttpClient } from "../commons/adminHttpClient";
import OrderedPageFilter from "../commons/orderedPageFilter";
import PagedList from "../commons/pagedList";

export interface BannedActivityDto {
  id: string | null;
  name: string;
  operatorName: string;
  subjectName: string;
  marginError: number;
  date: string;
  createdBy: string;
  createdAt: string;
}

export const getBannedActivities = (
  filter: OrderedPageFilter
): Promise<PagedList<BannedActivityDto>> =>
  adminHttpClient.get(`/BannedActivities/Get`, filter);
