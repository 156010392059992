import { adminHttpClient } from "../commons/adminHttpClient";

export interface PagedAdvertisementAudits {
    items: any[];
    totalItemCount: number;
    pageCount: number;
    pageNumber: number;
    pageSize: number;
}
export interface AdvertisementAuditFilter {
    createdAt?: string;
    searchValue?: string;
    pageNumber: number;
    pageSize: number;
    orderColumn: string;
    orderDescending: boolean;
}
const getAdvertisementAudit = (filter : AdvertisementAuditFilter) : Promise<any>  => adminHttpClient.get(`/advertisementAudits/get`, filter)

export default getAdvertisementAudit;