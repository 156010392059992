import {adminHttpClient} from "../commons/adminHttpClient";
import { AuditReportDto, AuditReportFilter } from "./auditableSubjectsReport";
import { AuditGroupBy } from "../../domain/reports/auditGroupBy";

export const generateNewsTickerAuditsReport = (filter : AuditReportFilter) : Promise<AuditReportDto> => {
    return adminHttpClient.post(`reports/newsTickerAudits/generate`, filter);
}

export const generateNewsTickerAuditsReportExcel = (filter : AuditReportFilter) : Promise<string> => {
    return adminHttpClient.post(`reports/newsTickerAudits/generateExcel`, filter);
}