import {formatDataAsDashed} from "../../../common/dateFormatters";

export const getParentColumns = () => {
    return [
        { title: 'Operatori', dataIndex: 'operatorName', key: 'operatorName' },
        { title: 'Data', dataIndex: 'date', key: 'date', render:(text: string, record : any) => (record !== undefined && formatDataAsDashed(record.date))},
        { title: 'Shënime', dataIndex: 'notes', key: 'notes' },
    ];
}

export const getChildrenColumns = () => {
    return [
        { title: 'Nr.', dataIndex: 'index', key: 'index', render:(text: string, record : any) => record.index  },
        { title: 'Koha', dataIndex: 'time', key: 'time' },
    ];
}

export const getNephewColumns = () => {
    return [
        { title: 'Reklama', dataIndex: 'subjectName', key: 'subjectName' },
        { title: 'Kohëzgjatja', dataIndex: 'duration', key: 'duration' },
        { title: 'Tipi', dataIndex: 'advertisementTypeName', key: 'advertisementTypeName' },
        { title: 'Shënime', dataIndex: 'notes', key: 'notes' },
    ];
}
