import React from "react";
import { Input } from "antd";

import "./DataEntry.less";

const { TextArea: AntTextArea } = Input;

interface TextAreaProps {
  name?: string;
  placeholder?: string;
  cols?: number;
  rows?: number;
  wrap?: string;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  className?: string;
  id?: string;
}

const TextArea: React.FC<TextAreaProps> = props => {
  return (
    <AntTextArea {...props} className={`round_border ${props.className}`} />
  );
};

export default TextArea;
