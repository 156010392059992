import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import shqip from "./translation/sq/dashboard.json";
// the translations
// (tip move them in a JSON file and import them)

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "sq",
    resources: {
      sq: {
        translation: shqip
      }
    },
    react: {
      useSuspense: false
    },
    fallbackLng: "sq",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
