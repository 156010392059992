import { adminHttpClient } from "../commons/adminHttpClient";

const getNewsAuditListReportExcel = (id: string): Promise<any> => {
  return adminHttpClient.get(`/NewsAudits/View/GenerateSummaryExcel/${id}`);
};

export const getNewsAuditReportExcel = (id: string): Promise<any> => {
  return adminHttpClient.get(`/NewsAudits/View/GenerateExcel/${id}`);
};

export default getNewsAuditListReportExcel;