import {adminHttpClient} from "../commons/adminHttpClient";
import PagedList from "../commons/pagedList";
import PagedFilter from "../commons/pagedFilter";

export interface AuditeesDto {
    id : string;
    name: string;
    gender:any;
    createdAt : string;
    createdBy : string;
}

const getAuditees = (filter : PagedFilter) : Promise<PagedList<AuditeesDto>> => {
    return adminHttpClient.get(`/Auditees/Get`, filter);
}

export default getAuditees;