import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import createUser, {CreateUserDto} from "core/application/users/createUser";
import {commandRequest} from "../../common/RequestUtils";
import notification from "../../common/components/feedback/Notification";
import {Form as FormikForm, Formik} from "formik";
import Card from "../../common/components/dataDisplay/Card";
import {Col, Row, Skeleton} from "antd";
import Input from "../../common/components/dataEntry/formik/FormikInputField";
import FormSaveCancelButtons from "../../common/components/forms/SaveCancelFormButtons";
import * as Yup from "yup";
import PasswordInput from "../../common/components/dataEntry/formik/FormikPasswordField";
import Select from "../../common/components/dataEntry/formik/FormikSelectField";
import {NamedItemInt} from "core/application/commons/namedItem";
import {Permission} from "core/domain/users/permission";
import { UserPermissions } from "core/application/users/updateUser";

export const CREATE_USER_PATH = 'create-user'
const formValidator = (translate: any) =>
    Yup.object({
        firstName: Yup.string()
            .required(translate("general.required"))
            .nullable()
            .typeError(translate("general.required")),
    });

const UserRoles : NamedItemInt[] = [
    {id: 0,  name: "SuperAdmin"},
    {id: 1,  name: "Admin"},
]

const CreateUser : React.FC = () => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const [item, setItem] = useState<CreateUserDto | []>({
        firstName:"",
        lastName:"",
        password:"",
        roles:[],
        username:"",
        userPermissions: []
    })
    const [loading, setLoading] = useState(false);

    const onSubmit = async (values : any) => {
        const result = await commandRequest(() => createUser(values))
        if (!result.errors) {
            notification.open({
                message: translate("general.saved").replace(
                    "[]",
                    translate("users.name")
                ),
                type: "success"
            });
            history.goBack();
        } else {
            notification.open({message: result.errors[0], type: "error"});
        }
    }
    return (
        <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 6, className: "p-64 color-gray-5" }}
            title={false}
        >
            {" "}
            {item && (
                <Formik
                    enableReinitialize={true}
                    initialValues={item}
                    validationSchema={formValidator(translate)}
                    onSubmit={() => {}}
                >
                    {formik => (
                        <div>
                            <Card
                                bordered={true}
                                title={id ? translate("users.edit"): translate("users.add")}
                            >
                                <FormikForm className={'container'}>
                                    <Row>
                                        <Col span={24}>
                                            <Input
                                                name="firstName"
                                                label={`Emri`}
                                                placeholder={`Emri`}
                                            >
                                            </Input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Input
                                                name="lastName"
                                                label={`Mbiemri`}
                                                placeholder={`Mbiemri`}
                                            >
                                            </Input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Input
                                                name="username"
                                                label={`Email`}
                                                placeholder={`Email`}
                                            >
                                            </Input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Select
                                                name="userPermissions"
                                                showSearch={true}
                                                label={`Privilegje`}
                                                placeholder={`Privilegje`}
                                                mode="multiple"
                                            >
                                                {UserPermissions.map((index) => (
                                                    <option key={index.id} value={index.id}>
                                                        {index.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24}>
                                            <PasswordInput
                                                label={"Fjalëkalimi"}
                                                placeholder={"Fjalëkalimi"}
                                                name="password"
                                                className={"mb-0 mt-0"}
                                                popover={true}
                                            />
                                        </Col>
                                    </Row>

                                </FormikForm>
                            </Card>
                            <div className={"text-center mb-16"}>
                                <FormSaveCancelButtons
                                    disabledSave={
                                        !formValidator(translate).isValidSync(formik.values)
                                    }
                                    onButtonClick={() => onSubmit(formik.values)}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    )}
                </Formik>
            )}
        </Skeleton>
    )
}

export default CreateUser