import React, { useContext, useState, useEffect, useRef } from 'react';

import InputNumber from "common/components/dataEntry/formik/FormikInputNumberField";
import Input from "common/components/dataEntry/formik/FormikInputField";
import Select from "common/components/dataEntry/formik/FormikSelectField";
import Button from "common/components/general/Button";
import Table from "common/components/dataDisplay/Table";
import {useFormikContext} from "formik";
import {NewsAuditForSave, NewsAuditSubjects, NewsAuditItems} from "core/application/newsAudits/getNewsAuditForSave";
import TextArea from "../../../common/components/dataEntry/formik/FormikTextAreaField";
import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";


const subjectRowValidator = (translate : any) =>
    Yup.object({
        auditableSubjectId:Yup.string().trim().required(translate("general.required")).nullable(),
    });
interface Props{
    auditSubject : NewsAuditSubjects[],
    auditItem: NewsAuditItems,
    datasource: NewsAuditForSave,
    name: string;
    translate : (key: string) => string;
    onRemoveAuditSubjectRow : any,
    onAddAuditSubjectRow : any,
    auditItemIndex: number,
    formik : any
}

const NewsAuditSubjectTableInputs : React.FC<Props> = ({
                                                    auditSubject,
                                                    auditItem,
                                                    auditItemIndex,
                                                    datasource,
                                                    name,
                                                    translate,
                                                    onRemoveAuditSubjectRow,
                                                    onAddAuditSubjectRow,
                                                    formik
                                                }) => {
    const { values, setFieldValue } = useFormikContext<NewsAuditForSave>();

    const addRow = (values : any, index : number) => {
        if (values.auditItems[auditItemIndex].auditSubjects.length -1 === index) {
            onAddAuditSubjectRow(values, auditItemIndex)
        }
    }
    const columns = [
        {
            title: "",
            dataIndex: 'index',
            key: `index`,
            width:"5%",
            render:(text: string, record: any, i: number) => (
                <span><b>{++i}</b></span>
            )
        },
        {
            title: <span style={{fontSize:"12px"}}>{translate("dashboard.news.auditSubject.subject")}</span>,
            dataIndex: 'auditableSubjectId',
            key: `auditableSubjectId`,
            width:"20%",
            render:(text: string, record: any, i: number) => (
                <Select
                    name={`${name}[${i}].auditableSubjectId`}
                    showSearch={true}
                    placeholder={translate("dashboard.news.auditSubject.subject")}
                    className={"mb-0"}
                    onBlur={() => addRow(values, i)}
                >
                    {datasource.availableAuditableSubjects.map((item : any) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </Select>
            )
        },
        {
            title: <span style={{fontSize:"12px"}}>{`${translate("dashboard.news.auditSubject.journalistTime")}(sek.)`}</span>,
            dataIndex: 'journalistTime',
            key: `journalistTime`,
            width:"5%",
            render:(text: string, record: any, i: number) =>(
                <InputNumber
                    name={`${name}[${i}].journalistTime`}
                    placeholder={translate("dashboard.news.auditSubject.journalistTime")}
                />
            )
        },
        {
            title: <span style={{fontSize:"12px"}}>{`${translate("dashboard.news.auditSubject.syncTime")}(sek.)`}</span>,
            dataIndex: 'syncTime',
            key: `syncTime`,
            width:"5%",
            render:(text: string, record: any, i: number) =>(
                <InputNumber
                    name={`${name}[${i}].syncTime`}
                    placeholder={translate("dashboard.news.auditSubject.syncTime")}
                />
            )
        },
        {
            title: <span style={{fontSize:"12px"}}>{`${translate("dashboard.news.auditSubject.totalTime")}(sek.)`}</span>,
            dataIndex: 'totalTime',
            key: `totalTime`,
            width:"5%",
            render:(text: string, record: any, i: number) =>(
                <InputNumber
                    name={`${name}[${i}].totalTime`}
                    placeholder={translate("dashboard.news.auditSubject.totalTime")}
                />
            )
        },
        {
            title: <span style={{fontSize:"12px"}}>{translate("dashboard.news.auditSubject.auditeeId")}</span>,
            dataIndex: 'auditeeId',
            key: `auditeeId`,
            sorter: false,
            width:"20%",
            editable: true,
            render:(text: string, record: any, i: number) => (
                <Select
                    name={`${name}[${i}].auditeeId`}
                    showSearch={true}
                    placeholder={translate("dashboard.news.auditSubject.auditeeId")}
                    className={"mb-0"}
                >
                    {datasource.availableAuditees.map((item : any) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </Select>
            )
        },
        {
            title: <span style={{fontSize:"12px"}}>{`${translate("dashboard.news.auditSubject.auditeeSyncTime")}(sek.)`}</span>,
            dataIndex: 'auditeeSyncTime',
            key: `auditeeSyncTime`,
            width:"10%",
            render:(text: string, record: any, i: number) =>(
                <InputNumber
                    name={`${name}[${i}].auditeeSyncTime`}
                    placeholder={translate("dashboard.news.auditSubject.auditeeSyncTime")}
                />
            )
        },
        {
            title: <span style={{fontSize:"12px"}}>{translate("dashboard.news.auditSubject.notes")}</span>,
            dataIndex: 'notes',
            width:"25%",
            key: `notes`,
            render:(text: string, record: any, i: number) =>(
                <TextArea
                    name={`${name}[${i}].notes`}
                    placeholder={translate("dashboard.news.audit.notes")}
                />
            )
        },
        {
            title:"",
            dataIndex: "actions",
            key: `actions`,
            width: "5%",
            render: (text: string, record: any, i : number) => (
                <div>
                    <Button
                        className={"error-button mb-0"}
                        icon="far fa-trash-alt"
                        onClick={(e) => {
                            onRemoveAuditSubjectRow(auditItemIndex, i ,values );
                        }}
                    />
                </div>
            )
        }

    ];
    return(
        <>
            <Table
                rowClassName={() => 'editable-row table-header-sm'}
                dataSource={auditSubject}
                columns={columns}
                pagination={false}
                bordered={false}
                size={"small"}
                rowKey={(record, key) => record.id ? record.id : key}
            />
        </>
    )
}

export default NewsAuditSubjectTableInputs;
