import { adminHttpClient } from "../commons/adminHttpClient";
import {AuditStatus} from "../../domain/common/auditStatus";

export enum ProgramType {
  Live = "0",
  Recorded = "1"
}

export interface ProgramForSave {
  id: string;
  date: Date;
  operatorId: string;
  operatorName: string;
  notes: string;
  status: AuditStatus,
  auditItems: any[];
  availableAuditItems: any[];
  availableOperators: any[];
  availableAuditableSubjects: any[];
  availableAuditees: any[];
}

export interface SaveProgram {
  id: string;
  date: Date;
  operatorId: string;
  notes: string;
  auditItems: AuditItems[];
  status: number;
}

export interface AuditItems {
  id: string | null;
  name: string;
  index?: string | number;
  startTime: string | null;
  endTime: string | null;
  auditSubjects: AuditSubjects[];
}

export interface AuditSubjects {
  id: string | null;
  index?: string | number;
  auditableSubjectId: string | null;
  auditeeId: string | null;
  decimal?: number;
  notes: string;
  duration: number;
}

export const getProgramForSave = (id: string | null): Promise<any> => {
  if (id) {
    return adminHttpClient.get(`/ProgramAudits/GetForSave/${id}`);
  }
  return adminHttpClient.get(`/ProgramAudits/GetForSave`);
};

const saveProgram = (data: SaveProgram): Promise<any> => {
  return adminHttpClient.post(`/ProgramAudits`, data);
};
export default saveProgram;
