import { NamedItemInt } from "application/commons/namedItem";

export enum NewsAuditItemSubjectTime {
    JournalistTime = 0,
    TotalTime = 1,
    SyncTime = 2
}

export const getNewsAuditItemSubjectTime = (translate: (key: string) => string): NamedItemInt[] => {
    var types: NamedItemInt[] = [];
    types.push({
        id: NewsAuditItemSubjectTime.JournalistTime,
        name: translate("dashboard.reports.journalist_time")
    });
    types.push({
      	id: NewsAuditItemSubjectTime.TotalTime,
      	name: translate("dashboard.reports.total_time")
    });
    types.push({
      	id: NewsAuditItemSubjectTime.SyncTime,
      	name: translate("dashboard.reports.sync_time")
    });
	return types;
};