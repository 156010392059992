import React, { useEffect, useState, useRef, ReactNode } from "react";
import { Modal, Card } from "antd";
import { queryRequest } from "../../common/RequestUtils";
import { downloadExcel } from "common/downloadFile";
import notification from "common/components/feedback/Notification";
import Button from "common/components/general/Button";
import { useTranslation } from "react-i18next";
import { User } from "core/application/account/loginUser";
import { Permission } from "core/domain/users/permission";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

import "./AuditTableView.less";
import Table from "common/components/dataDisplay/Table";
import { ReportTimeUnit } from "core/domain/reports/reportTimeUnit";

interface Props {
  name: string;
  auditId: string | null;
  getAuditData: any;
  isModalVisible: boolean;
  onCloseModal: any;
  childrenName: string;
  nephewName: string;
  parentColumns: any[];
  childColumns: any[];
  nephewColumns: any[];
  getAuditReportExcel?: any;
  auditTemplateName?: string;
  getSummaryReportExcel?: any;
  summaryTemplateName?: any;
  getAuditReportHtml?: any;
  user?: User;
  timeUnit: number;
  entityName: string;
}
const AuditTableView: React.FC<Props> = ({
  name,
  auditId,
  isModalVisible,
  onCloseModal,
  getAuditData,
  childrenName,
  nephewName,
  parentColumns,
  childColumns,
  nephewColumns,
  getAuditReportExcel,
  auditTemplateName,
  getSummaryReportExcel,
  summaryTemplateName,
  getAuditReportHtml,
  timeUnit,
  entityName,
  user
}) => {
  const [item, setItem] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { t: translate } = useTranslation();
  const componentRef = useRef(null);

  useEffect(() => {
    (async () => {
      if (auditId) {
        setLoading(true);
        setItem(await queryRequest(() => getAuditData(auditId)));
        setLoading(false);
      } else {
        setItem(null);
      }
    })();
  }, [auditId]);

  const onGenerateReportPdf = async () => {
    if (getAuditReportHtml) {
      var result = await getAuditReportHtml(auditId);
      let templateName = `AMA_Raport_${translate(
        `${auditTemplateName}`
      )}_${moment(item.date).format("DD/MM/YYYY")}.pdf`;
      //do something
      if (result.errors) {
        notification.open({
          message: result.errors.join(","),
          type: "error"
        });
      }
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const onGenerateReportExcel = async () => {
    if (getAuditReportExcel) {
      var result = await getAuditReportExcel(auditId);
      let templateName = `AMA_Raport_${translate(
        `${auditTemplateName}`
      )}_${moment(item.date).format("DD/MM/YYYY")}.xlsx`;
      downloadExcel(result, templateName);
      if (result.errors) {
        notification.open({
          message: result.errors.join(","),
          type: "error"
        });
      }
    }
  };

  const onGenerateSummaryReportExcel = async () => {
    if (getSummaryReportExcel) {
      var result = await getSummaryReportExcel(auditId);
      let templateName = `AMA_Raport_${translate(
        `${summaryTemplateName}`
      )}_${moment(item.date).format("DD/MM/YYYY")}.xlsx`;
      downloadExcel(result, templateName);
      if (result.errors) {
        notification.open({
          message: result.errors.join(","),
          type: "error"
        });
      }
    }
  };

  const getTitle = (item: any, timeUnit: number, name: string): ReactNode => {
    if (item)
      return [
        <div className="report_title">
          {translate("dashboard.reports.ama")}
        </div>,
        <div className="report_title">
          {`${name} ${item[entityName]} ${moment(item.date).format("DD/MM/YYYY")}`}
        </div>,
        <div className="report_title">{`${
          timeUnit === ReportTimeUnit.Minutes
            ? translate("dashboard.reports.time_in_minutes")
            : translate("dashboard.reports.time_in_seconds")
        }`}</div>
      ];
  };

  return (
    <Modal
      width={1200}
      title={`${name}`}
      visible={isModalVisible}
      footer={[]}
      onCancel={() => onCloseModal()}
    >
      {user &&
        (user.permissions.includes(Permission.Reports) ||
          user.permissions.includes(Permission.All)) && (
          <div>
            <Button
              type="primary"
              onClick={handlePrint}
              loading={loading}
              size="middle"
              className="mb-8 mr-8"
            >
              {translate("dashboard.reports.print")}
            </Button>
            {/* <Button
              type="primary"
              onClick={onGenerateReportPdf}
              loading={loading}
              size="middle"
              className="mb-8 mr-8"
            >
              {translate("dashboard.reports.pdf")}
            </Button> */}
            <Button
              type="primary"
              onClick={onGenerateReportExcel}
              loading={loading}
              size="middle"
              className="mb-8"
            >
              {translate("dashboard.reports.excel")}
            </Button>
            {getSummaryReportExcel && (
              <Button
                type="primary"
                onClick={onGenerateSummaryReportExcel}
                loading={loading}
                size="middle"
                className="ml-8 mb-8"
              >
                {translate("dashboard.reports.excel_summary")}
              </Button>
            )}
          </div>
        )}

      <Card ref={componentRef} bordered={false} title={getTitle(item, timeUnit, name)}>
        <Table
          rowKey={record => record.id}
          loading={loading}
          className="components-table-demo-nested"
          columns={parentColumns}
          dataSource={item ? [item] : []}
          pagination={false}
          expandable={{ defaultExpandAllRows: true }}
          expandedRowKeys={item ? [item.id] : []}
          expandedRowRender={record => (
            <Table
              rowKey={record => record.id}
              columns={childColumns}
              dataSource={record[childrenName]}
              pagination={false}
              expandable={{
                defaultExpandAllRows: true,
                rowExpandable: record => record[nephewName] !== undefined
              }}
              expandedRowRender={record => (
                <Table
                  rowKey={record => record.id}
                  columns={nephewColumns}
                  dataSource={record[nephewName]}
                  pagination={false}
                />
              )}
            />
          )}
        />
      </Card>
    </Modal>
  );
};

export default AuditTableView;
