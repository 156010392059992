import React from "react";
import { Tag } from "antd";
import {useTranslation} from "react-i18next";
import {AuditStatus} from "core/domain/common/auditStatus";

interface Props {
    status: number,
}

const Display = (props: Props) => {
    const {t: translate} = useTranslation();

    return props.status === AuditStatus.Published
        ? (<Tag color="green">{translate("general.published")}</Tag>)
        : (<Tag color="red">{translate("general.draft")}</Tag>)
};

export default Display;
