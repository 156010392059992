import {adminHttpClient} from "../commons/adminHttpClient";
import { AuditReportDto, AuditReportFilter } from "./auditableSubjectsReport";
import { AuditGroupBy } from "../../domain/reports/auditGroupBy";

export interface LiveBroadcastAuditReportFilter extends AuditReportFilter {
    groupBy: AuditGroupBy;
}

export const generateLiveBroadcastAuditsReport = (filter : LiveBroadcastAuditReportFilter) : Promise<AuditReportDto> => {
    return adminHttpClient.post(`reports/liveBroadcastAudits/generate`, filter);
}

export const generateLiveBroadcastAuditsReportExcel = (filter : LiveBroadcastAuditReportFilter) : Promise<string> => {
    return adminHttpClient.post(`reports/liveBroadcastAudits/generateExcel`, filter);
}