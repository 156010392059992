import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PagedList from "core/application/commons/pagedList";
import {queryRequest} from "../../../common/RequestUtils";
import {formatDateTimeReadable} from "../../../common/dateFormatters";
import TableEditButton from "../../../common/components/tables/TableEditButton";
import {CONFIGURATION_PATH} from "../ConfigurationWrapper";
import TableDeleteButton from "../../../common/components/tables/TableDeleteButton";
import {Col, notification, Row} from "antd";
import Card from "../../../common/components/dataDisplay/Card";
import Button from "../../../common/components/general/Button";
import Table from "common/components/dataDisplay/Table";
import {MANAGE_NEWS_TOPICS} from "./ManageNewsTopics";
import deleteNewsTopics from "core/application/newsTopics/deleteNewsTopics";
import getNewsTopics, {NewsTopicsDto} from "core/application/newsTopics/getNewsTopics";
import InputSearch, {SearchValues} from "../../common/InputSearch";
import {SearchFilter} from "core/domain/common/searchFilter";

export const NEWS_TOPICS_PATH = "news-topics";


const NewsTopics: React.FC = () => {

    let history = useHistory();
    const {t: translate} = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [item, setItems] = useState<PagedList<NewsTopicsDto>>({} as PagedList<NewsTopicsDto>);
    const [searchFilter, setSearchFilter] = useState<SearchFilter>({
        pageNumber: 1,
        pageSize: 20,
        orderColumn: "createdAt",
        orderDescending: true
    });
    const searchInitialValues = {inputSearch: searchFilter.searchValue} as SearchValues;

    useEffect(() => {
        (async () => {
            await updateTableDate();
        })();
    }, [searchFilter])

    const updateTableDate = async () => {
        setLoading(true);
        setItems(await queryRequest(() => getNewsTopics(searchFilter)))
        setLoading(false);
    }
    const getColumns = (): any[] => {
        const columns = [
            {
                title: translate("general.name"),
                dataIndex: "name",
                key: "name",
                width: "30%",
                sorter: true,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => (
                    <div>
                        <span>{record.name}</span>
                    </div>
                )
            },
            {
                title: translate("dashboard.created_at"),
                dataIndex: "createdAt",
                key: "createdAt",
                responsive: ["md"],
                sorter: true,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => formatDateTimeReadable(record.createdAt)
            },
            {
                title: translate("dashboard.created_by"),
                dataIndex: "createdBy",
                key: "createdBy",
                responsive: ["md"],
                sorter: false,
                sortDirections: ["descend", "ascend"],
                render: (text: string, record: any) => (<span>{record.createdBy}</span>)
            },
            {
                title: translate("dashboard.action"),
                width: "10%",
                dataIndex: "",
                key: "actions",
                align: "center",
                sorter: false,
                render: (text: string, record: any) => (
                    <span>
                        <div className="actions flex flex__center">
                            <TableEditButton
                                className="mr-16"
                                editUrl={`/${CONFIGURATION_PATH}/${NEWS_TOPICS_PATH}/${MANAGE_NEWS_TOPICS}/${record.id}`}
                            />
                          <TableDeleteButton
                              disableDeleted={record.children?.length > 0}
                              onDelete={() => deleteNewsTopics(record.id)}
                              onDeleted={() => updateTableDate()}
                          />
                        </div>
                    </span>
                )
            }
        ];
        const columnToSort: any = columns.find(c => c.dataIndex === searchFilter.orderColumn);
        columnToSort.sortOrder = searchFilter.orderDescending ? "descend" : "ascend";
        return columns;
    };
    const onTableChange = (pagination: any, filters: any, sorter: any) => {
        setSearchFilter({
            ...searchFilter,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            orderColumn: sorter.field || "createdAt",
            orderDescending: sorter.order !== "ascend"
        });
    };
    const onSearch =  (search : any) => {
        if (search.inputSearch && search.inputSearch?.length < 2) {
            notification.open({type: "error", message: translate("general.search_length")});
        } else {
            setSearchFilter({
                ...searchFilter,
                searchValue: search.inputSearch,
            });
        }
    }
    return (
        <Card title={
            <Row>
                <Col xs={12} md={10}>
                    <h4>{translate("dashboard.newsTopic.name")}</h4>
                </Col>
                <Col xs={12} md={11}>
                    <InputSearch
                        placeholder={"Emri"}
                        initialValues={searchInitialValues}
                        onSearch={onSearch}
                        translate={translate}
                        loading={loading}
                    />
                </Col>
                <Col xs={12} md={3}>
                    <div className={"text-right"}>
                        <Button
                            icon="far fa-plus"
                            type="primary"
                            onClick={() => {
                                history.push(
                                    `${history.location.pathname}/${MANAGE_NEWS_TOPICS}`
                                );
                            }}>
                            {translate("dashboard.add")}
                        </Button>
                    </div>
                </Col>
            </Row>
        }>
            <Row>
                <Col span={24}>
                    <Table
                        dataSource={item.items || []}
                        onChange={onTableChange}
                        columns={getColumns()}
                        loading={loading}
                        pagination={{
                            total: item.totalItemCount,
                            pageSize: item.pageSize,
                            current: item.pageNumber,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "20", "30", "40"]
                        }}
                    />
                </Col>
            </Row>

        </Card>
    )
}

export default NewsTopics;

