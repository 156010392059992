import React, {ReactElement, useEffect, useState} from 'react'
import {useHistory, useLocation} from "react-router-dom";
import {Row, Col, notification} from "antd";
import {useTranslation} from "react-i18next";

import Button from "../../common/components/general/Button";
import Card from "../../common/components/dataDisplay/Card";

import getAdvertisementAudit from "core/application/advertisementAudits/getAdvertisementAudits";
import { PagedAdvertisementAudits } from "core/application/advertisementAudits/getAdvertisementAudits";
import deleteAdvertisementAudit from "core/application/advertisementAudits/deleteAdvertisementAudit";

import {queryRequest} from "../../common/RequestUtils";
import AdvertisementAuditTable from "./tables/AdvertisementAuditTable";
import {MANAGE_ADVERTISEMENT_AUDIT_PATH} from "./ManageAdvertisementAudit";
import AuditTableView from "../common/AuditTableView";
import { useSelector } from 'react-redux';
import PermissionButton from 'common/components/general/PermissionButton';
import { Permission } from "core/domain/users/permission";
import InputSearch, {SearchValues} from "../common/InputSearch";
import {SearchFilter} from "core/domain/common/searchFilter";

export const ADVERTISEMENT_PATH = 'advertisement-audit';


const AdvertisementAudit: React.FC = () => {
    let history = useHistory();
    const {t: translate} = useTranslation();
    const userLoginInfo = useSelector((s: any) => s.userReducer);
    const [loading , setLoading] = useState<boolean>(false);
    const [items , setItems] = useState<PagedAdvertisementAudits>({} as PagedAdvertisementAudits);
    const [searchFilter, setSearchFilter] = useState<SearchFilter>(
         {pageNumber: 1, pageSize: 20, orderColumn: "createdAt", orderDescending: true}
    );
    const searchInitialValues = {inputSearch: searchFilter.searchValue} as SearchValues;

    useEffect(() => {
        (async() => {
            await updateTableDate();
        })();
    }, [searchFilter])

    const updateTableDate = async () =>{
        setLoading(true);
        setItems(await queryRequest(() => getAdvertisementAudit(searchFilter)))
        setLoading(false);
    }

    const onSearch =  (search : any) => {
        if (search.inputSearch && search.inputSearch?.length < 2) {
            notification.open({type: "error", message: translate("general.search_length")});
        } else {
            setSearchFilter({
                ...searchFilter,
                searchValue: search.inputSearch,
            });
        }
    }
    return (
        <>
          <Card title={
              <Row>
                  <Col  xs={12} md={10}>
                      <h4>{translate("dashboard.advertisements.advertisementAuditTitle")}</h4>
                  </Col>
                  <Col xs={12} md={11}>
                      <InputSearch
                          initialValues={searchInitialValues}
                          onSearch={onSearch}
                          translate={translate}
                          loading={loading}
                          placeholder={'Operatori, Data(dd/mm/yyyy)'}
                      />
                  </Col>
                  <Col xs={12} md={3}>
                      <div className={"text-right"}>
                          <PermissionButton
                            permissions={[Permission.SaveAdvertisementAudit]}
                            userPermissions={userLoginInfo.user.permissions}
                              icon="far fa-plus"
                              type="primary"
                              onClick={() => {
                                  history.push(
                                      `${history.location.pathname}/${MANAGE_ADVERTISEMENT_AUDIT_PATH}`
                                  );
                              }}>
                              {translate("dashboard.add")}
                          </PermissionButton>
                      </div>
                  </Col>
              </Row>
          }>
              <Row>
                  <Col span={24}>
                    <AdvertisementAuditTable
                        onDelete={deleteAdvertisementAudit}
                        updateTable={updateTableDate}
                        user={userLoginInfo.user}
                        setSearchFilter={setSearchFilter}
                        filter={searchFilter}
                        item={items}
                        history={history}
                        translate={translate}
                        loading={loading}
                    />
                  </Col>
              </Row>

          </Card>
        </>
    )
}

export default AdvertisementAudit;
