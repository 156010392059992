import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import Users from "./Users";

import NotFound from "../../errorPages/NotFound";
import CreateUser, {CREATE_USER_PATH} from "./CreateUser";
import UpdateUser, {UPDATE_USER_PATH} from "./UpdateUser";
import ChangePasswordForUser, {CHANGE_PASSWORD_FOR_USER_PATH} from "./ChangePasswordForUser";

const Routes: React.FC = () => {
    let match = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${match.path}/`} component={Users} />
            <Route path={`${match.path}/${CREATE_USER_PATH}`} component={CreateUser} />
            <Route path={`${match.path}/${UPDATE_USER_PATH}/:id`} component={UpdateUser} />
            <Route path={`${match.path}/${CHANGE_PASSWORD_FOR_USER_PATH}/:id`} component={ChangePasswordForUser} />
            <Route component={NotFound} />
        </Switch>
    );
};

export default Routes;