import React, {useState, useEffect} from "react";
import {Menu, Layout as AntLayout, Row, Col, Card} from "antd";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {OPERATORS_PATH} from "../operators/Operators";
import {ADVERTISEMENT_PATH} from "./advertisements/Advertisement";
import {INDUSTRY_PATH} from "./industries/Industry";
import {NEWS_EDITION_PATH} from "./newsEditions/NewsEdition";
import {ADVERTISEMENT_TYPE_PATH} from "./advertisementTypes/AdvertisementType";
import {NEWS_TOPICS_PATH} from "./newsTopics/NewsTopics";
import {AVAILABLE_SUBJECT_PATH} from "./subjects/AvailableSubject";
import {AUDITEE_PATH} from "./auditees/Auditee";
import {PROGRAM_PATH} from "./programs/Program";
import {Permission} from "core/domain/users/permission";
import {User} from "core/application/account/loginUser";
import {useSelector} from "react-redux";

export const CONFIGURATION_PATH = "configurations";

const ConfigurationWrapper: React.FC = ({children}) => {
    const history = useHistory();
    let location = useLocation();
    const {t: translate} = useTranslation();

    const [activeKey, setActiveKey] = useState();
    const userLoginInfo = useSelector((s: any) => s.userReducer);

    useEffect(() => {
        let pathname = location.pathname;
        const split = pathname.split("/")[2];
        setActiveKey(split ? [split.toString()] : []);
    }, [location]);

    const goTo = (path: string) => {
        history.push(`/${path}`);
    };

    const filterMenuItemBasedOnPermissions = (
        permission: Permission,
        user: User
    ) => {
        return (
            user.permissions.includes(Permission.All) ||
            user.permissions.includes(permission)
        );
    };
    return (
        <Card title={translate("dashboard.configurations.name")}>
            <Row>
                <Col xs={24} md={6}>
                    <Menu
                        selectedKeys={activeKey}
                        mode="inline"
                        style={{borderRight: "0px"}}
                    >
                        {filterMenuItemBasedOnPermissions(
                            Permission.AdvertisementTypes,
                            userLoginInfo.user
                        ) && (
                            <Menu.Item
                                key={ADVERTISEMENT_TYPE_PATH}
                                onClick={() =>
                                    goTo(`${CONFIGURATION_PATH}/${ADVERTISEMENT_TYPE_PATH}`)
                                }
                            >
                                {"Tipe reklamash"}
                            </Menu.Item>
                        )}
                        {filterMenuItemBasedOnPermissions(
                            Permission.AuditableSubjects,
                            userLoginInfo.user
                        ) && (
                            <Menu.Item
                                key={AVAILABLE_SUBJECT_PATH}
                                onClick={() =>
                                    goTo(`${CONFIGURATION_PATH}/${AVAILABLE_SUBJECT_PATH}`)
                                }
                            >
                                {"Subjekte"}
                            </Menu.Item>
                        )}
                        {filterMenuItemBasedOnPermissions(
                            Permission.Auditees,
                            userLoginInfo.user
                        ) && (
                            <Menu.Item
                                key={AUDITEE_PATH}
                                onClick={() => goTo(`${CONFIGURATION_PATH}/${AUDITEE_PATH}`)}
                            >
                                {"Aktorë"}
                            </Menu.Item>
                        )}
                        {filterMenuItemBasedOnPermissions(
                            Permission.Industries,
                            userLoginInfo.user
                        ) && (
                            <Menu.Item
                                key={INDUSTRY_PATH}
                                onClick={() => goTo(`${CONFIGURATION_PATH}/${INDUSTRY_PATH}`)}
                            >
                                {translate("dashboard.industries.name")}
                            </Menu.Item>
                        )}
                        {filterMenuItemBasedOnPermissions(
                            Permission.NewsEditions,
                            userLoginInfo.user
                        ) && (
                            <Menu.Item
                                key={NEWS_EDITION_PATH}
                                onClick={() =>
                                    goTo(`${CONFIGURATION_PATH}/${NEWS_EDITION_PATH}`)
                                }
                            >
                                {translate("dashboard.newsEdition.name")}
                            </Menu.Item>
                        )}
                        {filterMenuItemBasedOnPermissions(
                            Permission.NewsTopics,
                            userLoginInfo.user
                        ) && (
                            <Menu.Item
                                key={NEWS_TOPICS_PATH}
                                onClick={() =>
                                    goTo(`${CONFIGURATION_PATH}/${NEWS_TOPICS_PATH}`)
                                }
                            >
                                {"Tema Lajmesh"}
                            </Menu.Item>
                        )}
                        {filterMenuItemBasedOnPermissions(
                            Permission.Operators,
                            userLoginInfo.user
                        ) && (
                            <Menu.Item
                                key={OPERATORS_PATH}
                                onClick={() => goTo(`${CONFIGURATION_PATH}/${OPERATORS_PATH}`)}
                            >
                                {translate("dashboard.operators.operator")}
                            </Menu.Item>
                        )}
                    </Menu>
                </Col>
                <Col xs={24} md={18}>
                    <div>{children}</div>
                </Col>
            </Row>
        </Card>
    );
};

export default ConfigurationWrapper;
