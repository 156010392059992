import React from "react";
import {AuditItems, LiveBroadcastForSave} from "core/application/liveBroadcastAudit/saveLiveBroadcastAudit";
import Input from "common/components/dataEntry/formik/FormikInputField"
import Button from "../../../common/components/general/Button";
import {Modal} from "antd";
import AuditSubjectsTableInput from "./AuditSubjectsTableInput";
import InputMask from "../../../common/components/dataEntry/formik/FormikInputMaskField";
import Table from "common/components/dataDisplay/Table";


interface Props {
    auditItems: AuditItems[],
    datasource: LiveBroadcastForSave,
    name: string;
    translate : (key: string) => string;
    onRemoveAuditItemRow : any,
    onAddAuditSubjectRow : any,
    onAddAuditItem : any,
    onRemoveAuditSubjectRow : any,
    onChangeSubject : any,
    formik: any
}
const {confirm} = Modal;
const AuditItemsTableInput : React.FC<Props> = ({
                                    auditItems,
                                    datasource,
                                    name,
                                    translate,
                                    onRemoveAuditItemRow,
                                                    onAddAuditItem,
                                    onAddAuditSubjectRow,
                                    onRemoveAuditSubjectRow,
                                    onChangeSubject,
                                    formik
                            }) =>{
    const addRow = (values :any, index : number) => {
        if (values.auditItems.length - 1  == index)
            onAddAuditItem(values);
    }

    const resolveIndex = (i : number) => {
        let x = i;
        return ++x;
    }
    const columns = [
        {
            title: "",
            dataIndex: 'index',
            key: `index`,
            width:"5%",
            render:(text: string, record: any, i: number) => (
                <div>
                <span><b>{++i}</b></span>
                </div>
            )
        },
        {
            title: translate("dashboard.liveBroadcast.auditItems.location"),
            dataIndex: 'name',
            key: "name",
            sorter: false,
            width:"10%",
            editable: true,
            render:(text: string, record: any, i: number) => (
                <Input
                    onBlur={() => addRow(formik.values, i)}
                    name={`${name}[${i}].name`} placeholder={`${translate("dashboard.liveBroadcast.auditItems.location")}`} />
            )
        },
        {
            title: translate("dashboard.liveBroadcast.auditItems.startTime"),
            dataIndex: 'startTime',
            key: "startTime",
            width: "10%",
            sorter: false,
            editable: true,
            render:(text: string, record: any, i: number) => (
                <InputMask
                    mask="11:11"
                    name={`${name}[${i}].startTime`}
                    placeholder={"00:00"}
                />
            )
        },
        {
            title: translate("dashboard.liveBroadcast.auditItems.endTime"),
            dataIndex: 'endTime',
            key: "endTime",
            width: "10%",
            sorter: false,
            editable: true,
            render:(text: string, record: any, i: number) => (
                <InputMask
                    mask="11:11"
                    name={`${name}[${i}].endTime`}
                    placeholder={"00:00"}
                />
            )
        },
        {
            title: "",
            dataIndex: "auditSubjects",
            width: "65%",
            key: "auditSubjects",
            render: (text: string, record: any, index :number) => (
                <div>
                    <AuditSubjectsTableInput
                        auditSubjects={auditItems.length ? auditItems[index].auditSubjects : []}
                        datasource={datasource}
                        name={`${name}[${index}].auditSubjects`}
                        translate={translate}
                        onRemoveAuditItemRow={onRemoveAuditItemRow}
                        onAddAuditSubjectRow={onAddAuditSubjectRow}
                        onAddAuditItem={onAddAuditItem}
                        onRemoveAuditSubjectRow={onRemoveAuditSubjectRow}
                        auditItemIndex={index}
                        onChangeSubject={onChangeSubject}
                        formik={formik}
                     />
                    <Button className={"mt-8"} type="primary" onClick={() => onAddAuditSubjectRow(formik.values, index)}>
                        {translate("general.add")}
                    </Button>
                </div>
            )
        },
        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            width: "5%",
            render: (text: string, record: any, i : number) => (
                <div>
                    <Button
                        className={"error-button mb-0"}
                        icon="far fa-trash-alt"
                        onClick={() => {
                            confirm({
                                title: translate("general.delete_alert_title"),
                                content: translate("general.delete_alert_content"),
                                okText: translate("general.ok_text"),
                                onOk : (()=> onRemoveAuditItemRow(formik.values, i))
                            })
                        }}
                    />
                </div>
            )
        }

    ];
    return (
        <Table
            dataSource={auditItems}
            columns={columns}
            pagination={false}
        />
    )
}

export default AuditItemsTableInput
