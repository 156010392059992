import React, { Dispatch, useEffect } from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";

import Routes from "./Routes";

import {
  fetchUser,
  UserAction
} from "core/application/account/updateUserInStore/userAction";

interface AppProps {
  onFetchUser: () => void;
}

const App: React.FC<AppProps> = ({ onFetchUser }) => {
  useEffect(() => {
    onFetchUser();
  }, []);

  return <Routes />;
};

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, UserAction>) {
  return {
    onFetchUser: () => dispatch(fetchUser())
  };
}

export default connect(null, mapDispatchToProps)(App);
