import React, { useState, useRef, useEffect, ReactNode } from "react";
import { Skeleton } from "antd";
import { Card as AntdCard } from "antd";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";

import Card from "common/components/dataDisplay/Card";
import DatePicker from "common/components/dataEntry/components/DatePicker";
import Button from "common/components/general/Button";
import Select from "common/components/dataEntry/components/Select";

import {
  getReportTimeUnits,
  ReportTimeUnit
} from "core/domain/reports/reportTimeUnit";
import {
  generateOperatorAuditsReport,
  OperatorAuditReportFilter,
  generateOperatorAuditsReportExcel,
  OperatorReportDto,
  OperatorAuditReportDto
} from "core/application/reports/operatorAuditsReport";

import "../Reports.less";
import { queryRequest } from "common/RequestUtils";
import { getAuditableSubjectTypes } from "core/domain/reports/auditableSubjectType";
import { downloadExcel } from "common/downloadFile";
import notification from "common/components/feedback/Notification";
import { useReactToPrint } from "react-to-print";
import { getAvailableOperators } from "core/application/operators/getOperators";
import { NamedItemInt } from "core/application/commons/namedItem";
import OperatorAuditReportTable from "./OperatorAuditReportTable";
import jsPDF from "jspdf";

const OperatorAuditReport: React.FC = () => {
  const { t: translate } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [date, setDate] = useState<string>();
  const [operatorId, setOperatorId] = useState<string>();
  const [timeUnit, setTimeUnit] = useState<number>();
  const [subjectTypes, setSubjectTypes] = useState<number[]>();
  const [operatorAuditsReport, setOperatorAuditsReport] = useState<
    OperatorReportDto
  >();
  const [availableOperators, setAvailableOperators] = useState<NamedItemInt[]>(
    []
  );
  const componentRef = useRef(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setAvailableOperators(await queryRequest(() => getAvailableOperators()));
      setLoading(false);
    })();
  }, []);

  const onDateChange = (date: Moment | null, formatString: string) => {
    setDate(formatString ?? null);
    setOperatorAuditsReport(undefined);
  };

  const onOperatorChange = (operatorId: any) => {
    setOperatorId(operatorId);
    setOperatorAuditsReport(undefined);
  };

  const onTimeUnitChange = (timeUnit: string | number[] | string[]) => {
    setTimeUnit(+timeUnit);
    setOperatorAuditsReport(undefined);
  };

  const onSubjectTypesChange = (types: any) => {
    setSubjectTypes(types);
    setOperatorAuditsReport(undefined);
  };

  const onGenerateReport = async () => {
    if (date && timeUnit != null && operatorId != null) {
      setLoading(true);
      setOperatorAuditsReport(
        await queryRequest(() =>
          generateOperatorAuditsReport({
            date: date,
            operatorId: operatorId,
            minutes: timeUnit === ReportTimeUnit.Minutes,
            auditableSubjectTypes: subjectTypes
          } as OperatorAuditReportFilter)
        )
      );
      setLoading(false);
    }
  };

  const onGenerateReportExcel = async () => {
    if (date && timeUnit != null && operatorId != null) {
      var result = await queryRequest(() =>
        generateOperatorAuditsReportExcel({
          date: date,
          operatorId: operatorId,
          minutes: timeUnit === ReportTimeUnit.Minutes,
          auditableSubjectTypes: subjectTypes
        } as OperatorAuditReportFilter)
      );
      let templateName = `AMA_Raport_Operator_${date}.xlsx`;
      downloadExcel(result, templateName);
      if (result.errors) {
        notification.open({
          message: result.errors.join(","),
          type: "error"
        });
      }
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle:
      "@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin: 200mm !important }}"
  });

  const getTitle = (report: OperatorReportDto): ReactNode => {
    return [
      <div className="report_title">{translate("dashboard.reports.ama")}</div>,
      <div className="report_title">
        {`${report.operatorName} - ${moment(date).format("DD/MM/YYYY")}`}
      </div>,
      <div className="report_title">{`${
        timeUnit === ReportTimeUnit.Minutes
          ? translate("dashboard.reports.time_in_minutes")
          : translate("dashboard.reports.time_in_seconds")
      }`}</div>
    ];
  };

  return (
    <Card>
      <div>
        <div className="flex flex__wrap flex__space_between">
          <div className="width_20">
            <DatePicker
              placeholder={translate("dashboard.reports.date")}
              className="full_width"
              disabledDate={d => !d || d.isAfter(moment().subtract(0, "day"))}
              onChange={onDateChange}
            />
          </div>
          <div className="width_20">
            <Select
              placeholder={translate("dashboard.reports.choose_operator")}
              showArrow={true}
              showSearch={true}
              onChange={onOperatorChange}
              filterOption={(input, option) =>
                option != null &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {availableOperators.map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="width_20">
            <Select
              placeholder={translate(
                "dashboard.reports.choose_auditable_subject_type"
              )}
              mode="multiple"
              showArrow={true}
              onChange={onSubjectTypesChange}
            >
              {getAuditableSubjectTypes(translate).map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="width_20">
            <Select
              placeholder={translate("dashboard.reports.time_unit")}
              showArrow={true}
              onChange={onTimeUnitChange}
            >
              {getReportTimeUnits(translate).map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>

          <div>
            <Button
              type="primary"
              onClick={onGenerateReport}
              loading={loading}
              size="large"
            >
              {translate("dashboard.reports.generate_report")}
            </Button>
          </div>
        </div>
      </div>
      <Skeleton active loading={loading}>
        <div className="mt-8">
          {operatorAuditsReport && (
            <>
              <AntdCard
                bordered={false}
                ref={componentRef}
                title={getTitle(operatorAuditsReport)}
              >
                {operatorAuditsReport.audits.map(audit => (
                  <OperatorAuditReportTable
                    minutes={timeUnit === ReportTimeUnit.Minutes}
                    entityName={audit.malePercentage ? "Aktori" : "Subjekti"}
                    auditReport={audit}
                    translate={translate}
                    loading={loading}
                  />
                ))}
              </AntdCard>
              <div>
                <Button
                  type="primary"
                  onClick={handlePrint}
                  loading={loading}
                  size="large"
                  className="mr-4"
                >
                  {translate("dashboard.reports.print")}
                </Button>
                <Button
                  type="primary"
                  onClick={onGenerateReportExcel}
                  loading={loading}
                  size="large"
                  className="mr-4"
                >
                  {translate("dashboard.reports.excel")}
                </Button>
              </div>
            </>
          )}
        </div>
      </Skeleton>
    </Card>
  );
};

export default OperatorAuditReport;
