import React, {useEffect} from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import Operators, {OPERATORS_PATH} from "./Operators";
import ManageOperator from "./ManageOperator";
import {MANAGE_OPERATOR_PATH} from "./ManageOperator";
import NotFound from "../../errorPages/NotFound";
import {CONFIGURATION_PATH} from "../configurations/ConfigurationWrapper";

const Routes: React.FC = () => {
    let match = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${match.path}/`} component={Operators} />
            <Route path={`${match.path}/${MANAGE_OPERATOR_PATH}/:id?`} component={ManageOperator} />
            <Route component={NotFound} />
        </Switch>
    );
};

export default Routes;
