import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import Card from "../../common/components/dataDisplay/Card";
import {Col, Row, Skeleton} from "antd";
import {Form as FormikForm, Formik} from "formik";
import Select from "../../common/components/dataEntry/formik/FormikSelectField";
import Input from "common/components/dataEntry/formik/FormikInputField"
import FormSaveCancelButtons from "../../common/components/forms/SaveCancelFormButtons";
import * as Yup from "yup";
import {commandRequest, queryRequest} from "../../common/RequestUtils";
import getOperatorForSave from "core/application/operators/saveOperator";
import {OperatorTypeMap} from "core/application/operators/getOperators";
import {saveOperator} from "core/application/operators/saveOperator";
import notification from "../../common/components/feedback/Notification";

export const MANAGE_OPERATOR_PATH = 'manage-operator';

const formValidator = (translate: any) =>
    Yup.object({
        name: Yup.string()
            .required(translate("general.required"))
            .nullable()
            .typeError(translate("general.required")),
        type: Yup.number()
            .required(translate("general.required"))
            .nullable()
            .typeError(translate("general.required")),

    });

const ManageOperator : React.FC = () => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const [item, setItem] = useState<any | null>(null)
    const [loading, setLoading] = useState(false);

    useEffect( () =>{
        (async () => {
            await updateForm();
        })()
    },[])


    const updateForm = async() =>{
        setLoading(true);
        setItem(await queryRequest(() => getOperatorForSave(id)));
        setLoading(false);
    }

    const onSubmit =async (values : any) => {
        const result = await commandRequest(()=>saveOperator(values))
        if (!result.errors) {
            notification.open({
                message: translate("general.saved").replace(
                    "[]",
                    translate("dashboard.operators.the_operator")
                ),
                type: "success"
            });
            history.goBack();
        } else {
            notification.open({message: result.errors[0], type: "error"});
        }
    }
    return(
        <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 6, className: "p-64 color-gray-5" }}
            title={false}
        >
            {" "}
            {item && (
                <Formik
                    enableReinitialize={true}
                    initialValues={item}
                    validationSchema={formValidator(translate)}
                    onSubmit={() => {}}
                >
                    {formik => (
                        <div>
                            <Card
                                bordered={true}
                                title={id ? translate("dashboard.operators.editOperator"): translate("dashboard.operators.addOperator")}
                            >
                                <FormikForm>
                                    <Row>
                                        <Col span={24}>
                                            <Input
                                                name="name"
                                                label={`${translate("general.name")}`}
                                                placeholder={translate("general.name")}
                                            >
                                            </Input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Select
                                                name="type"
                                                showSearch={true}
                                                label={`${translate("dashboard.operators.type")}`}
                                                placeholder={translate("dashboard.operators.type")}
                                                defaultValue={id ? [item.type] : []}
                                            >
                                                {OperatorTypeMap.map((index) => (
                                                    <option key={index.id} value={index.id}>
                                                        {index.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Col>
                                    </Row>

                                </FormikForm>
                            </Card>
                            <div className={"text-center mb-16"}>
                                <FormSaveCancelButtons
                                    disabledSave={
                                        !formValidator(translate).isValidSync(formik.values)
                                    }
                                    onButtonClick={() => onSubmit(formik.values)}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    )}
                </Formik>
            )}
        </Skeleton>
    )
}

export default ManageOperator;
