import { adminHttpClient } from "../../commons/adminHttpClient";

export interface ChangePasswordData {
  currentPassword: string;
  newPassword: string;
}

const savePassword = (data: ChangePasswordData) => {
  return adminHttpClient.put("/account/ChangePassword", data);
};

export default savePassword;
