import { adminHttpClient } from "../commons/adminHttpClient";
import OrderedPageFilter from  "../commons/orderedPageFilter";
import PagedList from "../commons/pagedList";

export interface NewsAudit {
    id : string;
    date : Date;
    newsEditionTime : string;
    operatorName : string;
    createdBy : string;
    createdAt : string;
}

const getNewsAudit = (filter : OrderedPageFilter) : Promise<PagedList<NewsAudit>>  => adminHttpClient.get(`/newsAudits/Get`, filter)
export default getNewsAudit;