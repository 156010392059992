import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import AdvertisementAudit from "./AdvertisementAudit";
import ManageAdvertisementAudit, {MANAGE_ADVERTISEMENT_AUDIT_PATH} from "./ManageAdvertisementAudit";
import NotFound from "../../errorPages/NotFound";

const Routes: React.FC = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/`} component={AdvertisementAudit} />
      <Route
            path={`${match.path}/${MANAGE_ADVERTISEMENT_AUDIT_PATH}/:id?`}
            component={ManageAdvertisementAudit}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
