import React, {useState, useEffect} from "react";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import {commandRequest, queryRequest} from "../../common/RequestUtils";
import notification from "../../common/components/feedback/Notification";
import {Form as FormikForm, Formik} from "formik";
import Card from "../../common/components/dataDisplay/Card";
import {Col, Row, Skeleton} from "antd";
import PasswordInput from "../../common/components/dataEntry/formik/FormikPasswordField";
import changePasswordForUser from "core/application/users/changePasswordForUser";
import {getUserForUpdate} from "core/application/users/updateUser";
import FormSaveCancelButtons from "../../common/components/forms/SaveCancelFormButtons";

export const CHANGE_PASSWORD_FOR_USER_PATH = 'change-user-password'
const formValidator = (translate: any) =>
    Yup.object({
        newPassword: Yup.string().nullable().required("Fushë e detyrueshme"),
        passwordConfirmation: Yup.string()
            .nullable()
            .trim()
            .when("newPassword", (password : string, schema: any) => {
                return schema.test({
                    test: (passwordConfirmation : any) =>
                        !!password && passwordConfirmation === password,
                    message: "Fjalëkalimi nuk përputhet"
                });
            })
    });


const ChangePasswordForUser : React.FC = () => {
    const { t: translate } = useTranslation();

    let history = useHistory();
    const [item, setItem] = useState<any | null>([]);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const {id} = useParams();

    const getUserInfo = async () => {
        setLoading(true);

        const item = await queryRequest(() => getUserForUpdate(id));
        setItem(item);

        setLoading(false);
    }

    useEffect(() => {
        (async () => {
            await getUserInfo();
        })();
    }, []);

    const onSubmit = async (values :any) => {
        setSaveLoading(true);

        const request = {
            userId: id,
            newPassword: values.newPassword
        };

        const result = await commandRequest(() => changePasswordForUser(request));
        setSaveLoading(false);
        if (!result.errors) {
            notification.open({
                message: "Ndryshimi i fjalëkalimit u krye me sukses.",
                type: "success"
            });
            history.goBack();
        } else {
            notification.open({message: result.errors[0], type: "error"});
        }
    };

    return (


    <Skeleton
        active
        loading={loading}
        paragraph={{ rows: 6, className: "p-64 color-gray-5" }}
        title={false}
    >
        {" "}
        {item && (
            <Formik
                enableReinitialize={true}
                initialValues={item}
                validationSchema={formValidator(translate)}
                onSubmit={() => {}}
            >
                {formik => (
                    <div>
                        <Card
                            className={'container'}
                            bordered={true}
                            title={id ? translate("users.edit"): translate("users.add")}
                        >
                            <FormikForm className={'container'}>
                                <Row>
                                    <PasswordInput label="Fjalëkalimi" name="newPassword" type="password"
                                                   placeholder="Ju lutemi vendosni fjalëkalimin"/>
                                </Row>

                                <Row>
                                    <PasswordInput label="Përsërit Fjalëkalimin e ri" name="passwordConfirmation"
                                                   type="password"
                                                   placeholder="Ju lutemi përsërisni fjalëkalimin e ri."/>
                                </Row>
                            </FormikForm>
                        </Card>
                        <div className={"text-center mb-16"}>
                            <FormSaveCancelButtons
                                disabledSave={
                                    !formValidator(translate).isValidSync(formik.values)
                                }
                                onButtonClick={() => onSubmit(formik.values)}
                                loading={loading}
                            />
                        </div>
                    </div>
                )}
            </Formik>
        )}
    </Skeleton>
    );
}

export default ChangePasswordForUser;