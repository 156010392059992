import {adminHttpClient} from "../commons/adminHttpClient";
import { AdvertisementAuditGroupBy } from "domain/reports/advertisementAuditGroupBy";
import { AuditReportDto, AuditReportFilter } from "./auditableSubjectsReport";

export interface AdvertisementAuditReportFilter extends AuditReportFilter {
    groupBy: AdvertisementAuditGroupBy;
}

export const generateAdvertisementAuditsReport = (filter : AdvertisementAuditReportFilter) : Promise<AuditReportDto> => {
    return adminHttpClient.post(`reports/advertisementAudits/generate`, filter);
}

export const generateAdvertisementAuditsReportExcel = (filter : AdvertisementAuditReportFilter) : Promise<string> => {
    return adminHttpClient.post(`reports/advertisementAudits/generateExcel`, filter);
}