import {adminHttpClient} from "../commons/adminHttpClient";

export interface CreateUserDto{
    firstName : string;
    lastName : string;
    username: string;
    password: string;
    roles : any[]
    userPermissions : any[]
}

const createUser = (data : any) : Promise<any> => {
    return adminHttpClient.post(`/Users`, data);
}

export default createUser