import moment, { Moment, parseZone } from "moment";
import i18n from "i18next";

export const formatDate = (date: Date | Moment) => {
  return moment(date).format("MM/DD/YYYY");
};

export const formatMonthYear = (date: Date | Moment) => {
  return moment(date).format("MMMM YYYY");
};

export const parseZoneFormat = (date: Date | Moment) => {
  return parseZone(date).format("LL");
};

export const formatDataAsDashed = (date: Date | Moment) => {
  return moment(date).format("DD-MM-YYYY");
};

export const formatDateWithUtc = (date: Date | Moment) => {
  return moment.utc(date).format("MM/DD/YYYY");
};

export const formatDateTime = (date: Date | Moment) => {
  return moment(date).format("MM/DD/YYYY HH:mm");
};

export const formatDateTimeReadable = (date: Date | Moment)=>{
  return moment(date).format("DD/MM/YYYY HH:mm");
}
export const formatShortTime = (date: Date | Moment) => {
  return date;
}

export const formatDateTimeAMPM = (date: Date | Moment) => {
  return moment(date)
    .lang(i18n.language)
    .format("LLL");
};

export const formatDateRange = (dateRange: [string, string]) =>{
  return `${moment(dateRange[0]).format("DD/MM/YYYY")} - ${moment(dateRange[1]).format("DD/MM/YYYY")}`;
}
