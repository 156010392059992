import { adminHttpClient } from "../commons/adminHttpClient";
import PagedList from "../commons/pagedList";
import OrderedPageFilter from "../commons/orderedPageFilter";
import { NamedItemInt } from "../commons/namedItem";

export interface OperatorDto {
  id: string;
  name: string;
  operatorType: any;
  createdAt: string;
  createdBy: string;
}

export const OperatorTypeMap: NamedItemInt[] = [
  { id: 0, name: "TV" },
  { id: 1, name: "Radio" }
];

const getOperators = (
  filter: OrderedPageFilter
): Promise<PagedList<OperatorDto>> => {
  return adminHttpClient.get(`operators/get`, filter);
};

export const getAvailableOperators = (): Promise<NamedItemInt[]> => {
  return adminHttpClient.get(`operators/getAvailable`);
};

export default getOperators;
