import {adminHttpClient} from "../commons/adminHttpClient";
import {NamedItemInt} from "../commons/namedItem";
import { Permission } from "../../domain/users/permission";

export interface UserForUpdateDto {
    id: string | null;
    firstName : string;
    lastName: string;
    userPermissions : any[]
}
export const UserRoles : NamedItemInt[] = [
    {id: 0,  name: "SuperAdmin"},
    {id: 1,  name: "Admin"},
]

export const UserPermissions : NamedItemInt[] =[
    {id: Permission.All,  name: "All"},
    {id: Permission.SaveAdvertisementAudit,  name: "Reklama Shto"},
    {id: Permission.DeleteAdvertisementAudit,  name: "Reklama Fshi"},
    {id: Permission.ViewAllAdvertisementAudits,  name: "Reklama Përmbledhje"},
    {id: Permission.ViewAdvertisementAudit,  name: "Reklama Listo"},

    {id: Permission.SaveLiveBroadcastAudit,  name: "Transmetime Live Shto"},
    {id: Permission.DeleteLiveBroadcastAudit,  name: "Transmetime Live Fshi"},
    {id: Permission.ViewAllLiveBroadcastAudits,  name: "Transmetime Live Përmbledhje"},
    {id: Permission.ViewLiveBroadcastAudit,  name: "Transmetime Live Listo"},

    {id: Permission.SaveNewsAudit,  name: "Lajme Shto"},
    {id: Permission.DeleteNewsAudit,  name: "Lajme Fshi"},
    {id: Permission.ViewAllNewsAudits,  name: "Lajme Përmbledhje"},
    {id: Permission.ViewNewsAudit,  name: "Lajme Listo"},

    {id: Permission.SaveNewsTickerAudit,  name: "Titrime Shto"},
    {id: Permission.DeleteNewsTickerAudit,  name: "Titrime Fshi"},
    {id: Permission.ViewAllNewsTickerAudits,  name: "Titrime Përmbledhje"},
    {id: Permission.ViewNewsTickerAudit,  name: "Titrime Listo"},

    {id: Permission.SaveProgramAudit,  name: "Emisione Shto"},
    {id: Permission.DeleteProgramAudit,  name: "Emisione Fshi"},
    {id: Permission.ViewAllProgramAudits,  name: "Emisione Përmbledhje"},
    {id: Permission.ViewProgramAudit,  name: "Emisione Listo"},

    // {id: Permission.Advertisements,  name: "Reklamë"},
    {id: Permission.AdvertisementTypes,  name: "Tipe Reklamash"},
    {id: Permission.AuditableSubjects,  name: "Subjekte"},
    {id: Permission.Auditees,  name: "Aktorë"},
    {id: Permission.Industries,  name: "Industri"},
    {id: Permission.NewsEditions,  name: "Edicione Lajmesh"},
    {id: Permission.NewsTopics,  name: "Tema Lajmevesh"},
    {id: Permission.Notes,  name: "Shënime"},
    {id: Permission.Operators,  name: "Operatorë"},
    // {id: Permission.Programs,  name: "Programe"},
    {id: Permission.Users,  name: "Përdorues"},
    {id: Permission.Reports,  name: "Raporte"},

    {id: Permission.Polls, name: "Sondazhe"},
    {id: Permission.BannedActivities, name: "Aktivitete të ndaluara"},
    {id: Permission.EditAfterPublished, name: "Modifiko mbas publikimit"}
]

export const getUserForUpdate = (id : string) : Promise<any> => {
    return adminHttpClient.get(`/Users/GetForUpdate/${id}`)
}

const updateUser = (data : UserForUpdateDto) : Promise<any> => {
    return adminHttpClient.put(`/Users`, data);
}

export default updateUser

