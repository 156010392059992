import {adminHttpClient} from "../commons/adminHttpClient";

export interface ChangePasswordForUserDto {
    userId: string;
    newPassword: string
}

const changePasswordForUser = (data: ChangePasswordForUserDto) : Promise<any> => {
    return adminHttpClient.put(`/Users/ChangePassword`, data);
}
export default changePasswordForUser;