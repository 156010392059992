import {adminHttpClient} from "../commons/adminHttpClient";
import PagedList from "../commons/pagedList";
import OrderedPageFilter from "../commons/orderedPageFilter";

export interface NewsTickerAuditDto {
    id: string;
    date: Date;
    auditableSubjectName : string;
    operatorName : string;
    createdBy : string;
    createdAt : string;
}
const getNewsTickerAudit = (filter : OrderedPageFilter) : Promise<PagedList<NewsTickerAuditDto>> =>{
    return adminHttpClient.get(`newsTickerAudits/Get`, filter);
}

export default getNewsTickerAudit;