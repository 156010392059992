import {adminHttpClient} from "../commons/adminHttpClient";

export enum NotesType {
    Violation= "0",
    ProhibitedActivity = "1"
}

export interface NotesForSaveDto{
    id: string | null;
    title : string;
    description : string;
}

export const getNotesForSave = (id : string | undefined) : Promise<any> => {
    if (id){
        return adminHttpClient.get(`/Notes/GetForSave/${id}`);
    }
    return adminHttpClient.get(`/Notes/GetForSave`);
}

const saveViolationNotes = (data : any) :Promise<any> =>{
    return adminHttpClient.post(`/Notes/Violations`, data)
}

const saveProhibitedActivityNotes = (data : any) :Promise<any> =>{
    return adminHttpClient.post(`/Notes/ProhibitedActivities`, data)
}

export {saveViolationNotes, saveProhibitedActivityNotes}