import React from "react";
import Routes from "./Routes";
import OtherAuditsWrapper from "./OtherAuditsWrapper";

const Index: React.FC = () => {
  return (
    <OtherAuditsWrapper>
      <Routes />
    </OtherAuditsWrapper>
  );
};
export default Index;
