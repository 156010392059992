import { NamedItemInt } from "../../application/commons/namedItem";

export enum AuditableSubjectType {
  PoliticalParty = 0,
  Institution = 1,
  IndependentInstitution = 2,
  Others = 3
}

export const getAuditableSubjectTypes = (
  translate: (key: string) => string
): NamedItemInt[] => {
  var types: NamedItemInt[] = [];
  types.push({
    id: AuditableSubjectType.PoliticalParty,
    name: translate("dashboard.reports.auditable_subject_type.political_party")
  });
  types.push({
    id: AuditableSubjectType.Institution,
    name: translate("dashboard.reports.auditable_subject_type.institution")
  });
  types.push({
    id: AuditableSubjectType.IndependentInstitution,
    name: translate(
      "dashboard.reports.auditable_subject_type.independent_institution"
    )
  });
  types.push({
    id: AuditableSubjectType.Others,
    name: translate("dashboard.reports.auditable_subject_type.others")
  });
  return types;
};
