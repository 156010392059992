import React, { ReactNode } from "react";

import { Input as AntInput } from "antd";
import { InputProps } from "antd/lib/input";

import "./DataEntry.less";

const Input: React.FC<InputProps> = props => {
  return (
    <AntInput.Password
      {...props}
      className={`input_height round_border ${props.className}`}
    />
  );
};
export default Input;
