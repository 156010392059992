import { NamedItemInt } from "application/commons/namedItem";

export enum ReportTimeUnit{
    Seconds = 0,
    Minutes = 1
}

export const getReportTimeUnits = (translate: (key: string) => string): NamedItemInt[] => {
    var types: NamedItemInt[] = [];
    types.push({
        id: ReportTimeUnit.Seconds,
        name: translate("dashboard.reports.seconds")
    });
    types.push({
      	id: ReportTimeUnit.Minutes,
      	name: translate("dashboard.reports.minutes")
    });
	return types;
};