import React, { useState, useEffect } from "react";
import { Menu, Layout as AntLayout, Row, Col, Card } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Permission } from "core/domain/users/permission";
import { User } from "core/application/account/loginUser";
import { useSelector } from "react-redux";
import { NOTES_PATH } from "./notes/Notes";
import { BANNED_ACTIVITIES_PATH } from "./bannedActivities/BannedActivities";
import { POLLS_PATH } from "./polls/Polls";

export const OTHER_AUDITS_PATH = "other-audits";

const OtherAuditsWrapper: React.FC = ({ children }) => {
  const history = useHistory();
  let location = useLocation();
  const { t: translate } = useTranslation();

  const [activeKey, setActiveKey] = useState();
  const userLoginInfo = useSelector((s: any) => s.userReducer);

  useEffect(() => {
    let pathname = location.pathname;
    const split = pathname.split("/")[2];
    setActiveKey(split ? [split.toString()] : []);
  }, [location]);

  const goTo = (path: string) => {
    history.push(`/${path}`);
  };

  const filterMenuItemBasedOnPermissions = (
    permission: Permission,
    user: User
  ) => {
    return (
      user.permissions.includes(Permission.All) ||
      user.permissions.includes(permission)
    );
  };
  return (
    <Card title={translate("dashboard.other_audits.title")}>
      <Row>
        <Col xs={24} md={6}>
          <Menu
            selectedKeys={activeKey}
            mode="inline"
            style={{ borderRight: "0px" }}
          >
            {filterMenuItemBasedOnPermissions(
              Permission.Notes,
              userLoginInfo.user
            ) && (
              <Menu.Item
                key={NOTES_PATH}
                onClick={() => goTo(`${OTHER_AUDITS_PATH}/${NOTES_PATH}`)}
              >
                {translate("dashboard.notes.name")}
              </Menu.Item>
            )}
            {filterMenuItemBasedOnPermissions(
              Permission.Polls,
              userLoginInfo.user
            ) && (
              <Menu.Item
                key={POLLS_PATH}
                onClick={() => goTo(`${OTHER_AUDITS_PATH}/${POLLS_PATH}`)}
              >
                {translate("dashboard.polls.title")}
              </Menu.Item>
            )}
            {filterMenuItemBasedOnPermissions(
              Permission.BannedActivities,
              userLoginInfo.user
            ) && (
              <Menu.Item
                key={BANNED_ACTIVITIES_PATH}
                onClick={() =>
                  goTo(`${OTHER_AUDITS_PATH}/${BANNED_ACTIVITIES_PATH}`)
                }
              >
                {translate("dashboard.banned_activities.title")}
              </Menu.Item>
            )}
          </Menu>
        </Col>
        <Col xs={24} md={18}>
          <div>{children}</div>
        </Col>
      </Row>
    </Card>
  );
};

export default OtherAuditsWrapper;
