import React from "react";
import Routes from "./Routes"
import ConfigurationWrapper from "./ConfigurationWrapper";

const Index : React.FC = () => {
    return (
        <ConfigurationWrapper>
            <Routes/>
        </ConfigurationWrapper>
    )
}
export default Index;