import React, { useEffect } from "react";
import {
  AuditItems,
  ProgramForSave,
  AuditSubjects
} from "core/application/programAudits/saveProgramAudit";
import { useFormikContext } from "formik";
import Button from "../../../common/components/general/Button";
import Table from "common/components/dataDisplay/Table";
import { v4 as uuidv4 } from "uuid";
import Select from "../../../common/components/dataEntry/formik/FormikSelectField";
import InputNumber from "../../../common/components/dataEntry/formik/FormikInputNumberField";
import TextArea from "../../../common/components/dataEntry/formik/FormikTextAreaField";
import { NewsAuditSubjects } from "core/application/newsAudits/getNewsAuditForSave";
import * as Yup from "yup";
import { useParams } from "react-router";
import Input from "../../../common/components/dataEntry/formik/FormikInputField";

interface Props {
  auditSubjects: AuditSubjects[];
  datasource: ProgramForSave;
  name: string;
  translate: (key: string) => string;
  onRemoveAuditItemRow: any;
  onAddAuditSubjectRow: any;
  onAddAuditItem: any;
  onRemoveAuditSubjectRow: any;
  auditItemIndex: number;
  onChangeSubject: any;
  formik: any;
}

const AuditSubjectsTableInput: React.FC<Props> = ({
  name,
  onAddAuditSubjectRow,
  translate,
  auditSubjects,
  datasource,
  children,
  onRemoveAuditItemRow,
  onAddAuditItem,
  onRemoveAuditSubjectRow,
  auditItemIndex,
  onChangeSubject,
  formik
}) => {
  const addRow = (values: any, index: number) => {
    if (values.auditItems[auditItemIndex].auditSubjects.length - 1 === index) {
      onAddAuditSubjectRow(values, auditItemIndex);
    }
  };
  const columns = [
    {
      title: "",
      dataIndex: "index",
      key: `index`,
      width: "5%",
      render: (text: string, record: any, i: number) => (
        <span>
          <b>{++i}</b>
        </span>
      )
    },
    {
      title: translate("dashboard.programs.auditSubjects.subject"),
      dataIndex: "auditableSubjectId",
      key: "auditableSubjectId",
      width: "20%",
      render: (text: string, record: any, i: number) => (
        <Select
          name={`${name}[${i}].auditableSubjectId`}
          showSearch={true}
          placeholder={translate(
            "dashboard.programs.auditSubjects.subject"
          )}
          onBlur={() => addRow(formik.values, i)}
        >
          {datasource.availableAuditableSubjects.map((item: any) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Select>
      )
    },
    {
      title: translate("dashboard.programs.auditSubjects.auditee"),
      dataIndex: "auditeeId",
      key: "auditeeId",
      width: "10%",
      render: (text: string, record: any, i: number) => (
        <div>
          <Select
            name={`${name}[${i}].auditeeId`}
            showSearch={true}
            placeholder={translate(
              "dashboard.programs.auditSubjects.auditee"
            )}
          >
            {datasource.availableAuditees.map((item: any) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </div>
      )
    },
    {
      title: `${translate("dashboard.liveBroadcast.auditSubjects.duration")} (minuta)`,
      dataIndex: "duration",
      key: "duration",
      width: "10%",
      render: (text: string, record: any, i: number) => (
        <InputNumber
          name={`${name}[${i}].duration`}
          placeholder={translate(
            "dashboard.programs.auditSubjects.duration"
          )}
        />
      )
    },
    {
      title: translate("dashboard.programs.auditSubjects.notes"),
      dataIndex: "notes",
      key: "notes",
      width: "10%",
      render: (text: string, record: any, i: number) => (
        <TextArea
          name={`${name}[${i}].notes`}
          placeholder={translate("dashboard.programs.auditSubjects.notes")}
        ></TextArea>
      )
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "5%",
      render: (text: string, record: any, i: number) => (
        <div>
          <Button
            className={"error-button mb-0"}
            icon="far fa-trash-alt"
            onClick={() => {
              onRemoveAuditSubjectRow(auditItemIndex, formik.values, i);
            }}
          />
        </div>
      )
    }
  ];
  return (
    <Table dataSource={auditSubjects} columns={columns} pagination={false} />
  );
};

export default AuditSubjectsTableInput;
