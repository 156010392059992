import {adminHttpClient} from "../commons/adminHttpClient";
import PagedList from "../commons/pagedList";
import PagedFilter from "../commons/pagedFilter";

export interface AdvertisementTypeDto {
    id : string;
    name: string;
    createdAt : string;
    createdBy : string;
}

const getAdvertisementType = (filter : PagedFilter) : Promise<PagedList<AdvertisementTypeDto>> => {
    return adminHttpClient.get(`/AdvertisementTypes/Get`, filter);
}

export default getAdvertisementType;