import {adminHttpClient} from "../commons/adminHttpClient";
import OrderedPageFilter from  "../commons/orderedPageFilter";
import PagedList from "../commons/pagedList";

export interface ProgramAudit{
    id: string | null;
    date: Date;
    programName: string;
    operatorName: string;
    createdBy : string;
    createdAt : string;
}

export const getProgramAudit = (filter : OrderedPageFilter) : Promise<PagedList<any>> => {
    return adminHttpClient.get(`/ProgramAudits/Get`, filter);
}
const getLiveProgramAudit = (filter : OrderedPageFilter) : Promise<PagedList<ProgramAudit>>  => adminHttpClient.get(`/programAudits/Live/Get`, filter)
const getRecordedProgramAudit = (filter : OrderedPageFilter) : Promise<PagedList<ProgramAudit>>  => adminHttpClient.get(`/programAudits/Recorded/Get`, filter)

export {getLiveProgramAudit, getRecordedProgramAudit}