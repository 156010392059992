import {adminHttpClient} from "../commons/adminHttpClient";
import PagedFilter from "../commons/pagedFilter";
import PagedList from "../commons/pagedList";

export interface UserDto {
    id : string  ;
    name :string
    username :string
    roles : any[];
    lastLogin : string
    createdAt :string
}

const getUsers = (filter : PagedFilter) : Promise<PagedList<UserDto>> => {
    return adminHttpClient.get(`/Users/Get`, filter);
}

export default getUsers;