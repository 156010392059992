import {adminHttpClient} from "../commons/adminHttpClient";
import PagedList from "../commons/pagedList";
import PagedFilter from "../commons/pagedFilter";

export interface AuditableSubjectDto {
    id : string;
    name: string;
    createdAt : string;
    createdBy : string;
}

const getAuditableSubject = (filter : PagedFilter) : Promise<PagedList<AuditableSubjectDto>> => {
    return adminHttpClient.get(`/AuditableSubjects/Get`, filter);
}

export default getAuditableSubject;