import React, { useState, useRef } from "react";
import { Skeleton } from "antd";
import { Card as AntdCard } from "antd";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { RangeValue } from "rc-picker/lib/interface";

import Card from "common/components/dataDisplay/Card";
import RangePicker from "common/components/dataEntry/components/DateRange";
import Button from "common/components/general/Button";
import Select from "common/components/dataEntry/components/Select";

import {
  getAdvertisementAuditGroupBy,
} from "core/domain/reports/advertisementAuditGroupBy";
import {
  getReportTimeUnits,
  ReportTimeUnit
} from "core/domain/reports/reportTimeUnit";
import {
  generateAdvertisementAuditsReport,
  AdvertisementAuditReportFilter,
  generateAdvertisementAuditsReportExcel
} from "core/application/reports/advertisementAuditsReport";

import { formatDateRange } from "common/dateFormatters";

import "../Reports.less";
import { queryRequest } from "common/RequestUtils";
import { AuditReportDto } from "core/application/reports/auditableSubjectsReport";
import { getAuditableSubjectTypes } from "core/domain/reports/auditableSubjectType";
import AuditReportTable from "../AuditReportTable";
import {
  downloadExcel,
  generateReportPdf,
  resolveReportDataForPdf
} from "common/downloadFile";
import notification from "common/components/feedback/Notification";

const AdvertisementAuditReport: React.FC = () => {
  const { t: translate } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[string, string]>();
  const [groupBy, setGroupBy] = useState<number>();
  const [timeUnit, setTimeUnit] = useState<number>();
  const [subjectTypes, setSubjectTypes] = useState<number[]>();
  const [advertisementAuditReport, setAdvertisementAuditReport] = useState<
    AuditReportDto
  >();
  const componentRef = useRef(null);

  const onDateRangeChange = (
    dateRange: RangeValue<Moment>,
    formatString: [string, string]
  ) => {
    setDateRange(formatString);
    setAdvertisementAuditReport(undefined);
  };

  const onGroupByChange = (groupBy: string | number[] | string[]) => {
    setGroupBy(+groupBy);
    setAdvertisementAuditReport(undefined);
  };

  const onTimeUnitChange = (timeUnit: string | number[] | string[]) => {
    setTimeUnit(+timeUnit);
    setAdvertisementAuditReport(undefined);
  };

  const onSubjectTypesChange = (types: any) => {
    setSubjectTypes(types);
    setAdvertisementAuditReport(undefined);
  };

  const onGenerateReport = async () => {
    if (
      dateRange &&
      dateRange[0] &&
      dateRange[1] &&
      timeUnit != null &&
      groupBy != null
    ) {
      setLoading(true);
      setAdvertisementAuditReport(
        await queryRequest(() =>
          generateAdvertisementAuditsReport({
            startDate: dateRange[0],
            endDate: dateRange[1],
            minutes: timeUnit === ReportTimeUnit.Minutes,
            groupBy: groupBy,
            auditableSubjectTypes: subjectTypes
          } as AdvertisementAuditReportFilter)
        )
      );
      setLoading(false);
    }
  };

  const onGenerateReportExcel = async () => {
    if (
      dateRange &&
      dateRange[0] &&
      dateRange[1] &&
      timeUnit != null &&
      groupBy != null
    ) {
      var result = await queryRequest(() =>
        generateAdvertisementAuditsReportExcel({
          startDate: dateRange[0],
          endDate: dateRange[1],
          minutes: timeUnit === ReportTimeUnit.Minutes,
          groupBy: groupBy,
          auditableSubjectTypes: subjectTypes
        } as AdvertisementAuditReportFilter)
      );
      let templateName = `AMA_Raport_Reklamash_${dateRange[0]}_${dateRange[1]}.xlsx`;
      downloadExcel(result, templateName);
      if (result.errors) {
        notification.open({
          message: result.errors.join(","),
          type: "error"
        });
      }
    }
  };
  const handlePdfDownload = (print?: boolean) => {
    if (
      dateRange &&
      dateRange[0] &&
      dateRange[1] &&
      timeUnit != null &&
      groupBy != null
    ) {
      const data = resolveReportDataForPdf(
        advertisementAuditReport,
        timeUnit === ReportTimeUnit.Minutes
      );
      let templateName = `AMA_Raport_Reklamash_${dateRange[0]}_${dateRange[1]}.pdf`;
      const groupName = getAdvertisementAuditGroupBy(translate)
        .filter(c => c.id === groupBy)
        .map(c => c.name)[0];
      const pageHeader = `Autoriteti i Mediave Audiovizive \n${groupName} - Reklama \nKoha e plotë në ${
        timeUnit === ReportTimeUnit.Minutes ? "minuta" : "sekonda"
      } \n${formatDateRange(dateRange)}`;

      const headerColumns = [
        "Nr.",
        groupName,
        `${timeUnit === ReportTimeUnit.Minutes ? "Koha min." : "Koha sek."}`
      ];
      generateReportPdf(
        advertisementAuditReport?.columnNames,
        data,
        pageHeader,
        templateName,
        headerColumns,
        print
      );
    }
  };

  return (
    <Card>
      <div>
        <div className="flex flex__wrap flex__space_between">
          <div className="width_20">
            <RangePicker
              placeholder={translate("dashboard.reports.date")}
              className="full_width"
              disabledDate={d => !d || d.isAfter(moment().subtract(0, "day"))}
              onChange={onDateRangeChange}
            />
          </div>
          <div className="width_20">
            <Select
              placeholder={translate("dashboard.reports.group_by")}
              showArrow={true}
              onChange={onGroupByChange}
            >
              {getAdvertisementAuditGroupBy(translate).map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="width_20">
            <Select
              placeholder={translate(
                "dashboard.reports.choose_auditable_subject_type"
              )}
              mode="multiple"
              showArrow={true}
              onChange={onSubjectTypesChange}
            >
              {getAuditableSubjectTypes(translate).map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="width_20">
            <Select
              placeholder={translate("dashboard.reports.time_unit")}
              showArrow={true}
              onChange={onTimeUnitChange}
            >
              {getReportTimeUnits(translate).map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>

          <div>
            <Button
              type="primary"
              onClick={onGenerateReport}
              loading={loading}
              size="large"
            >
              {translate("dashboard.reports.generate_report")}
            </Button>
          </div>
        </div>
      </div>
      <Skeleton active loading={loading}>
        <div className="mt-8">
          {advertisementAuditReport && (
            <>
              <AntdCard bordered={false} ref={componentRef}>
                <AuditReportTable
                  minutes={timeUnit === ReportTimeUnit.Minutes}
                  auditReport={advertisementAuditReport}
                  entityName={
                    getAdvertisementAuditGroupBy(translate).find(
                      i => i.id === groupBy
                    ).name
                  }
                  loading={loading}
                  translate={translate}
                />
              </AntdCard>
              <div>
                <Button
                  type="primary"
                  onClick={() => handlePdfDownload(true)}
                  loading={loading}
                  size="large"
                  className="mr-4"
                >
                  {translate("dashboard.reports.print")}
                </Button>
                <Button
                  type="primary"
                  onClick={onGenerateReportExcel}
                  loading={loading}
                  size="large"
                  className={"mr-4"}
                >
                  {translate("dashboard.reports.excel")}
                </Button>
                <Button
                  type="primary"
                  onClick={() => handlePdfDownload()}
                  disabled={!advertisementAuditReport}
                  loading={loading}
                  size="large"
                  className={"mr-4"}
                >
                  {translate("dashboard.reports.pdf")}
                </Button>
              </div>
            </>
          )}
        </div>
      </Skeleton>
    </Card>
  );
};

export default AdvertisementAuditReport;
