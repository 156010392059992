import { message } from "antd";
import Error from "./Error";

const queryRequest = async (func: () => {}): Promise<any> => {
  try {
    return await func();
  } catch (err) {
    if (!err || !err.status) {
      message.error("There was an error while processing your request");
      return;
    }

    if (err.status === 401) {
      window.location.href = "/login";
    } else {
      message.error("There was an error while processing your request");
    }
  }
};

const commandRequest = async (func: () => void): Promise<any> => {
  try {
    return await func();
  } catch (err) {
    if (!err || !err.status) {
      return {
        errors: ["There was an error while processing your request"]
      };
    }

    if (err.status === 401) {
      window.location.href = "/login";
    } else if (
      err.status &&
      err.status === 400 &&
      err.data &&
      err.data.length
    ) {
      return {
        errors: err.data.map((err: Error) => err.message)
      };
    } else {
      return {
        errors: ["There was an error while processing your request"]
      };
    }
  }
};

export { queryRequest, commandRequest };
