import {adminHttpClient}  from "../commons/adminHttpClient";
import {NamedItem, NamedItemInt} from "../commons/namedItem";
import {AuditableSubjectType} from "../../domain/reports/auditableSubjectType";

export interface AuditableSubjectForSave {
    id : string | null;
    name: string;
    industryId : string;
    industryName:string;
    type: any;
    availableIndustries : NamedItem[]
}

export const getAuditableSubjectForSave = (id : string | undefined) : Promise<AuditableSubjectForSave> => {
    if (id) {
        return adminHttpClient.get(`/AuditableSubjects/GetForSave/${id}`);
    }
    return adminHttpClient.get(`/AuditableSubjects/GetForSave`);
}

const saveAuditableSubject = (data : any) :Promise<any> => {
    return adminHttpClient.post(`/AuditableSubjects`, data);
}
export default saveAuditableSubject;