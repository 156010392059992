import React from "react";
import {Modal} from "antd";
import Button from "common/components/general/Button";
import {Sections} from "core/application/advertisementAudits/saveAdvertisementAudit";
import AdvertisementAuditSectionsTableInput from "./AdvertisementAuditSectionsTableInput";
import InputMask from "../../../common/components/dataEntry/formik/FormikInputMaskField";
import {NamedItem} from "core/application/commons/namedItem";
import TableDeleteButton from "../../../common/components/tables/TableDeleteButton";
import Table from "common/components/dataDisplay/Table";

interface Props {
    sections: Sections[];
    translate: (key: string) => string;
    name: string;
    availableSubjects : any[],
    advertisementTypes: NamedItem[]
    onDeleteSections : any
    onDeleteAuditItem:any
    onAddAuditItems:any,
    onAddSection:any,
    formik:any
}
const {confirm} = Modal;
const AdvertisementAuditTableInputs: React.FC<Props> = ({
    sections,
    translate,
    name,
    availableSubjects,
    onDeleteSections,
    onDeleteAuditItem,
    onAddSection,
    onAddAuditItems,
    formik,
    advertisementTypes
}) => {

    const addRow = (values : any, index: number) => {
        if(values.sections.length - 1 === index ){
            onAddSection(values)
        }
    }
    const getColumns = () =>{
        return [
            {
                title: "",
                dataIndex: 'index',
                key: `index`,
                render: (text: string, record: any, i: number) => (
                    <span><b>{++i}</b></span>
                )
            },
            {
                title: translate("dashboard.advertisements.time"),
                dataIndex: 'time',
                key: "time",
                width: "8%",
                sorter: false,
                editable: true,
                render: (text: string, record: any, i: number) => (
                    <InputMask
                        mask="11:11"
                        name={`${name}[${i}].time`}
                        placeholder={translate("dashboard.advertisements.time")}
                        onBlur={() => addRow(formik.values, i)}
                    />
                )
            },
            {
                title: "",
                dataIndex: 'items',
                key: "items",
                width: "80%",
                render: (text: string, record: any, i: number) => (
                    <div>
                        <AdvertisementAuditSectionsTableInput
                            auditItems={sections[i] !== undefined ? sections[i].items : []}
                            translate={translate}
                            name={`${name}[${i}].items`}
                            availableSubjects={availableSubjects}
                            advertisementTypes={advertisementTypes}
                            onDeleteAuditItems={onDeleteAuditItem}
                            onAddAuditItems={onAddAuditItems}
                            sectionIndex={i}
                            formik={formik}
                        />
                        <Button className={"mt-8"} type="primary" onClick={() => onAddAuditItems(formik.values, i)}>
                            {translate("general.add")}
                        </Button>
                    </div>
                )
            },
            {
                title: "",
                dataIndex: "actions",
                key: "actions",
                width: "8%",
                render: (text: string, record: any, key: number) => (
                    <div className="form-group">
                        <Button
                            className={"error-button"}
                            icon="far fa-trash-alt"
                            onClick={() => {
                                confirm({
                                    title: translate("general.delete_alert_title"),
                                    content: translate("general.delete_alert_content"),
                                    okText: translate("general.ok_text"),
                                    onOk : (()=> onDeleteSections(key, formik.values))
                                })
                            }}
                        />
                    </div>
                )
            }
        ];
    }
    return (
        <Table
            dataSource={sections}
            columns={getColumns()}
            pagination={false}
        />
    );
}

export default AdvertisementAuditTableInputs;
