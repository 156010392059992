import {adminHttpClient} from "../commons/adminHttpClient";

const deleteNewsEdition = (id : string) : Promise<any> =>{
    return adminHttpClient.delete(`/NewsEditions/Delete`,{
        data:{
            id: id
        }
    })
}

export default deleteNewsEdition;