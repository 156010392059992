import React, {useState} from "react";
import {Modal} from "antd";

import OrderedPageFilter from "core/application/commons/orderedPageFilter";
import PagedList from "core/application/commons/pagedList";
import {NewsAudit} from "core/application/newsAudits/getNewsAudits";

import {formatDataAsDashed, formatDateTimeReadable, formatShortTime} from "../../../common/dateFormatters";
import TableEditButton from "../../../common/components/tables/TableEditButton";
import TableDeleteButton from "../../../common/components/tables/TableDeleteButton";

import {NEWS_AUDIT_PATH} from "../NewsAudits";
import {MANAGE_NEWS_AUDIT} from "../ManageNewsAudit";
import {v4 as uuidv4} from "uuid";
import AuditTableView from "../../common/AuditTableView";
import {getChildrenColumns, getNephewColumns, getParentColumns} from "./AuditColumns";
import getNewsAuditView from "core/application/newsAudits/getNewsAuditView";
import {Permission} from "core/domain/users/permission";
import PermissionButton from "common/components/general/PermissionButton";
import Tag from "../../../common/components/dataDisplay/Tag";
import AuditStatusTag from "../../../common/components/dataDisplay/AuditStatusTag";
import {AuditStatus} from "core/domain/common/auditStatus";
import { User } from "core/application/account/loginUser";
import getNewsAuditListReportExcel, { getNewsAuditReportExcel } from "core/application/newsAudits/newsAuditsReports";
import Table from "common/components/dataDisplay/Table";
import { ReportTimeUnit } from "core/domain/reports/reportTimeUnit";

interface Props {
    setSearchFilter: (value: any) => void;
    filter: OrderedPageFilter;
    item: PagedList<NewsAudit>;
    history: any;
    translate: (key: string) => string;
    loading: boolean;
    user: User;
    onDelete: (id: string) => void;
    updateTable: () => void;
}

const NewsAuditsTable: React.FC<Props> =
    ({
         setSearchFilter,
         filter,
         item,
         history,
         translate,
         loading,
         user,
         onDelete,
         updateTable,
     }) => {
        const {confirm} = Modal;
        const [isModalVisible, setModalVisible] = useState(false);
        const [auditId, setAuditId] = useState(null);
        const getColumns = (): any[] => {
            const columns = [
                {
                    title: translate("dashboard.news.operator"),
                    dataIndex: "operatorName",
                    key: `operatorName__${uuidv4()}`,
                    width: "30%",
                    sorter: true,
                    sortDirections: ["descend", "ascend"],
                    render: (text: string, record: any) => (
                        <div>
                            <span>{`${record.operatorName} @${formatShortTime(record.newsEditionTime)}`}</span>
                        </div>
                    )
                },
                {
                    title: translate("general.date"),
                    dataIndex: "date",
                    key: `date__${uuidv4()}`,
                    width: "20%",
                    sorter: true,
                    sortDirections: ["descend", "ascend"],
                    render: (text: string, record: any) => (
                        <div>
                            <span>{formatDataAsDashed(record.date)}</span>
                        </div>
                    )
                },
                {
                    title: translate("dashboard.status"),
                    dataIndex: "status",
                    key: `status_${uuidv4()}`,
                    responsive: ["md"],
                    sorter: true,
                    sortDirections: ["descend", "ascend"],
                    render: (text: string, record: any) => (<AuditStatusTag status={record.status}/>)

                },
                {
                    title: translate("dashboard.created_at"),
                    dataIndex: "createdAt",
                    key: `createdAt__${uuidv4()}`,
                    responsive: ["md"],
                    sorter: true,
                    sortDirections: ["descend", "ascend"],
                    render: (text: string, record: any) => formatDateTimeReadable(record.createdAt)
                },
                {
                    title: translate("dashboard.created_by"),
                    dataIndex: "createdBy",
                    key: `createdBy__${uuidv4()}`,
                    responsive: ["md"],
                    sorter: false,
                    sortDirections: ["descend", "ascend"],
                    render: (text: string, record: any) => (<span>{record.createdBy}</span>)
                },
                {
                    title: translate("dashboard.action"),
                    width: "10%",
                    dataIndex: "",
                    key: `actions__${uuidv4()}`,
                    align: "center",
                    sorter: false,
                    render: (text: string, record: any) => (
                        <span>
                        <div className="actions flex flex__center">
                            <PermissionButton
                                permissions={[Permission.ViewNewsAudit]}
                                userPermissions={user.permissions}
                                className="mr-16"
                                iconPadding={false}
                                icon={"far fa-list"}
                                onClick={() => {
                                    setAuditId(record.id);
                                    setModalVisible(true)
                                }}
                            />

                            <TableEditButton
                                permissions={[record.status === AuditStatus.Published ? Permission.EditAfterPublished : Permission.SaveNewsAudit]}
                                userPermissions={user.permissions}
                                className="mr-16"
                                editUrl={`/${NEWS_AUDIT_PATH}/${MANAGE_NEWS_AUDIT}/${record.id}`}
                            />

                          <TableDeleteButton
                              permissions={[Permission.DeleteNewsAudit]}
                              userPermissions={user.permissions}
                              onDelete={() => onDelete(record.id)}
                              onDeleted={() => updateTable()}
                          />
                        </div>
                    </span>
                    )
                }
            ];

            const columnToSort: any = columns.find(c => c.dataIndex === filter.orderColumn);
            columnToSort.sortOrder = filter.orderDescending ? "descend" : "ascend";

            return columns;
        };
        const onTableChange = (pagination: any, filters: any, sorter: any) => {
            setSearchFilter({
                ...filter,
                pageNumber: pagination.current,
                pageSize: pagination.pageSize,
                orderColumn: sorter.field || "createdAt",
                orderDescending: sorter.order !== "ascend"
            });
        };
        return (
            <>
                <Table
                    dataSource={item.items}
                    onChange={onTableChange}
                    columns={getColumns()}
                    loading={loading}
                    pagination={{
                        total: item.totalItemCount,
                        pageSize: item.pageSize,
                        current: item.pageNumber,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "30", "40"]
                    }}
                />
                <AuditTableView
                    name={"Lajme"}
                    auditId={auditId}
                    getAuditData={getNewsAuditView}
                    isModalVisible={isModalVisible}
                    onCloseModal={() => setModalVisible(false)}
                    childrenName={'auditItems'}
                    nephewName={'auditSubjects'}
                    parentColumns={getParentColumns()}
                    childColumns={getChildrenColumns()}
                    nephewColumns={getNephewColumns()}
                    getAuditReportExcel={getNewsAuditReportExcel}
                    auditTemplateName={"dashboard.news.newsAudit"}
                    getSummaryReportExcel={getNewsAuditListReportExcel}
                    summaryTemplateName={"dashboard.news.summary"}
                    timeUnit={ReportTimeUnit.Seconds}
                    entityName={"newsEditionName"}
                    user={user}
                />
            </>
        )
    }

export default NewsAuditsTable;
