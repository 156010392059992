import React, {useEffect, useState} from "react";
import Card from "common/components/dataDisplay/Card";
import {Col, notification, Row, Tabs} from "antd";
import Button from "common/components/general/Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NotesViolationTable from "./tables/NotesViolationTable";
import NotesProhibitedActivityTable from "./tables/NotesProhibitedActivityTable";

import { MANAGE_NOTES } from "./ManageNotes";
import { NotesType } from "core/application/notes/saveNotes";
import PagedList from "core/application/commons/pagedList";
import {getViolationNotes, NotesDto} from "core/application/notes/getNotes";
import {queryRequest} from "../../../common/RequestUtils";
import {v4 as uuidv4} from "uuid";
import AuditStatusTag from "../../../common/components/dataDisplay/AuditStatusTag";
import {formatDateTimeReadable} from "../../../common/dateFormatters";
import TableEditButton from "../../../common/components/tables/TableEditButton";
import {OTHER_AUDITS_PATH} from "../OtherAuditsWrapper";
import TableDeleteButton from "../../../common/components/tables/TableDeleteButton";
import deleteNotes from "core/application/notes/deleteNotes";
import InputSearch, {SearchValues} from "../../common/InputSearch";
import {SearchFilter} from "core/domain/common/searchFilter";
import {Permission} from "core/domain/users/permission";
import {useSelector} from "react-redux";
import PermissionButton from "../../../common/components/general/PermissionButton";
import {AuditStatus} from "core/domain/common/auditStatus";
import Table from "common/components/dataDisplay/Table";

export const NOTES_PATH = "notes";
const { TabPane } = Tabs;

const Notes: React.FC = () => {
  let history = useHistory();
  const { t: translate } = useTranslation();
  const [tabKey, setTabKey] = useState<string>(NotesType.Violation);
  const userLoginInfo = useSelector((s: any) => s.userReducer);
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<PagedList<NotesDto>>(
      {} as PagedList<NotesDto>
  );
  const [searchFilter, setSearchFilter] = useState<SearchFilter>({
    pageNumber: 1,
    pageSize: 20,
    orderColumn: "createdAt",
    orderDescending: true
  });
  const searchInitialValues = {inputSearch: searchFilter.searchValue} as SearchValues;

  useEffect(() => {
    (async () => {
      await updateTableDate();
    })();
  }, [searchFilter]);

  const updateTableDate = async () => {
    setLoading(true);
    setItems(await queryRequest(() => getViolationNotes(searchFilter)));
    setLoading(false);
  };
  const getColumns = (): any[] => {
    const columns = [
      {
        title: "Titulli",
        dataIndex: "title",
        key: `title`,
        width: "20%",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => (
            <div>
              <span>{record.title}</span>
            </div>
        )
      },
      {
        title: translate("dashboard.status"),
        dataIndex: "status",
        key: `status_${uuidv4()}`,
        responsive: ["md"],
        sorter: true,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => (<AuditStatusTag status={record.status}/>)
      },
      {
        title: translate("dashboard.created_at"),
        dataIndex: "createdAt",
        key: `createdAt_${uuidv4}`,
        responsive: ["md"],
        sorter: true,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) =>
            formatDateTimeReadable(record.createdAt)
      },
      {
        title: translate("dashboard.created_by"),
        dataIndex: `createdBy`,
        key: `createdBy_${uuidv4}`,
        responsive: ["md"],
        sorter: false,
        sortDirections: ["descend", "ascend"],
        render: (text: string, record: any) => <span>{record.createdBy}</span>
      },
      {
        title: translate("dashboard.action"),
        width: "10%",
        dataIndex: "",
        key: `actions_${uuidv4}`,
        align: "center",
        sorter: false,
        render: (text: string, record: any) => (
            <span>
            <div className="actions flex flex__center">
              <TableEditButton
                  permissions={record.status === AuditStatus.Published ?  [Permission.EditAfterPublished, Permission.All] : [Permission.Notes]}
                  userPermissions={userLoginInfo.user.permissions}
                  className="mr-16"
                  editUrl={`/${OTHER_AUDITS_PATH}/${NOTES_PATH}/${MANAGE_NOTES}/${record.id}`}
              />
              <TableDeleteButton
                  disableDeleted={record.children?.length > 0}
                  onDelete={() => deleteNotes(record.id)}
                  onDeleted={() => updateTableDate()}
              />
            </div>
          </span>
        )
      }
    ];
    const columnToSort: any = columns.find(c => c.dataIndex === searchFilter.orderColumn);
    columnToSort.sortOrder = searchFilter.orderDescending ? "descend" : "ascend";
    return columns;
  };
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setSearchFilter({
      ...searchFilter,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || "createdAt",
      orderDescending: sorter.order !== "ascend"
    });
  };
  const onSearch =  (search : any) => {
    if (search.inputSearch && search.inputSearch?.length < 2) {
      notification.open({type: "error", message: translate("general.search_length")});
    } else {
      setSearchFilter({
        ...searchFilter,
        searchValue: search.inputSearch,
      });
    }
  }
  return (
    <Card
      title={
        <Row>
          <Col xs={12} md={10}>
            <h4>{`${translate("dashboard.notes.name")}`}</h4>
          </Col>
          <Col xs={12} md={11}>
            <InputSearch
                placeholder={'Emri'}
                initialValues={searchInitialValues}
                onSearch={onSearch}
                translate={translate}
                loading={loading}
            />
          </Col>
          <Col xs={12} md={3}>
            <div className={"text-right"}>
              <Button
                icon="far fa-plus"
                type="primary"
                onClick={() => {
                  history.push(
                    `${history.location.pathname}/${MANAGE_NOTES}`
                  );
                }}
              >
                {translate("dashboard.add")}
              </Button>
            </div>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          <Table
              dataSource={items.items}
              onChange={onTableChange}
              columns={getColumns()}
              loading={loading}
              pagination={{
                total: items.totalItemCount,
                pageSize: items.pageSize,
                current: items.pageNumber,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30", "40"]
              }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Notes;
