import { adminHttpClient } from "../commons/adminHttpClient";

export interface OperatorReportDto {
  operatorName: string;
  audits: OperatorAuditReportDto[];
}

export interface OperatorAuditReportDto {
  auditName: string;
  auditedEntities: AuditedEntities[];
  totalTime: number;
  malePercentage?: number;
  femalePercentage?: number;
}

export interface AuditedEntities {
  id: string;
  name: string;
  gender?: number;
  time: number;
}

export interface OperatorAuditReportFilter {
  date: string;
  operatorId: string;
  minutes: boolean;
  auditableSubjectTypes: number[];
}

export const generateOperatorAuditsReport = (
  filter: OperatorAuditReportFilter
): Promise<OperatorReportDto> => {
  return adminHttpClient.post(`reports/operators/generate`, filter);
};

export const generateOperatorAuditsReportExcel = (
  filter: OperatorAuditReportFilter
): Promise<string> => {
  return adminHttpClient.post(`reports/operators/generateExcel`, filter);
};
