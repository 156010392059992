import React, { ReactNode } from "react";
import { Select as AntSelect } from "antd";
import { SelectProps as AntSelectProps } from "antd/lib/select";

import "./DataEntry.less";

interface LabeledValue {
  key: string;
  label: React.ReactNode;
}

declare type T =
  | string
  | string[]
  | number
  | number[]
  | LabeledValue
  | LabeledValue[];

export type SelectProps = AntSelectProps<string | string[] | number[]>;

const Input: React.FC<SelectProps> = props => {
  return (
    <>
      <AntSelect {...props} className={`round_border ${props.className}`}>
        {props.children}
      </AntSelect>
    </>
  );
};

export default Input;
