import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Skeleton } from "antd";

import { User } from "core/application/account/loginUser";
import { AppState } from "store";

import Routes from './Routes'
import { APP_PATH } from "Routes";
import {LOGIN_PATH} from '../account/Login';

import DashboardWrapper from "./DashboardWrapper";
interface AdminDashboardProps {
    user: User | null;
    fetching: boolean;
    error: string | null;
    hasError: boolean;
  }
const Dashboard: React.FC<AdminDashboardProps> = ({user, fetching, error, hasError}) => {
    const history = useHistory();

    useEffect(() => {
        if (hasError) {
            history.push(`${APP_PATH}${LOGIN_PATH}`);
        }
        }, [user, error]);
    return (
      user && (
        <Skeleton loading={fetching}>
            <DashboardWrapper user={user}>
                <Routes/>
            </DashboardWrapper>
        </Skeleton>
        )
    )
}

const mapStateToProps = ({ userReducer }: AppState) => {
    return {
      user: userReducer.user,
      error: userReducer.error,
      hasError: userReducer.hasError,
      fetching: userReducer.fetching
    };
  };
  
export default connect(mapStateToProps)(Dashboard);
