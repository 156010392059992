import React, {useEffect} from "react";
import { Switch, Route, useRouteMatch} from "react-router-dom";

import Home from './home';
import NotFound from "errorPages/NotFound";
import Advertisement from "./advertisements";
import News from "./news";
import Programs from "./programs"
import NewsTicker from "./ticker"
import Configuration from "./configurations";
import OtherAudits from "./otherAudits";
import LiveBroadcastAudit from "./liveBroadcastAudit";

import { ADVERTISEMENT_PATH } from "./advertisements/AdvertisementAudit";
import { NEWS_AUDIT_PATH } from "./news/NewsAudits";
import { PROGRAM_AUDIT_PATH} from "./programs/ProgramAudits";
import { NEWS_TICKER_PATH } from "./ticker/NewsTicker";
import { CONFIGURATION_PATH } from "./configurations/ConfigurationWrapper";
import {LIVE_BROADCAST_PATH} from "./liveBroadcastAudit/LiveBroadcastAudit";
import ChangePassword, {CHANGE_PASSWORD_PATH} from "../account/profile/ChangePassword";
import Users from "./users";
import {USERS_PATH} from "./users/Users";
import { OTHER_AUDITS_PATH } from "./otherAudits/OtherAuditsWrapper";

export const  ADMIN_DASHBOARD_PATH = "/";

const Routes: React.FC = () => {
   let match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}`} component={Home}/>
            <Route path={`${match.path}${ADVERTISEMENT_PATH}`} component={Advertisement} />
            <Route path={`${match.path}${NEWS_AUDIT_PATH}`} component={News} />
            <Route path={`${match.path}${PROGRAM_AUDIT_PATH}`} component={Programs} />
            <Route path={`${match.path}${CONFIGURATION_PATH}`} component={Configuration} />
            <Route path={`${match.path}${OTHER_AUDITS_PATH}`} component={OtherAudits} />
            <Route path={`${match.path}${NEWS_TICKER_PATH}`} component={NewsTicker} />
            <Route path={`${match.path}${LIVE_BROADCAST_PATH}`} component={LiveBroadcastAudit} />
            <Route path={`${match.path}${USERS_PATH}`} component={Users} />
            <Route path={`${match.path}${CHANGE_PASSWORD_PATH}`} component={ChangePassword} />
            <Route component={NotFound}/>
        </Switch>
    );
};

export default Routes;
