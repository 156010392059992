import {adminHttpClient} from "../commons/adminHttpClient";
import {NamedItem} from "../commons/namedItem";
import {AuditStatus} from "../../domain/common/auditStatus";

export interface NewsTickerAuditForSave {
    id : string | null;
    date : Date;
    operatorId : string
    operatorName : string;
    startTime : string;
    endTime : string;
    notes : string;
    status: AuditStatus,
    auditSubjects : AuditSubject[];
    availableOperators : NamedItem[]
    availableAuditableSubjects : NamedItem[]
}

export interface SaveTicker {
    id?: string;
    date: Date;
    operatorId: string;
    startTime: string;
    endTime: string;
    notes: string;
    auditSubjects: AuditSubject[],
    status: number
}

export interface AuditSubject {
    id : string | null;
    auditableSubjectId : string | null;
    auditableSubjectName : string;
    duration : number;
    notes : string;
    index? : string | number;
}

const getNewsTickerForSave = (id : string | undefined) : Promise<NewsTickerAuditForSave> => {
    if (id){
        return adminHttpClient.get(`/NewsTickerAudits/GetForSave/${id}`)
    }
    return adminHttpClient.get(`/NewsTickerAudits/GetForSave`)
}

export const saveTickerNewsAudit = (data : any) : Promise<any> =>{
    return adminHttpClient.post(`NewsTickerAudits`, data);
}

export default getNewsTickerForSave;
