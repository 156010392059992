import React, { useContext, useState, useEffect, useRef } from 'react';
import { Modal } from 'antd';

import InputNumber from "common/components/dataEntry/formik/FormikInputNumberField";
import Select from "common/components/dataEntry/formik/FormikSelectField";
import Input from "common/components/dataEntry/formik/FormikInputField";
import Button from "common/components/general/Button";
import Table from "common/components/dataDisplay/Table";
import {useFormikContext} from "formik";
import {NewsAuditItems, NewsAuditForSave, NewsAuditSubjects} from "core/application/newsAudits/getNewsAuditForSave";
import NewsAuditSubjectTableInputs from "./NewsAuditSubjectTableInputs";
import {v4 as uuidv4} from "uuid";

interface Props{
    auditItems: any[],
    datasource: NewsAuditForSave,
    name: string;
    translate : (key: string) => string;
    onRemoveAuditItemRow : any,
    onAddAuditSubjectRow : any,
    onRemoveAuditSubjectRow : any,
    onAddAuditItemsRow : any,
    formik : any
}
const {confirm} = Modal;
const NewsAuditTableInputs : React.FC<Props> = ({
                                                auditItems,
                                                datasource,
                                                name,
                                                translate,
                                                onRemoveAuditItemRow,
                                                onAddAuditSubjectRow,
                                                    onAddAuditItemsRow,
                                                onRemoveAuditSubjectRow,
                                                    formik
                                              }) => {
    const addRow = (values: any, index: number) => {
        if (values.auditItems.length -1 === index){
            onAddAuditItemsRow(values)
        }
    }
    const columns = [
        {
            title: "",
            dataIndex: 'index',
            key: `index`,
            width:"5%",
            render:(text: string, record: any, i: number) => (
                <span><b>{++i}</b></span>
            )
        },
        {
            title: translate("dashboard.news.audit.newsTopicId"),
            dataIndex: 'newsTopicId',
            key: `newsTopicId`,
            width:"10%",
            render:(text: string, record: any, i: number) => (
                <Select
                    name={`${name}[${i}].newsTopicId`}
                    showSearch={true}
                    placeholder={translate("dashboard.news.audit.newsTopicId")}
                    onBlur={()=>addRow(formik.values, i)}
                    className={"mb-0"}
                >
                    {datasource.availableNewsTopics.map((item : any) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </Select>
            )
        },
        {
            title: `${translate("dashboard.liveBroadcast.auditSubjects.duration")} (sekonda)`,
            dataIndex: 'duration',
            key: `duration`,
            width: "5%",
            render:(text: string, record: any, i: number) =>(
                <Input
                    name={`${name}[${i}].duration`}
                    placeholder={translate("dashboard.news.audit.duration")}
                />
            )
        },
        {
            title: "",
            dataIndex: "addAuditSubject",
            width: "80%",
            key: `addAuditSubject`,
            render: (text: string, record: any, index :number) => (
                <div>
                    <NewsAuditSubjectTableInputs
                        auditSubject={auditItems[index] !== undefined ? auditItems[index].auditSubjects: []}
                        auditItem={auditItems[index] !== undefined ? auditItems[index] : []}
                        auditItemIndex={index}
                        datasource={datasource}
                        name={`${name}[${index}].auditSubjects`}
                        translate={translate}
                        onRemoveAuditSubjectRow={onRemoveAuditSubjectRow}
                        formik={formik}
                        onAddAuditSubjectRow={onAddAuditSubjectRow}
                    />
                    <Button className={"mt-8"} type="primary" onClick={() => onAddAuditSubjectRow(formik.values, index)}>
                        {translate("general.add")}
                    </Button>

                </div>
            )
        },
        {
            title: "",
            dataIndex: "actions",
            key: `actions`,
            width: "5%",
            render: (text: string, record: any, i : number) => (
                <div>
                    <Button
                        className={"error-button mb-0"}
                        icon="far fa-trash-alt"
                        onClick={() => {
                            confirm({
                                title: translate("general.delete_alert_title"),
                                content: translate("general.delete_alert_content"),
                                okText: translate("general.ok_text"),
                                onOk : (()=> onRemoveAuditItemRow(i, formik.values ))
                            })
                        }}
                    />
                </div>
            )
        }

    ];
    return (
        <Table
            rowClassName={() => 'editable-row '}
            dataSource={auditItems}
            columns={columns}
            pagination={false}
            rowKey={(record, key) => record.id ? record.id : key}
        />
    )
}

export default NewsAuditTableInputs
