import {adminHttpClient} from "../commons/adminHttpClient";

const deleteAdvertisementType = (id : string) : Promise<any> =>{
    return adminHttpClient.delete(`/AdvertisementTypes/Delete`,{
        data:{
            id: id
        }
    })
}

export default deleteAdvertisementType;