import React, {useEffect, useState} from "react";
import {Col, notification, Row} from "antd";
import Button from "../../common/components/general/Button";
import Card from "../../common/components/dataDisplay/Card";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PagedList from "core/application/commons/pagedList";
import getOperators, {OperatorDto} from "core/application/operators/getOperators";
import OperatorsTable from "./tables/OperatorsTable";
import {queryRequest} from "../../common/RequestUtils";
import {MANAGE_OPERATOR_PATH} from "./ManageOperator";
import InputSearch, {SearchValues} from "../common/InputSearch";
import {SearchFilter} from "core/domain/common/searchFilter";


export const OPERATORS_PATH = 'operators';

const Operators : React.FC = () =>{
    let history = useHistory();
    const {t: translate} = useTranslation();
    const [loading , setLoading] = useState<boolean>(false);
    const [items , setItems] = useState<PagedList<OperatorDto>>({} as PagedList<OperatorDto>);
    const [searchFilter, setSearchFilter] = useState<SearchFilter>({
        pageNumber: 1,
        pageSize: 20,
        orderColumn: "createdAt",
        orderDescending: true
    });
    const searchInitialValues = {inputSearch: searchFilter.searchValue} as SearchValues;

    useEffect(() => {
        (async() => {
            await updateTableDate();
        })();
    }, [searchFilter])

    const updateTableDate = async () =>{
        setLoading(true);
        setItems(await queryRequest(() => getOperators(searchFilter)))
        setLoading(false);
    }
    const onSearch =  (search : any) => {
        if (search.inputSearch && search.inputSearch?.length < 2) {
            notification.open({type: "error", message: translate("general.search_length")});
        } else {
            setSearchFilter({
                ...searchFilter,
                searchValue: search.inputSearch,
            });
        }
    }
    return(
        <Card title={
            <Row>
                <Col xs={12} md={10}>
                    <h4>{translate("dashboard.operators.operator")}</h4>
                </Col>
                <Col xs={12} md={11}>
                    <InputSearch
                        placeholder={'Emri'}
                        initialValues={searchInitialValues}
                        onSearch={onSearch}
                        translate={translate}
                        loading={loading}
                    />
                </Col>
                <Col xs={12} md={3}>
                    <div className={"text-right"}>
                        <Button
                            icon="far fa-plus"
                            type="primary"
                            onClick={() => {
                                history.push(
                                    `${history.location.pathname}/${MANAGE_OPERATOR_PATH}`
                                );
                            }}>
                            {translate("dashboard.add")}
                        </Button>
                    </div>
                </Col>
            </Row>
        }>
            <Row>
                <Col span={24}>
                    <OperatorsTable
                        setSearchFilter={setSearchFilter}
                        filter={searchFilter}
                        item={items}
                        history={history}
                        translate={translate}
                        loading={loading}
                        updateTableDate={updateTableDate}
                    />
                </Col>
            </Row>

        </Card>
    )
}

export default Operators;