import React, { useState } from "react";
import { Skeleton } from "antd";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { RangeValue } from "rc-picker/lib/interface";

import Card from "common/components/dataDisplay/Card";
import RangePicker from "common/components/dataEntry/components/DateRange";
import Button from "common/components/general/Button";
import Select from "common/components/dataEntry/components/Select";

import {
  getReportTimeUnits,
  ReportTimeUnit
} from "core/domain/reports/reportTimeUnit";
import {
  generateAuditsGenderReport,
  generateAuditsGenderReportExcel
} from "core/application/reports/auditsGenderReport";

import "../Reports.less";
import { queryRequest } from "common/RequestUtils";
import { getAuditableSubjectTypes } from "core/domain/reports/auditableSubjectType";
import AuditReportTable from "../AuditReportTable";
import {
  AuditReportDto,
  AuditReportFilter
} from "core/application/reports/auditableSubjectsReport";
import {
  downloadExcel,
  generateReportPdf,
  resolveReportDataForPdf
} from "common/downloadFile";
import notification from "common/components/feedback/Notification";
import { formatDateRange } from "common/dateFormatters";

const AuditableSubjectsByGenderReport: React.FC = () => {
  const { t: translate } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[string, string]>();
  const [timeUnit, setTimeUnit] = useState<number>();
  const [subjectTypes, setSubjectTypes] = useState<number[]>();
  const [auditableSubjectsReport, setAuditableSubjectsReport] = useState<
    AuditReportDto
  >();

  const onDateRangeChange = (
    dateRange: RangeValue<Moment>,
    formatString: [string, string]
  ) => {
    setDateRange(formatString);
    setAuditableSubjectsReport(undefined);
  };

  const onTimeUnitChange = (timeUnit: string | number[] | string[]) => {
    setTimeUnit(+timeUnit);
    setAuditableSubjectsReport(undefined);
  };

  const onSubjectTypesChange = (types: any) => {
    setSubjectTypes(types);
    setAuditableSubjectsReport(undefined);
  };

  const onGenerateReport = async () => {
    if (dateRange && dateRange[0] && dateRange[1] && timeUnit != null) {
      setLoading(true);
      setAuditableSubjectsReport(
        await queryRequest(() =>
          generateAuditsGenderReport({
            startDate: dateRange[0],
            endDate: dateRange[1],
            minutes: timeUnit === ReportTimeUnit.Minutes,
            auditableSubjectTypes: subjectTypes
          } as AuditReportFilter)
        )
      );
      setLoading(false);
    }
  };

  const onGenerateReportExcel = async () => {
    if (dateRange && dateRange[0] && dateRange[1] && timeUnit != null) {
      var result = await queryRequest(() =>
        generateAuditsGenderReportExcel({
          startDate: dateRange[0],
          endDate: dateRange[1],
          minutes: timeUnit === ReportTimeUnit.Minutes,
          auditableSubjectTypes: subjectTypes
        } as AuditReportFilter)
      );
      let templateName = `AMA_Raport_Gjinor_${dateRange[0]}_${dateRange[1]}.xlsx`;
      downloadExcel(result, templateName);
      if (result.errors) {
        notification.open({
          message: result.errors.join(","),
          type: "error"
        });
      }
    }
  };
  const handlePdfDownload = (print?: boolean) => {
    if (dateRange && dateRange[0] && dateRange[1] && timeUnit != null) {
      const data = resolveReportDataForPdf(
        auditableSubjectsReport,
        timeUnit === ReportTimeUnit.Minutes
      );
      let templateName = `AMA_Raport_Gjinor_${dateRange[0]}_${dateRange[1]}.pdf`;
      const headerColumns = [
        "Nr.",
        "Subjekti",
        `${timeUnit === ReportTimeUnit.Minutes ? "Koha min." : "Koha sek."}`
      ];
      const pageHeader = `Autoriteti i Mediave Audiovizive \nSubjekti - Gjinia - të gjitha auditet \nKoha e plotë në ${
        timeUnit === ReportTimeUnit.Minutes ? "minuta" : "sekonda"
      } \n${formatDateRange(dateRange)}`;
      generateReportPdf(
        auditableSubjectsReport?.columnNames,
        data,
        pageHeader,
        templateName,
        headerColumns,
        print
      );
    }
  };

  return (
    <Card>
      <div>
        <div className="flex flex__wrap flex__space_between">
          <div className="width_20">
            <RangePicker
              placeholder={translate("dashboard.reports.date")}
              className="full_width"
              disabledDate={d => !d || d.isAfter(moment().subtract(0, "day"))}
              onChange={onDateRangeChange}
            />
          </div>
          <div className="width_20">
            <Select
              placeholder={translate(
                "dashboard.reports.choose_auditable_subject_type"
              )}
              mode="multiple"
              showArrow={true}
              onChange={onSubjectTypesChange}
            >
              {getAuditableSubjectTypes(translate).map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="width_20">
            <Select
              placeholder={translate("dashboard.reports.time_unit")}
              showArrow={true}
              onChange={onTimeUnitChange}
            >
              {getReportTimeUnits(translate).map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>

          <div>
            <Button
              type="primary"
              onClick={onGenerateReport}
              loading={loading}
              size="large"
            >
              {translate("dashboard.reports.generate_report")}
            </Button>
          </div>
        </div>
      </div>
      <Skeleton active loading={loading}>
        <div className="mt-8">
          {auditableSubjectsReport && (
            <>
              <AuditReportTable
                minutes={timeUnit === ReportTimeUnit.Minutes}
                entityName={translate("dashboard.reports.auditable_subject")}
                auditReport={auditableSubjectsReport}
                loading={loading}
                translate={translate}
              />
              <div>
                <Button
                  type="primary"
                  onClick={() => handlePdfDownload(true)}
                  loading={loading}
                  size="large"
                  className="mr-4"
                >
                  {translate("dashboard.reports.print")}
                </Button>
                <Button
                  type="primary"
                  onClick={onGenerateReportExcel}
                  loading={loading}
                  size="large"
                  className={"mr-4"}
                >
                  {translate("dashboard.reports.excel")}
                </Button>
                <Button
                  type="primary"
                  onClick={() => handlePdfDownload()}
                  disabled={!auditableSubjectsReport}
                  loading={loading}
                  size="large"
                  className={"mr-4"}
                >
                  {translate("dashboard.reports.pdf")}
                </Button>
              </div>
            </>
          )}
        </div>
      </Skeleton>
    </Card>
  );
};

export default AuditableSubjectsByGenderReport;
