import {adminHttpClient} from "../commons/adminHttpClient";

export interface OperatorForSave {
    id :string | null;
    name: string;
    operatorType : any
}

const getOperatorForSave = (id : string | undefined) : Promise<OperatorForSave> => {
    if (id){
        return adminHttpClient.get(`operators/GetForSave/${id}`)
    }
    return adminHttpClient.get(`operators/GetForSave`);
}
export const saveOperator = (data : any) : Promise<any> => {
    return adminHttpClient.post(`operators`, data);
}
export default getOperatorForSave;