const config: Map<string, any> = new Map<string, any>();

const env: string = process.env.REACT_APP_ENVIRONMENT || "development";

if(env === "development") {
  config.set("development", {
    apiEndpoint: "https://localhost:7001"
  });
}

if(env === "staging") {
  config.set("staging", {
    apiEndpoint: "https://apistage.amamonitoring.com"
  });
}

if(env === "production") {
  config.set("production", {
    apiEndpoint: "https://api.amamonitoring.com"
  });
}

export default config.get(env);
