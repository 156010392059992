import {adminHttpClient} from "../commons/adminHttpClient";

export interface AuditReportDto {
  columnNames: string[];
  entitySummary: AuditReportEntitySummaryDto[];
}

export interface AuditReportEntitySummaryDto {
    entityName: string;
    times: number[];
}

export interface AuditReportFilter {
    startDate : string;
    endDate: string;
    minutes: boolean;
    auditableSubjectTypes: number[];
}

export const generateAuditableSubjectsReport = (filter : AuditReportFilter) : Promise<AuditReportDto> => {
    return adminHttpClient.post(`reports/auditableSubjects/generate`, filter);
}

export const generateAuditableSubjectsReportExcel = (filter : AuditReportFilter) : Promise<string> => {
    return adminHttpClient.post(`reports/auditableSubjects/generateExcel`, filter);
}