import {adminHttpClient} from "../commons/adminHttpClient";
import { NewsAuditGroupBy } from "domain/reports/newsAuditGroupBy";
import { NewsAuditItemSubjectTime } from "domain/reports/newsAuditItemSubjectTime";
import { AuditReportFilter, AuditReportDto } from "./auditableSubjectsReport";

export interface NewsAuditReportFilter extends AuditReportFilter {
    groupBy: NewsAuditGroupBy;
    subjectTime?: NewsAuditItemSubjectTime | undefined;
}

export const generateNewsAuditsReport = (filter : NewsAuditReportFilter) : Promise<AuditReportDto> => {
    return adminHttpClient.post(`reports/newsAudits/generate`, filter);
}

export const generateNewsAuditsReportExcel = (filter : NewsAuditReportFilter) : Promise<string> => {
    return adminHttpClient.post(`reports/newsAudits/generateExcel`, filter);
}