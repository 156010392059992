import {adminHttpClient}  from "../commons/adminHttpClient";

export interface IndustryForSave {
    id : string | null;
    name: string;
}

export const getIndustryForSave = (id : string | undefined) : Promise<IndustryForSave> => {
    if (id) {
        return adminHttpClient.get(`/Industries/GetForSave/${id}`);
    }
    return adminHttpClient.get(`/Industries/GetForSave`);
}

const saveIndustry = (data : any) :Promise<any> => {
    return adminHttpClient.post(`/Industries`, data);
}
export default saveIndustry;