import React from "react";
import CancelButton from "../dataEntry/components/CancelButton";
import Button from "../general/Button";
import {useTranslation} from "react-i18next";

interface Props {
    goBackPath?: string;
    disabledSave: boolean;
    onButtonClick: () => void;
    loading: boolean;
    showCancelButton?: boolean;
    saveText?: string;
    cancelText?: string;
}

const FormSaveCancelButtons: React.FC<Props> = ({goBackPath,
                                    disabledSave=false,
                                    onButtonClick,
                                    loading=false,
                                    showCancelButton = true,
                                    saveText,
                                    cancelText
                                    }) => {
    const { t: translate } = useTranslation();
    return (
        <div className="text-center mb-4 pb-24">
            <Button
                type="primary"
                htmlType="submit"
                disabled={disabledSave}
                className="mr-16"
                onClick={onButtonClick}
                loading={loading}>
                {saveText ? saveText : translate("dashboard.save")}
            </Button>
            {showCancelButton && <CancelButton backUrl={goBackPath} title={cancelText}/>}
        </div>
    );
};

export default FormSaveCancelButtons;