import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import NewsTicker from "./NewsTicker";
import ManageTicker from "../ticker/ManageTicker";
import {MANAGE_TICKER_PATH} from "../ticker/ManageTicker";

import NotFound from "../../errorPages/NotFound";

const Routes: React.FC = () => {
    let match = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${match.path}/`} component={NewsTicker} />
            <Route
                path={`${match.path}/${MANAGE_TICKER_PATH}/:id?`}
                component={ManageTicker}
            />
            <Route component={NotFound} />
        </Switch>
    );
};

export default Routes;
