import axios from "axios";
import config from "./config";

class HttpClient {
  protected getHeaders() {}

  private apiEndpoint: string;

  constructor(apiEndpoint?: string) {
    this.apiEndpoint = apiEndpoint ?? config.apiEndpoint;
  }

  private getAxiosInstance() {
    const axiosInstance = axios.create({
      baseURL: this.apiEndpoint,
      headers: this.getHeaders()
    });

    return axiosInstance;
  }

  get(url: string, params?: {}): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAxiosInstance()
        .get(url, { params })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }

  post(url: string, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAxiosInstance()
        .post(url, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }

  put(url: string, data: any, options: any = {}) {
    return new Promise((resolve, reject) => {
      this.getAxiosInstance()
        .put(url, data, options)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }

  delete(url: string, data: any) {
    return new Promise((resolve, reject) => {
      this.getAxiosInstance()
        .delete(url, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }
}

export default HttpClient;
const httpClient = new HttpClient();
export { httpClient };
