import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import NotFound from "./errorPages/NotFound";
import Login, {LOGIN_PATH} from "./account/Login";
import Dashboard from './dashboard';
import {ADMIN_DASHBOARD_PATH} from "./dashboard/Routes";
export const APP_PATH = '/';

const Routes: React.FC = () => {
    return (
        <Router>
            <Switch>
                <Route exact path={`${ADMIN_DASHBOARD_PATH}${LOGIN_PATH}`} component={Login}/>
                <Route path={`${ADMIN_DASHBOARD_PATH}`} component={Dashboard}/>
                <Route component={NotFound}/>
            </Switch>
        </Router>
    );
};

export default Routes;
