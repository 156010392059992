import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router";
import notification from "common/components/feedback/Notification";
import { queryRequest, commandRequest } from "common/RequestUtils";
import { Col, Row, Skeleton } from "antd";
import { Form as FormikForm, Formik } from "formik";
import Card from "common/components/dataDisplay/Card";
import Input from "common/components/dataEntry/formik/FormikInputField";
import FormSaveCancelButtons from "common/components/forms/SaveCancelFormButtons";
import Select from "common/components/dataEntry/formik/FormikSelectField";
import { NamedItemInt } from "core/application/commons/namedItem";
import {
  getBannedActivityForSave,
  saveBannedActivity,
  BannedActivityForSaveDto
} from "core/application/bannedActivities/saveBannedActivity";
import DatePicker from "common/components/dataEntry/formik/FormikDatePickerField";
import moment from "moment";
import SaveAuditFormButtons from "../../../common/components/forms/SaveAuditFormButtons";
import {AuditStatus} from "core/domain/common/auditStatus";

export const MANAGE_BANNED_ACTIVITY = "manage-banned-activity";

const formValidator = (translate: any) =>
  Yup.object({
    name: Yup.string()
      .required(translate("general.required"))
      .nullable()
      .typeError(translate("general.required")),
    date: Yup.date()
      .required(translate("general.required"))
      .nullable()
      .typeError(translate("general.required")),
    operatorId: Yup.string()
      .required(translate("general.required"))
      .nullable()
      .typeError(translate("general.required")),
    subjectId: Yup.string()
      .required(translate("general.required"))
      .nullable()
      .typeError(translate("general.required"))
  });
const ManageBannedActivity: React.FC = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [item, setItem] = useState<BannedActivityForSaveDto | null>(null);
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    (async () => {
      await updateForm();
    })();
  }, []);

  const updateForm = async () => {
    setLoading(true);
    setItem(await queryRequest(() => getBannedActivityForSave(id)));
    setLoading(false);
  };

  const lockAudit = async (values: any) => {
    const shouldLock = !values.id && values.name && values.operatorId && values.subjectId && values.date;
    if (!shouldLock) return;

    const request = {
      name : values.name,
      operatorId : values.operatorId,
      subjectId : values.subjectId,
      date : values.date,
    }

    setIsFormSubmitted(true)
    const result = await commandRequest(() => saveBannedActivity(request))
    setIsFormSubmitted(false)

    if (result.errors) {
      notification.open({message: result.errors[0], type: "error"});
      return;
    }

    if (!item) return;

    setItem({
      ...item,
      id: result,
      name: values.name,
      operatorId : values.operatorId,
      subjectId : values.subjectId,
      date : values.date
    })
  }
  const onSubmit = async (values: any, status : any) => {
    const result = await commandRequest(() => saveBannedActivity({...values, status : status}));
    if (!result.errors) {
      notification.open({
        message: translate("general.saved").replace(
          "[]",
          translate("dashboard.banned_activities.the_banned_activity")
        ),
        type: "success"
      });
      history.goBack();
    } else {
      notification.open({ message: result.errors[0], type: "error" });
    }
  };
  return (
    <Skeleton
      active
      loading={loading}
      paragraph={{ rows: 6, className: "p-64 color-gray-5" }}
      title={false}
    >
      {" "}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {formik => (
            <div>
              <Card
                bordered={true}
                title={
                  id
                    ? translate("dashboard.banned_activities.edit")
                    : translate("dashboard.banned_activities.add")
                }
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        name="name"
                        label={`${translate("general.name")}`}
                        placeholder={translate("general.name")}
                        onBlur={async () => {
                          await lockAudit(formik.values);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <DatePicker
                        label={translate("dashboard.banned_activities.date")}
                        name="date"
                        allowClear={false}
                        placeholder={translate(
                          "dashboard.banned_activities.date"
                        )}
                        defaultValue={item.date ? moment(item.date) : undefined}
                        disabledDate={d =>
                          !d || d.isAfter(moment().subtract(0, "day"))
                        }
                        className="full_width"
                        onBlur={async () => {
                          await lockAudit(formik.values);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Select
                        name="operatorId"
                        showSearch={true}
                        label={`${translate(
                          "dashboard.banned_activities.operator"
                        )}`}
                        placeholder={translate(
                          "dashboard.banned_activities.operator"
                        )}
                        defaultValue={id ? [item.operatorId] : []}
                        onBlur={async () => {
                          await lockAudit(formik.values);
                        }}
                      >
                        {item.availableOperators.map((index: NamedItemInt) => (
                          <option key={index.id} value={index.id}>
                            {index.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Select
                        name="subjectId"
                        showSearch={true}
                        label={`${translate(
                          "dashboard.banned_activities.subject"
                        )}`}
                        placeholder={translate(
                          "dashboard.banned_activities.subject"
                        )}
                        defaultValue={id ? [item.subjectId] : []}
                      >
                        {item.availableSubjects.map((index: NamedItemInt) => (
                          <option key={index.id} value={index.id}>
                            {index.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </FormikForm>
              </Card>
              <div className={"text-center mb-16"}>
                <SaveAuditFormButtons
                    onSaveAsDraftClick={async () => {
                      await formik.submitForm();
                      await onSubmit(formik.values, AuditStatus.Draft)
                    }}
                    onPublishClick={async () => {
                      await formik.submitForm();
                      await onSubmit(formik.values, AuditStatus.Published)
                    }}
                    loading={isFormSubmitted}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  );
};

export default ManageBannedActivity;
