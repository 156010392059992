import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import {commandRequest, queryRequest} from "../../../common/RequestUtils";
import notification from "../../../common/components/feedback/Notification";
import {Col, Row, Skeleton} from "antd";
import {Form as FormikForm, Formik} from "formik";
import Card from "../../../common/components/dataDisplay/Card";
import Input from "../../../common/components/dataEntry/formik/FormikInputField";
import FormSaveCancelButtons from "../../../common/components/forms/SaveCancelFormButtons";
import * as Yup from "yup";
import {getIndustryForSave, IndustryForSave} from "core/application/industries/saveIndustries";
import saveIndustry from "core/application/industries/saveIndustries";

export const MANAGE_INDUSTRY = "manage-industry"
const formValidator = (translate: any) =>
    Yup.object({
        name: Yup.string()
            .required(translate("general.required"))
            .nullable()
            .typeError(translate("general.required")),
    });
const ManageIndustry : React.FC = () =>{
    const { t: translate } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const [item, setItem] = useState<IndustryForSave | null>(null)
    const [loading, setLoading] = useState(false);

    useEffect( () =>{
        (async () => {
            await updateForm();
        })()
    },[])


    const updateForm = async() =>{
        setLoading(true);
        setItem(await queryRequest(() => getIndustryForSave(id)));
        setLoading(false);
    }

    const onSubmit =async (values : any) => {
        const result = await commandRequest(()=>saveIndustry(values))
        if (!result.errors) {
            notification.open({
                message: translate("general.saved").replace(
                    "[]",
                    translate("dashboard.industries.the_industry")
                ),
                type: "success"
            });
            history.goBack();
        } else {
            notification.open({message: result.errors[0], type: "error"});
        }
    }
    return (
        <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 6, className: "p-64 color-gray-5" }}
            title={false}
        >
            {" "}
            {item && (
                <Formik
                    enableReinitialize={true}
                    initialValues={item}
                    validationSchema={formValidator(translate)}
                    onSubmit={() => {}}
                >
                    {formik => (
                        <div>
                            <Card
                                bordered={true}
                                title={id ? translate("dashboard.industries.edit"): translate("dashboard.industries.add")}
                            >
                                <FormikForm>
                                    <Row>
                                        <Col span={24}>
                                            <Input
                                                name="name"
                                                label={`${translate("general.name")}`}
                                                placeholder={translate("general.name")}
                                            >
                                            </Input>
                                        </Col>
                                    </Row>
                                </FormikForm>
                            </Card>
                            <div className={"text-center mb-16"}>
                                <FormSaveCancelButtons
                                    disabledSave={
                                        !formValidator(translate).isValidSync(formik.values)
                                    }
                                    onButtonClick={() => onSubmit(formik.values)}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    )}
                </Formik>
            )}
        </Skeleton>
    )
}

export default ManageIndustry;