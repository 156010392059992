import React from "react";
import CancelButton from "../dataEntry/components/CancelButton";
import Button from "../general/Button";
import {useTranslation} from "react-i18next";

interface Props {
    goBackPath?: string;
    disableSaveAsDraft?: boolean;
    onSaveAsDraftClick: () => void;
    disablePublish?: boolean;
    onPublishClick: () => void;
    loading?: boolean;
    showCancelButton?: boolean;
    saveText?: string;
    cancelText?: string;
}

const FormSaveCancelButtons: React.FC<Props> = ({goBackPath,
                                                    disableSaveAsDraft=false,
                                                    onSaveAsDraftClick,
                                                    loading=false,
                                                    disablePublish=false,
                                                    onPublishClick,
                                                    showCancelButton = true,

                                                }) => {
    const { t: translate } = useTranslation();
    return (
        <div className="text-center mb-4 pb-24">
            <Button
                type="primary"
                htmlType="submit"
                disabled={disableSaveAsDraft}
                className="mr-16"
                onClick={onSaveAsDraftClick}
                loading={loading}>
                {translate("general.saveAsDraft")}
            </Button>
            <Button
                type="primary"
                htmlType="button"
                disabled={disablePublish}
                className="mr-16"
                onClick={onPublishClick}
                loading={loading}>
                {translate("general.publish")}
            </Button>
            {showCancelButton && <CancelButton backUrl={goBackPath} title={translate("dashboard.cancel")}/>}
        </div>
    );
};

export default FormSaveCancelButtons;
