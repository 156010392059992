import {adminHttpClient} from "../commons/adminHttpClient";
import PagedList from "../commons/pagedList";
import PagedFilter from "../commons/pagedFilter";

export interface NewsTopicsDto {
    id : string;
    name: string;
    createdAt : string;
    createdBy : string;
}

const getNewsTopics = (filter : PagedFilter) : Promise<PagedList<NewsTopicsDto>> => {
    return adminHttpClient.get(`/NewsTopics/Get`, filter);
}

export default getNewsTopics;