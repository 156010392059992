import React, {useState} from "react";
import Input from "../../common/components/dataEntry/components/Input";
import Icon from "../../common/components/general/Icon";
import Button from "../../common/components/general/Button";

export type SearchValues = {
    inputSearch: string | null;
};
export interface InputSearchProps {
    initialValues: SearchValues,
    onSearch: (value: SearchValues) => void;
    translate: (key: string) => string;
    loading: boolean;
    placeholder : string;
}
const searchIcon = <Icon type="fal fa-search" />;

const InputSearch : React.FC<InputSearchProps> = ({
                                                      translate,
                                                      initialValues,
                                                      onSearch,
                                                      loading,
                                                      placeholder
}) => {
    const [inputSearchValue, setInputSearchValue] = useState<any>(initialValues.inputSearch);

    const ENTER_KEY_CODE = 13;
    const search = (e: any) => {
        const value = e.target.value;
        setInputSearchValue(value ?? null);
        if (e.keyCode === ENTER_KEY_CODE) {
            onSearch({inputSearch: inputSearchValue});
        }
    };
    return (
        <div style={{display:'flex', alignItems:'center'}}>
            <Input
                placeholder={placeholder}
                suffix={searchIcon}
                defaultValue={inputSearchValue}
                onKeyUp={search}
                className="mr-8"
            />
        </div>
    )
}

export default InputSearch;